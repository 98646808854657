import { Layout } from '@enterprise-ui/canvas-ui-react'
import { useAuth } from '@praxis/component-auth'
import { SideNavigation, Header } from 'item-component'
import { MainRouter } from '../globalComponents/MainRouter'
import { LogIn } from './common/LogIn'
import { Unauthorized } from './common/Unauthorized'
import { LoadingProfile } from './common/LoadingProfile'
import { useMain } from './useMain'
import Banner from './common/Banner'

export const Main = () => {
  const {
    loading,
    error,
    data,
    constructedMenu,
    bannerHeader,
    getUserProfile,
  } = useMain()
  const appMenus = constructedMenu()
  const auth = useAuth()
  const { isAuthenticated } = auth
  const bannerData = bannerHeader ? bannerHeader?.bannerDetail : []
  if (isAuthenticated()) {
    if (loading) return <LoadingProfile />
    if (error) return <Unauthorized />
    if (data) {
      return (
        <Layout
          theme="dark" // configure theme here
          darkMode="false" // configure theme here
          data-testid="mainAuthenticatedLayout"
        >
          {getUserProfile?.roles?.length > 0 && (
            <>
              {bannerData &&
                bannerData.map((banner, index) => {
                  return <Banner key={index} bannerData={banner} />
                })}
              <Header
                userRole="User Role"
                useAuth={useAuth}
                appTitle="Launchpad"
              />
              <SideNavigation
                className="side-navigation-panel"
                data-testid="item-app-sidenav-panel"
                startExpanded={false}
                appMenus={appMenus}
                defaultmenurequired={false}
                // defaultmenurequired={itemCommonUtilis.isItemHub} //TODO: Need to handle when onboard to ItemHub
                isMinimized={true}
              />
            </>
          )}
          <MainRouter />
        </Layout>
      )
    }
  } else {
    return <LogIn />
  }
}
