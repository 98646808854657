import CrossOver from '../actionbar/CrossOver'
import Unpublished from '../actionbar/Unpublished'
const MMBActions = ({ selectedTcins, selectedItems }) => {
  const getDepartment = selectedItems?.map((item) => item.department)
  const isSameMMBDept = selectedItems
    ?.map((item) => item.department)
    ?.every((val, i, dept) => val === dept[0])
  return (
    <>
      <span className="hc-clr-contrast-weak button-bg-hover-transparent hc-ml-normal">
        MMB Actions
      </span>
      <CrossOver
        selectedTcins={selectedTcins}
        isSameMMBDept={isSameMMBDept}
        selectedDepartment={getDepartment}
      />
      <Unpublished
        selectedTcins={selectedTcins}
        isSameMMBDept={isSameMMBDept}
      />
    </>
  )
}

export default MMBActions
