import { Card } from '@enterprise-ui/canvas-ui-react'
import EnterpriseIcon, * as IconExports from '@enterprise-ui/icons'
import { useQuickNavigation } from './useQuickNavigation'

const {
  default: _default,
  EnterpriseIcon: _EnterpriseIcon,
  ...Icons
} = IconExports

export const QuickNavigation = () => {
  const { navigationItems, handleNavigation } = useQuickNavigation()

  return (
    <Card className="hc-pa-md hc-mt-normal">
      <h4 className="hc-fs-md hc-lh-expanded font-color-default font-weight-700 display-flex">
        <EnterpriseIcon icon={Icons['WorkspaceIcon']} className="hc-mr-dense" />
        <span>Quick Navigation</span>
      </h4>
      <div className="display-flex">
        {navigationItems.map((navigation, index, { length }) => (
          <Card
            // className="hc-pa-sm hc-mt-normal hc-mr-dense"
            className={`hc-mt-normal hc-pa-sm cursor-pointer ${length - 1 === index ? 'hc-mr-none' : 'hc-mr-md'}`}
            style={{ width: '20%' }}
            key={index}
            onClick={() => handleNavigation(navigation.path)}
          >
            <EnterpriseIcon
              icon={Icons[`${navigation.icon}`]}
              size="xl"
              className="link-color quick-navigation-icon"
            />
            <h5 className="hc-fs-sm hc-lh-expanded font-color-default font-weight-500 hc-mt-dense">
              {navigation.title}
            </h5>
            <p className="hc-fs-xs hc-lh-expanded font-color-secondary font-weight-400 hc-mt-dense">
              {navigation.description}
            </p>
          </Card>
        ))}
      </div>
    </Card>
  )
}
