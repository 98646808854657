import { useState } from 'react'
import InfoTableModal from './InfoTableModal'
import { Button } from '@enterprise-ui/canvas-ui-react'

const InfoTableCell = (props) => {
  const [isModalVisible, setModalVisibility] = useState(false)
  if (props?.cell) {
    return (
      <>
        {!props.table && <div>{props.cell}</div>}
        {props.table && (
          <>
            <Button
              type="ghost"
              data-testid="info-table-link-button"
              onClick={() => {
                setModalVisibility(true)
              }}
            >
              {props.cell}
            </Button>

            {isModalVisible && (
              <InfoTableModal
                visible={isModalVisible}
                {...props.table}
                onClose={() => setModalVisibility(false)}
              />
            )}
          </>
        )}
      </>
    )
  }
  return null
}

export default InfoTableCell
