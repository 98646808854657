import gql from 'graphql-tag'

export const SEARCH_INFO_TABLE = gql`
  query searchInfoTableLookup(
    $attributeId: String
    $tcins: [String]
    $includeExcelDownload: Boolean
  ) {
    searchInfoTableLookup(
      attributeId: $attributeId
      tcins: $tcins
      includeExcelDownload: $includeExcelDownload
    ) {
      attributeId
      tcins
      headings {
        key
        displayName
      }
      rowsByTcin
      errors
      excelFile
    }
  }
`
