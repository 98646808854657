import {
  List,
  Placeholder,
  Card,
  ProgressBar,
  Tooltip,
} from '@enterprise-ui/canvas-ui-react'
import { useSearchStickersContent } from './useSearchStickersContent'
import '../../../globalStyles/stickers.scss'

export const SearchStickersContent = (props) => {
  const {
    loading,
    output,
    userSelectedSticker,
    getClassname,
    getsStickerClassname,
    handleCardClick,
  } = useSearchStickersContent(props)

  const dummyArray = [...Array(7)]
  const value = userSelectedSticker[0]?.values[0]?.displayValue
  const verticalData = [
    'Item Data Not Ready',
    'Not Ready for Order',
    'Not Ready for Launch',
  ]
  const { setTotalCount } = props
  const TotalObj = output.filter((sticker) => sticker.name === 'Total')
  if (TotalObj) {
    setTotalCount(TotalObj[0]?.value)
  }
  return (
    <div className="sticker-list" id="sticker-list">
      {loading && (
        <Placeholder>
          <List>
            {dummyArray.map((num, index) => (
              <List.Item
                className="hc-pl-none mc-mr-normal"
                key={`place-holder_${index}`}
              >
                <Placeholder.Rect
                  emphasized={index === 1}
                  className="place-holder "
                />
              </List.Item>
            ))}
          </List>
        </Placeholder>
      )}
      {!loading && output.length > 0 && (
        <List>
          {output?.map((field, index) => (
            <Tooltip content={field.tooltip} location="top">
              <List.Item
                key={`${index}-list-tabs`}
                className={`hc-pl-none ${verticalData.includes(field.className) ? 'vertical-hr' : ''}`}
              >
                <div aria-description="secondary">
                  <Card
                    className={`${getClassname(field.className)} ${field.className === value ? 'lp-sticker-active' : ''} cursor-pointer`}
                    onClick={() => {
                      handleCardClick(field)
                    }}
                  >
                    <span className="lp-sticker-title">{field.className}</span>
                    <div className="lp-sticker-value">
                      <span className="lp-sticker-bottom">
                        {field.value?.toLocaleString()}
                      </span>
                    </div>
                    <div
                      className={`${getsStickerClassname(field.className)} lp-sticker`}
                    >
                      <div
                        className={`ct-series ct-series-${(1 + 1 + 9).toString(36)}`}
                      ></div>

                      <ProgressBar
                        className={`${getsStickerClassname(field.className)} ct-slice-donut`}
                        data-testidid="Progressnew"
                        percentComplete={0}
                      />
                    </div>
                  </Card>
                  {verticalData.includes(field.className) && (
                    <div className="vertical-line"></div>
                  )}
                </div>
              </List.Item>
            </Tooltip>
          ))}
        </List>
      )}
    </div>
  )
}
