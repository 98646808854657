import { useQuery } from '@apollo/client'

import { getUserProfileFromGql } from '../apiItemGraphql'
export const useMainRouter = () => {
  const { loading, error, data } = useQuery(getUserProfileFromGql())

  return {
    loading,
    error,
    data,
  }
}
