import { Grid, Input } from '@enterprise-ui/canvas-ui-react'
import { getFilterForFilterId } from './mapping'
import '../../../../../globalStyles/search.scss'

export const RenderFilterComponent = ({
  filter,
  index,
  handleFilterChange,
  handleFavoriteChange,
  favoriteFilters,
  isFavoriteSection,
  selectedValue,
  setSelectedValues,
}) => {
  const FilterComponent = getFilterForFilterId(filter.id)
  if (!FilterComponent) {
    return null
  }
  const isChecked = favoriteFilters.includes(filter.id)

  return (
    <Grid.Container
      className="hc-pt-dense grid-container star_icon"
      id={`filter_sec_${filter?.id}`}
      key={`filter_sec_${filter?.id}`}
    >
      <Grid.Item xs={1} className="hc-pa-none filter-component">
        <Input.IconToggle
          id={`filter_fav_${filter?.id}_${isChecked}${isFavoriteSection}`}
          key={`filter_fav_${filter?.id}_${isChecked}${isFavoriteSection}`}
          className={`hc-pt-md favorite-icon-position ${!isChecked ? 'default' : ''}`}
          onChange={(e) => {
            handleFavoriteChange(filter.id)
          }}
          checked={isChecked}
        />
      </Grid.Item>
      <Grid.Item xs={11} className="zero-top-padding">
        <FilterComponent
          id={filter.id}
          name={filter.displayName}
          onFilterChange={handleFilterChange}
          isFav={isFavoriteSection}
          selectedValue={selectedValue}
          setSelectedValues={setSelectedValues}
        />
      </Grid.Item>
    </Grid.Container>
  )
}
