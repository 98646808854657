import {
  ApolloClient,
  InMemoryCache,
  createHttpLink,
  ApolloLink,
  Observable,
} from '@apollo/client'
import { onError as onApolloError } from '@apollo/client/link/error'
import {
  cloneDeep,
  isArray,
  isNil,
  isObject,
  omit,
  omitBy,
  reject,
  some,
  uniqueId,
} from 'lodash'

import apiConfig from '../globalConfig/apiConfig'
import store, { dispatch } from '../store'
import { fetchBegin, fetchError, fetchSuccess } from '../store/fetch'
import { maintenance } from '../store/maintenance'
import { getUserSessionToken } from '../store/userProfile'

export function onError(error) {
  const { networkError, graphQLErrors = [] } = error
  if (networkError) {
    console.log(error, 'onError')
    // Todo Analytics
  }

  if (graphQLErrors.length > 0) {
    // Todo Analytics
  }
}

export const stripFetchCredentials = (input, init) => {
  const AUTH_HEADERS_TO_STRIP = ['Authorization', 'Cookie']

  const stringIsIn = (s, collection) =>
    some(
      collection,
      (compareString) => compareString.toLowerCase() === s.toLowerCase(),
    )

  if (typeof input !== 'string' && input) {
    for (const header of AUTH_HEADERS_TO_STRIP) {
      input.headers.delete(header)
    }
  }

  if (init && init.headers) {
    if (isArray(init.headers)) {
      init.headers = reject(init.headers, ([header]) =>
        stringIsIn(header, AUTH_HEADERS_TO_STRIP),
      )
    } else if (isObject(init.headers) && !(init.headers instanceof Headers)) {
      init.headers = omitBy(init.headers, (value, header) =>
        stringIsIn(header, AUTH_HEADERS_TO_STRIP),
      )
    } else if (init.headers instanceof Headers) {
      for (const header of AUTH_HEADERS_TO_STRIP) {
        init.headers.delete(header)
      }
    }
  }
}

const logFetchBegin = (correlationId, input, init) => {
  let clonedInput = input
  const clonedInit = cloneDeep(init)
  if (typeof input !== 'string' && input) {
    clonedInput = input.clone()
  }

  stripFetchCredentials(clonedInput, clonedInit)

  dispatch(fetchBegin(correlationId, clonedInput, clonedInit))
}

const logFetchSuccess = async (correlationId, startTime, response) => {
  const clonedResponse = response.clone()
  const headers = {}
  let body = null

  if (apiConfig.debug.networkOptions.body && clonedResponse) {
    body = await clonedResponse.json()
  }

  if (clonedResponse.headers) {
    clonedResponse.headers.forEach((value, name) => (headers[name] = value))
  }

  dispatch(
    fetchSuccess(correlationId, performance.now() - startTime, {
      ...omit(clonedResponse, 'body', 'bodyUsed', 'headers'),
      body,
      headers,
    }),
  )
}

const debugFetch = function (input, init) {
  const correlationId = generateUniqueId()
  const startTime = performance.now()

  try {
    logFetchBegin(correlationId, input, init)
  } catch (logError) {
    console &&
      console.error &&
      console.error('Error while logging fetchBegin', correlationId, logError)
  }

  if (typeof input === 'string' && init && typeof init.body === 'string') {
    const query = JSON.parse(init.body)
    const debugInfo = []

    if (query.operationName) {
      debugInfo.push(query.operationName)
    }

    if (debugInfo.length > 0) {
      input = input + '?' + debugInfo.join('&')
    }
  }

  return fetch.apply(this, [input, init]).then(
    async (response) => {
      try {
        await logFetchSuccess(correlationId, startTime, response)
      } catch (logError) {
        console &&
          console.error &&
          console.error(
            'Error while logging fetchSuccess',
            correlationId,
            logError,
          )
      }

      return response
    },
    (error) => {
      try {
        dispatch(fetchError(correlationId, error))
      } catch (logError) {
        console &&
          console.error &&
          console.error(
            'Error while logging fetchError',
            correlationId,
            logError,
            error,
          )
      }

      return error
    },
  )
}

export const request = (operation) => {
  const authToken = localStorage.getItem('access_token')
  const idToken = localStorage.getItem('id_token')
  const sessionToken = getUserSessionToken(store.getState())

  operation.setContext({
    headers: {
      Authorization: authToken,
      'x-lpp-id2-token': idToken,
      'x-api-key': apiConfig.apiKey,
      user_id: sessionToken ? sessionToken : '',
    },
  })

  return Promise.resolve()
}

const requestLink = new ApolloLink((operation, forward) =>
  forward
    ? new Observable((observer) => {
        let handle
        Promise.resolve(operation)
          .then((oper) => request(oper))
          .then(() => {
            handle = forward(operation).subscribe({
              next: observer.next.bind(observer),
              error: observer.error.bind(observer),
              complete: observer.complete.bind(observer),
            })
          })
          .catch(observer.error.bind(observer))

        return () => {
          if (handle) {
            handle.unsubscribe()
          }
        }
      })
    : null,
)

const maintenanceModeLink = new ApolloLink((operation, forward) =>
  forward
    ? new Observable((observer) => {
        const handle = forward(operation).subscribe({
          next: (result) => {
            if (!result.errors) {
              if (!isNil(result.maintenance)) {
                dispatch(maintenance(result.maintenance))
              } else {
                dispatch(maintenance(false))
              }
              observer.next(result)
            }
          },
          error: observer.error.bind(observer),
          complete: observer.complete.bind(observer),
        })

        return () => {
          if (handle) {
            handle.unsubscribe()
          }
        }
      })
    : null,
)

const cache = new InMemoryCache({
  addTypename: false,
})

// const defaultOptions = {
//   watchQuery: {
//     fetchPolicy: 'no-cache',
//     errorPolicy: 'ignore',
//   },
//   query: {
//     fetchPolicy: 'no-cache',
//     errorPolicy: 'all',
//   },
// }

const httpLink = createHttpLink({
  uri: apiConfig.graphql.uri,
  credentials: 'same-origin',
  fetchOptions: {
    fetchPolicy: 'cache-and-network',
  },
  fetch: apiConfig.debug.enableNetworkLogging ? debugFetch : fetch,
})
export const client = new ApolloClient({
  connectToDevTools: process.env.NODE_ENV === 'development',
  link: ApolloLink.from([
    maintenanceModeLink,
    onApolloError(onError),
    requestLink,
    httpLink,
  ]),
  cache,
  // defaultOptions: defaultOptions,
})

function generateUniqueId() {
  let correlationId = uniqueId()
  const timestamp = Date.now().toString(36)
  const randomStr = Math.random().toString(36).substring(2)
  correlationId = (correlationId + 1) % Number.MAX_SAFE_INTEGER
  const counterStr = correlationId.toString(36).padStart(6, '0') // Ensures the counter has a fixed length
  return `${timestamp}${randomStr}${counterStr}`
}
