import gql from 'graphql-tag'

export const getUserSavedSearchesQuery = () => gql`
  query getUserSavedSearches {
    getUserSavedSearches {
      savedSearches {
        name
        filters {
          id
          values {
            value
            displayValue
          }
        }
        id
        searchText
        favorite
        viewId
        showInDashboard
      }
      error
    }
  }
`

export const getUserSavedSearchesQueryFav = () => gql`
  query getUserSavedSearches {
    getUserSavedSearches {
      savedSearches {
        name
        id
        searchText
      }
      error
    }
  }
`
export const DeleteSavedSearchMutation = () => gql`
  mutation deleteSavedSearch($id: String) {
    deleteSavedSearch(id: $id) {
      id
      success
    }
  }
`
export const setSavedSearchMutation = () => gql`
  mutation setSavedSearch(
    $id: String
    $name: String
    $viewId: String
    $filters: [FilterInput]
    $favorite: Boolean
    $searchText: String
    $showInDashboard: Boolean
  ) {
    setSavedSearch(
      filters: $filters
      name: $name
      id: $id
      favorite: $favorite
      searchText: $searchText
      viewId: $viewId
      showInDashboard: $showInDashboard
    ) {
      savedSearch {
        name
        filters {
          id
          values {
            value
            displayValue
          }
        }
        favorite
        id
        searchText
        viewId
        showInDashboard
      }
      error
    }
  }
`

export const setSavedSearchDashboardMutation = () => gql`
  mutation setSavedSearchDashboard($id: String, $showInDashboard: Boolean) {
    setSavedSearchDashboard(id: $id, showInDashboard: $showInDashboard) {
      savedSearch {
        id
        showInDashboard
      }
      error
    }
  }
`

export const getUserSavedSearchesCountQuery = () => gql`
  query getItemCountsBySavedSearch($savedSearchIds: [String]) {
    getItemCountsBySavedSearch(savedSearchIds: $savedSearchIds) {
      counts {
        id
        count
      }
      errors
    }
  }
`

/**************** For Stickers ****************/
export const getUserBlockerCounts = () => gql`
  query getBlockerCounts(
    $blockerType: String
    $searchText: String
    $filters: [SearchFilter]!
  ) {
    getBlockerCounts(
      blockerType: $blockerType
      searchText: $searchText
      filters: $filters
    ) {
      total
      errors
    }
  }
`

export const getUserBlockerCountsDashboard = () => gql`
  query getBlockerCountsDashboard(
    $filters: [SearchFilterDashboard]
    $searchText: String
    $blockerType: String
  ) {
    getBlockerCountsDashboard(
      filters: $filters
      searchText: $searchText
      blockerType: $blockerType
    ) {
      total
      errors
    }
  }
`

export const getUserNewSearchItems = () => gql`
  query newSearchItems($stickerlabelType: String, $searchText: String) {
    newSearchItems(
      stickerlabelType: $stickerlabelType
      searchText: $searchText
    ) {
      total
      results
      errors
    }
  }
`
export const searchFilterDefinitionsQuery = () => gql`
  query searchFilterDefinitions {
    searchFilterDefinitions {
      filters {
        id
        displayName
        displayLimit
        description
        isPopular
        options {
          value
          displayValue
        }
      }
      errors
    }
  }
`

export const getSearchItemsQuery = (searchColumns) => gql`
  query searchItems($filters: [SearchFilter]!, $pageSize: Int, $skip: Int, $sorting: [Sorting], $searchText: String) {
      searchItems(filters: $filters, pageSize: $pageSize, skip: $skip, sorting: $sorting, searchText: $searchText) {
        results {
          ${getFieldsFromSearchColumns(searchColumns)}
        }
        total
        errors
      }
    }
`

const getFieldsFromSearchColumns = (searchColumns) => {
  const defaultFields = ['isMaintainable', 'isMMBItem']
  return searchColumns
    .map((column) => column.id)
    .concat(defaultFields)
    .join()
}

export const fetchExcelFromGql = () => gql`
  query downloadExcel(
    $filters: [SearchFilter]!
    $attributes: [String]
    $searchText: String
  ) {
    downloadExcel(
      filters: $filters
      searchText: $searchText
      attributes: $attributes
    ) {
      file
      errors
    }
  }
`
export const getMultiSheetBulkExport = () => gql`
  mutation getMultiSheetBulkExportData(
    $filters: [TaskFilter]
    $searchText: String
  ) {
    getMultiSheetBulkExportData(filters: $filters, searchText: $searchText) {
      ExcelExportResponse
      errors
    }
  }
`

export const fetchSearchColumnsFromGql = () => gql`
  query downloadExcel(
    $filters: [SearchFilter]!
    $attributes: [String]
    $searchText: String
  ) {
    downloadExcel(
      filters: $filters
      searchText: $searchText
      attributes: $attributes
    ) {
      file
      errors
    }
  }
`

/**************** For Copy Link Query ****************/
export const setSearchCopyLinkMutation = () => gql`
  mutation setSearchCopyLink($filters: [FilterInput], $searchText: String) {
    setSearchCopyLink(filters: $filters, searchText: $searchText) {
      viewId
      error
    }
  }
`

export const getSearchCopyLinkQuery = () => gql`
  query getSearchCopyLink($viewId: String) {
    getSearchCopyLink(viewId: $viewId) {
      filters {
        id
        values {
          value
          displayValue
        }
      }
      searchText
      error
    }
  }
`

export const setUserProfilePropertyMutation = () => gql`
  mutation setUserProfileProperty(
    $userProfileProperty: [UserProfileProperty!]!
  ) {
    setUserProfileProperty(userProfileProperty: $userProfileProperty) {
      userProfile {
        Common {
          userDeptandClasses {
            deptId
            classId
          }
          completedWalkthroughs
        }
        Dashboard {
          userFiltersandGroupBy {
            taskId
            filters {
              id
              values {
                displayValue
                value
              }
            }
            groupBy
            ignoreUserDashboardFilters
          }
        }
        Search {
          favoriteFilterIds
          columnPreference {
            id
            width
            visible
          }
          useColumnPreference
          isSearchActionPanelExpanded
          savedSearchesOrder
        }
      }
    }
  }
`

export const setSavedSearchDashboardOrderMutation = () => gql`
  mutation setSavedSearchDashboardOrder($orders: [SavedSearchOrderInput!]!) {
    setSavedSearchDashboardOrder(savedSearchOrders: $orders) {
      success
      error
    }
  }
`

export const getUserSavedSearchesDashboardQuery = () => gql`
  query getUserSavedSearches($orderInput: Boolean) {
    getUserSavedSearches(orderInput: $orderInput) {
      savedSearches {
        name
        filters {
          id
          values {
            value
            displayValue
          }
        }
        id
        searchText
        favorite
        viewId
        showInDashboard
        order
      }
      error
    }
  }
`
