import gql from 'graphql-tag'

export const userSessionTokenQuery = () => gql`
  query userSessionToken {
    userSessionToken {
      token
    }
  }
`
export const setUserSessionQuery = gql`
  mutation userSession($session: JSON!) {
    result: userSession(session: $session) {
      tcins
    }
  }
`
