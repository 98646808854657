import { Anchor, Chip } from '@enterprise-ui/canvas-ui-react'
import InfoTableCell from './InfoTable/InfoTableCell.js'
import { useMaintainNav } from './useMaintainNav'
import TcinComment from './Comment/TcinComment.js'

export const MaintainLinkCellRenderer = (props) => {
  const { NavigateToMaintainPage, maintainUrl } = useMaintainNav()
  if (props.value) {
    return props.data.isMaintainable ? (
      <Anchor
        href={maintainUrl}
        onClick={(eve) => NavigateToMaintainPage(eve, [props.value])}
      >
        {props.value}
      </Anchor>
    ) : (
      props.value
    )
  }
  return null
}
// const MaintainLinkCellRenderer = (props: Props) => {
//   if (props.value) {
//     return props.data.isMaintainable ? (
//       <Link
//         onClick={() => props.context.updateTcinsAndNavigateToMaintain([props.value])}
//         className={linkStyle}
//       >
//         {props.value}
//       </Link>
//     ) : (
//       props.value
//     );
//   }
//   return null;
// };

export const thumbnailCellRenderer = (props) => {
  // console.log(props.value)
  // if (props.value) {
  //   const imageElement = document.createElement('img')
  //   imageElement.src = `${props.value}?wid=${props.column?.getMaxWidth()}&make_square=true`
  //   imageElement.alt = `Preview for TCIN ${props.data.tcin}`
  //   return imageElement
  // }
  // const fallbackElement = document.createElement('small')
  // fallbackElement.innerHTML = 'No Image'
  return 'No Image'
}

const getClassname = (className) => {
  const classNameMap = {
    History: 'input-chip',
    'Ready for Order': 'alert-weak-chip',
    'Ready for Launch': 'success-weak-chip',
    Initiated: 'error-weak-chip',
    default: '',
  }

  return classNameMap[className] || classNameMap.default
}

export const ChipTextRenderer = (props) => {
  if (props.value) {
    return <Chip className={getClassname(props.value)}>{props.value}</Chip>
  }
  return null
}

export const InfoTableCellRenderer = (props) => {
  if (props.value) {
    return <InfoTableCell {...props.value} />
  }
  return null
}

export const CommentCellRenderer = (props) => {
  return <TcinComment tcin={props.data.tcin} mostRecentComment={props.value} />
}
