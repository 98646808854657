import { useSelector } from 'react-redux'
import { intersection } from 'lodash'
import { QUICK_NAVIGATION } from '../constant'
import { getUserInfo } from '../../../globalUtils/commonUtills'

export const useQuickNavigation = () => {
  const getUserProfile = useSelector((state) => getUserInfo(state))
  const hasRole = (userRoles, allowedRoles) => {
    return intersection(userRoles, allowedRoles).length > 0
  }
  const navigationMenu = () => {
    if (getUserProfile) {
      return QUICK_NAVIGATION.filter((feature) => {
        return (
          feature.enabled === true ||
          hasRole(getUserProfile.roles, feature?.enabled)
        )
      })
    }
    return []
  }
  const navigationItems = navigationMenu()
  return {
    navigationItems,
    handleNavigation: (path) => {
      window.location.href = path
    },
  }
}
