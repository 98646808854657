import {
  List,
  Grid,
  Button,
  Placeholder,
  Tooltip,
  useToaster,
} from '@enterprise-ui/canvas-ui-react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { useQuery } from '@apollo/client'

import { getUserBlockerCountsDashboard } from '../../../apiItemGraphql/userSearch'
import EnterpriseIcon, { DragIcon, MinusCircleIcon } from '@enterprise-ui/icons'
import { calculatePercentage } from '../../../globalUtils/dashboardUtils'
import { omitTypenameDeep } from '../../../globalUtils/commonUtills'
import {
  setBlockerFilters,
  setSearchText,
  updateSavedSearchFilters,
} from '../../../store/userSearchProfile'
import { useSearchHeader } from '../../searchGrid/header/useSearchHeader'
import { tabMapping } from '../constant'

const SavedSearchDashboardItem = ({
  search: { id, name, searchText, filters },
  removeSavedSearch,
}) => {
  const { loading, data, error } = useQuery(getUserBlockerCountsDashboard(), {
    variables: {
      filters: omitTypenameDeep(filters),
      searchText,
      blockerType: 'rfl_dashboard-maintainer',
    },
  })
  const totals = data?.getBlockerCountsDashboard?.total || {}
  const makeToast = useToaster()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { onTabSelect } = useSearchHeader()

  const handleGridItemClick = (blockerName) => {
    dispatch(updateSavedSearchFilters('all'))
    const blockerFilter = [
      {
        id: 'sticker',
        values: [
          {
            value: blockerName.split(/\s+/).join('_'),
            displayValue: blockerName,
          },
        ],
      },
      ...omitTypenameDeep(filters),
    ]
    dispatch(setSearchText(searchText))
    dispatch(setBlockerFilters(blockerFilter))
    const tabName = tabMapping[blockerName] || 'data_ready_status'
    onTabSelect(null, { name: tabName })
    navigate('/search')
  }

  if (loading) return <Placeholder.Text emphasized height="60px" />

  if (error) {
    makeToast({
      type: 'error',
      heading: 'Error',
      message: error.message,
    })
  }

  return (
    <List.Item className="bg-default-contrast-weak hc-mt-normal" key={id}>
      <Grid.Item xs={3}>
        <h5 className="hc-fs-xs font-weight-400 font-color-default hc-lh-dense">
          {name}
        </h5>
      </Grid.Item>
      <Grid.Item
        xs={2}
        onClick={() => handleGridItemClick('Not Ready for Order')}
      >
        <div className="saved-search-display-block saved-search-nro-block dashboard-item-hover">
          <span className="font-color-default hc-fs-sm font-weight-500">
            {totals['Not Ready for Order']}
          </span>
          <span className="font-color-default saved-search-nro-block-percentage hc-fs-xs font-weight-700">
            {calculatePercentage(
              totals['Not Ready for Order'],
              totals['Total'],
            )}
          </span>
        </div>
      </Grid.Item>
      <Grid.Item
        xs={2}
        onClick={() => handleGridItemClick('Not Ready for Launch')}
      >
        <div className="saved-search-display-block saved-search-nrl-block dashboard-item-hover">
          <span className="font-color-default hc-fs-sm font-weight-500">
            {totals['Not Ready for Launch']}
          </span>
          <span className="font-color-default saved-search-nrl-block-percentage hc-fs-xs font-weight-700">
            {calculatePercentage(
              totals['Not Ready for Launch'],
              totals['Total'],
            )}
          </span>
        </div>
      </Grid.Item>
      <Grid.Item xs={2} onClick={() => handleGridItemClick('Ready for Launch')}>
        <div className="saved-search-display-block saved-search-rl-block dashboard-item-hover">
          <span className="font-color-default hc-fs-sm font-weight-500">
            {totals['Ready for Launch']}
          </span>
          <span className="font-color-default saved-search-rl-block-percentage hc-fs-xs font-weight-700">
            {calculatePercentage(totals['Ready for Launch'], totals['Total'])}
          </span>
        </div>
      </Grid.Item>
      <Grid.Item xs={2}>
        <div className="saved-search-display-block saved-search-total-block">
          <span className="font-color-default hc-fs-sm font-weight-500">
            {totals['Total']}
          </span>
        </div>
      </Grid.Item>
      <Grid.Item xs={1}>
        <div className="saved-search-action-block">
          <EnterpriseIcon icon={DragIcon} className="my-handle cursor-move" />
          <Tooltip content="Delete saved search" location="top">
            <Button
              type="ghost"
              iconOnly
              className="bg-default-contrast-weak"
              onClick={(event) => removeSavedSearch(id)}
              aria-label="Delete a Saved Search"
            >
              <EnterpriseIcon
                icon={MinusCircleIcon}
                className="cursor-pointer"
              />
            </Button>
          </Tooltip>
        </div>
      </Grid.Item>
    </List.Item>
  )
}

export default SavedSearchDashboardItem
