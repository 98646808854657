import { useState, useCallback, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import PropTypes from 'prop-types'
import { useMutation } from '@apollo/client'
import {
  Button,
  Tooltip,
  Drawer,
  useToaster,
} from '@enterprise-ui/canvas-ui-react'
import { Autocomplete } from '@enterprise-ui/canvas-ui-react-autocomplete'
import EnterpriseIcon, { FillAndBackstockIcon } from '@enterprise-ui/icons'
import { refreshSearchFilters } from '../../../store/userSearchProfile'
import { useFetchLov } from '../../hooks/useLov'
import { CROSS_OVER } from '../../../apiItemGraphql/searchActionBar'
import messageConstants from '../../../constants/message-constants'
import { MMB_DEPARTMENT_MAPPING } from '../../../constants/search'

// Custom hook for toaster messages
const useToasterMessage = () => {
  const toaster = useToaster()
  return (type, heading, message) => {
    toaster({ type, heading, message })
  }
}

const CrossOver = ({ selectedTcins, isSameMMBDept, selectedDepartment }) => {
  const toasterMessage = useToasterMessage()
  const dispatch = useDispatch()
  const [isCrossOverOpen, setIsCrossOverOpen] = useState(false)
  const [crossOverItems, { loading }] = useMutation(CROSS_OVER)
  const [departmentValue, setDepartmentValue] = useState(null)
  const [classValue, setClassValue] = useState(null)
  const [vendorValue, setVendorValue] = useState(null)

  useEffect(() => {
    if (departmentValue === null) {
      setClassValue(null)
      setVendorValue(null)
    }
  }, [departmentValue])

  useEffect(() => {
    if (selectedDepartment !== null) {
      const department = selectedDepartment && selectedDepartment[0]
      const value =
        department in MMB_DEPARTMENT_MAPPING
          ? MMB_DEPARTMENT_MAPPING[department]
          : department
      setDepartmentValue({
        id: value,
        value: value,
        label: `${value} - Dept`,
        // label: departments?.find((item) => item.value === selectedDepartment[0])
        //   ?.label,
      })
    }
  }, [selectedDepartment])

  const {
    options: departments,
    loading: deptLoading,
    error: deptError,
  } = useFetchLov({
    variables: { attributeId: 'department' },
    skip: !isCrossOverOpen,
  })

  const {
    options: classes,
    loading: classLoading,
    error: classError,
  } = useFetchLov({
    variables: {
      attributeId: 'class',
      attributeParams: {
        department: [departmentValue?.value],
      },
    },
    skip: !departmentValue,
  })

  const {
    options: vendors,
    loading: vendorLoading,
    error: vendorError,
  } = useFetchLov({
    variables: {
      attributeId: 'vendor',
      attributeParams: {
        department: [departmentValue?.value],
      },
    },
    skip: !departmentValue,
  })

  const cancelCrossOver = () => {
    setIsCrossOverOpen(false)
    setDepartmentValue(null)
    setClassValue(null)
    setVendorValue(null)
  }
  const submitCrossover = useCallback(async () => {
    const crossOverParams = {
      departmentId: departmentValue?.value,
      classId: classValue?.value,
      vendorId: vendorValue?.value,
      tcins: selectedTcins,
    }
    try {
      const result = await crossOverItems({ variables: crossOverParams })
      result?.data?.crossOverItems?.result.forEach((item) => {
        if (item.status !== 'OK') {
          toasterMessage(
            'error',
            `TCIN: ${item.tcin} | Status: ${item.status}`,
            `${item?.errorMesg?.description}`,
          )
        } else {
          toasterMessage(
            'success',
            `TCIN: ${item.tcin} | Status: ${item.status}`,
            `${messageConstants.MMB_CROSSOVER_SUCCESS}`,
          )
          cancelCrossOver()
          dispatch(refreshSearchFilters())
        }
      })
    } catch (error) {
      toasterMessage(
        'error',
        'Crossover Creation Failed',
        `${messageConstants.MMB_CROSSOVER_FAILED}`,
      )
    }
  }, [
    departmentValue,
    classValue,
    vendorValue,
    selectedTcins,
    crossOverItems,
    toasterMessage,
    dispatch,
  ])
  return (
    <>
      <Tooltip content="Crossover" location="top">
        <Button
          type="ghost"
          className="button-bg-hover-transparent"
          onClick={() => setIsCrossOverOpen(true)}
          id="crossover"
          data-testid="crossover"
          disabled={!isSameMMBDept}
        >
          <EnterpriseIcon
            icon={FillAndBackstockIcon}
            size="sm"
            className={
              !isSameMMBDept ? 'hc-clr-grey03' : 'hc-clr-contrast-weak'
            }
          />
        </Button>
      </Tooltip>

      <Drawer
        isVisible={isCrossOverOpen}
        headingText="Crossover MMB Items"
        onRequestClose={() => setIsCrossOverOpen(false)}
        spacing="expanded"
        xs={3}
        className="crossover-sidecart sidecart-search"
        data-testid="crossover-drawer"
      >
        <p>{selectedTcins.length} Items</p>
        <p>
          <strong>New Crossover Department & Class</strong>
        </p>
        <div className="autocomplete-load-section hc-mt-expanded">
          <Autocomplete
            id="departmentId"
            options={departments}
            value={departmentValue}
            onUpdate={(id, value) => setDepartmentValue(value)}
            placeholder="Choose Dept"
            data-testid="department-selection"
          />
          {deptLoading && <span>Loading...</span>}
          {deptError && <p>Failed to load departments</p>}
        </div>

        <div className="autocomplete-load-section hc-mt-expanded">
          <Autocomplete
            id="classId"
            options={classes}
            value={classValue}
            onUpdate={(id, value) => setClassValue(value)}
            placeholder="Choose Class"
            disabled={!departmentValue?.value}
            data-testid="class-selection"
          />
          {classLoading && <span>Loading...</span>}
          {classError && <p>Failed to load classes</p>}
        </div>

        <div className="autocomplete-load-section hc-mt-expanded">
          <Autocomplete
            id="vendorId"
            label="Add Crossover Stores Vendor"
            options={vendors}
            value={vendorValue}
            onUpdate={(id, value) => setVendorValue(value)}
            placeholder="Vendor Name (min. 2 characters)"
            disabled={!departmentValue?.value}
            data-testid="vendor-selection"
          />
          {vendorLoading && <span>Loading...</span>}
          {vendorError && <p>Failed to load vendors</p>}
        </div>

        <div className="mmb-action-bar hc-mt-xl">
          <Button
            type="secondary"
            className="hc-mr-expanded"
            onClick={cancelCrossOver}
            data-testid="cancel-crossover"
          >
            Cancel
          </Button>
          <Button
            className="crossover-submit"
            type="primary"
            onClick={submitCrossover}
            disabled={!departmentValue || !classValue || !vendorValue}
            isLoading={loading}
            data-testid="submit-crossover"
          >
            Crossover
          </Button>
        </div>
      </Drawer>
    </>
  )
}

CrossOver.propTypes = {
  selectedTcins: PropTypes.arrayOf(PropTypes.string).isRequired,
}

export default CrossOver
