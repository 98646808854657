import { Routes, Route, useLocation } from 'react-router-dom'
import { ProtectedElement } from '@praxis/component-auth'
import { ErrorBoundary } from '@praxis/component-logging'
import { ToastProvider } from '@enterprise-ui/canvas-ui-react'
import { ErrorContent } from './ErrorContent'
import { SearchHome } from '../views/searchGrid/'
import { DashboardPage } from '../views/dashboard'
import { Unauthorized } from '../views/common/Unauthorized'
import { PageNotFound } from '../views/common/PageNotFound'
import { AuthLogin } from '../views/common/AuthLogin'

export const MainRouter = () => {
  const location = useLocation()
  return (
    <ErrorBoundary key={location.pathname} FallbackComponent={ErrorContent}>
      <ToastProvider location="top">
        <Routes>
          <>
            <Route
              path="/"
              element={
                <ProtectedElement>
                  <DashboardPage />
                </ProtectedElement>
              }
              unauthorizedRoute={'/unauthorized'}
            />
            {['/search', '/item-search/:path'].map((path) => (
              <Route
                key={path}
                path={path}
                element={
                  <ProtectedElement>
                    <SearchHome />
                  </ProtectedElement>
                }
                unauthorizedRoute={'/unauthorized'}
              />
            ))}
          </>
          <Route path="/auth/login" element={<AuthLogin />} />
          <Route path="/unauthorized" element={<Unauthorized />} />
          <Route path="*" element={<PageNotFound />} />
        </Routes>
      </ToastProvider>
    </ErrorBoundary>
  )
}
