import { useState, useEffect } from 'react'
import { useQuery, useMutation } from '@apollo/client'
import {
  getUserSavedSearchesDashboardQuery,
  setSavedSearchDashboardMutation,
} from '../../../apiItemGraphql/userSearch'
import { useToaster } from '@enterprise-ui/canvas-ui-react'

export const useMyDashboard = () => {
  const { data, loading, error } = useQuery(
    getUserSavedSearchesDashboardQuery(),
    { variables: { orderInput: true } },
  )
  const [editSearch] = useMutation(setSavedSearchDashboardMutation())
  const [isSavedSearchOpen, setIsSavedSearchOpen] = useState(false)
  const [savedSearches, setSavedSearches] = useState([])
  const [checkedItems, setCheckedItems] = useState([])
  const makeToast = useToaster()

  useEffect(() => {
    if (data && data.getUserSavedSearches) {
      setSavedSearches(data.getUserSavedSearches.savedSearches)
      const initialCheckedItems = data.getUserSavedSearches.savedSearches.map(
        (search) => ({
          id: search.id,
          showInDashboard: search.showInDashboard,
        }),
      )
      setCheckedItems(initialCheckedItems)
    }
  }, [data])

  const handleSavedSearchesModal = () => {
    setIsSavedSearchOpen(!isSavedSearchOpen)
  }

  const handleCheckboxChange = (id) => {
    setCheckedItems((prevState) =>
      prevState.map((item) =>
        item.id === id
          ? { ...item, showInDashboard: !item.showInDashboard }
          : item,
      ),
    )
  }

  const handleConfirm = async () => {
    try {
      await Promise.all(
        checkedItems.map((item) =>
          editSearch({
            variables: {
              id: item.id,
              showInDashboard: item.showInDashboard,
            },
            refetchQueries: [
              {
                query: getUserSavedSearchesDashboardQuery(),
                variables: { orderInput: true },
              },
            ],
          }),
        ),
      )
      setIsSavedSearchOpen(false)
    } catch (error) {
      makeToast({
        type: 'error',
        heading: 'Error',
        message: error,
      })
    }
  }

  return {
    loading,
    error,
    savedSearches,
    isSavedSearchOpen,
    editSearch,
    handleSavedSearchesModal,
    handleCheckboxChange,
    handleConfirm,
  }
}
