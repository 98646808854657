import { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useQuery } from '@apollo/client'
import { searchFilterDefinitions } from '../../../../../store/userSearchProfile'
import { searchFilterDefinitionsQuery } from '../../../../../apiItemGraphql/userSearch'
import { omitTypenameDeep } from '../../../../../globalUtils/commonUtills'
import { filterIds } from '../../../searchConstants'

export const useAllFilters = () => {
  const dispatch = useDispatch()
  const [searchFilter, setSearchFilter] = useState([])
  const filterData = (data) => {
    const output = filterIds[0] // since filterIds is an array with a single object
    const result = Object.keys(output)
      .map((key) => {
        const foundInList = data.find((item) => item.id === key)
        return foundInList ? foundInList : {}
      })
      .filter((el) => Object.keys(el).length)
    return result
  }

  const { loading, error, data } = useQuery(searchFilterDefinitionsQuery())

  useEffect(() => {
    if (!data) return
    const searchFilter = omitTypenameDeep(data?.searchFilterDefinitions.filters)
    const filteredSearchFilter = filterData(searchFilter)
    setSearchFilter(filteredSearchFilter)
    dispatch(searchFilterDefinitions(searchFilter))
  }, [data, dispatch])

  return {
    loading,
    error,
    searchFilter,
  }
}
