import { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { setHeaderCheckboxState } from '../../../store/searchColumns'

const useHeaderCheckbox = (api) => {
  const dispatch = useDispatch()
  const isHeaderChecked = useSelector(
    (state) => state.searchColumns.headerCheckbox,
  )
  const [isDisabled, setIsDisabled] = useState(false)

  const getTotalResult = api?.getDisplayedRowCount()

  useEffect(() => {
    if (getTotalResult > 10000) {
      setIsDisabled(true)
    } else {
      setIsDisabled(false)
    }
  }, [getTotalResult])

  const handleCheckboxChange = (event) => {
    const checked = event.target.checked
    dispatch(setHeaderCheckboxState(checked))

    // Select or deselect all rows in the grid
    if (checked) {
      api.selectAll()
    } else {
      api.deselectAll()
    }
  }

  return {
    isHeaderChecked,
    isDisabled,
    handleCheckboxChange,
  }
}

export default useHeaderCheckbox
