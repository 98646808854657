import { Button, Tooltip, useToaster } from '@enterprise-ui/canvas-ui-react'
import EnterpriseIcon, { CheckIcon } from '@enterprise-ui/icons'
import { useMutation } from '@apollo/client'
import { useSelector } from 'react-redux'
import { SET_USER_SESSION_MUTATION } from '../../../apiItemGraphql/searchActionBar'
import {
  filterWithMultipleMatches,
  filterTCINs,
} from '../../../globalUtils/searchUtills'
import { SEARCH_ACTIONS } from '../../../constants/search'
import apiConfig from '../../../globalConfig/apiConfig'

const ValidateItemData = ({ selectedItems }) => {
  const toaster = useToaster()
  const selectedMaintainableTcins = filterTCINs(
    filterWithMultipleMatches(selectedItems, {
      isMaintainable: true,
    }),
  )

  const isVendorFilterApplied = useSelector((state) => {
    const { userSearchProfile } = state
    const vendorFilter =
      userSearchProfile?.savedSearch?.activeSavedFilters.filter(
        (search) => search?.id === 'vendor',
      )
    return vendorFilter.some((filter) => filter.values.length > 0)
  }, [])

  const selectedItemsLength = selectedItems?.length
  const selectedValidateTcinsLength = selectedMaintainableTcins?.length
  const validateItemButtonDisabled =
    selectedValidateTcinsLength === 0 ||
    selectedValidateTcinsLength > SEARCH_ACTIONS.MAX_VALIDATE_ITEMS ||
    !isVendorFilterApplied

  let tooltipText = 'Validate Item Data'
  if (
    selectedItemsLength > 0 &&
    selectedValidateTcinsLength < selectedItemsLength
  ) {
    tooltipText = `Validate ${selectedValidateTcinsLength} out of ${selectedItemsLength} Items: Max ${SEARCH_ACTIONS.MAX_VALIDATE_ITEMS}`
  } else if (selectedItemsLength > 0 && !isVendorFilterApplied) {
    tooltipText = `No Manageable Items Selected or Must have one and only one Vendor filtered`
  } else if (selectedItemsLength > 0) {
    tooltipText = `Validate Item Data: Max ${SEARCH_ACTIONS.MAX_VALIDATE_ITEMS}`
  }

  const [setUserSession, { loading }] = useMutation(SET_USER_SESSION_MUTATION, {
    fetchPolicy: 'no-cache',
  })

  const validateItems = async () => {
    const session = {
      tcins: selectedMaintainableTcins,
    }

    try {
      const sessionData = await setUserSession({ variables: { session } })
      if (sessionData?.data?.result?.tcins) {
        const LP_MAINTAIN = `${apiConfig.launchpad.host}${apiConfig.launchpad.page.maintain}`
        window.open(LP_MAINTAIN)
      }
    } catch (error) {
      toaster({
        type: 'error',
        heading: 'Session Error',
        message: error,
      })
    }
  }

  return (
    <>
      <Tooltip content={tooltipText} location="top">
        <Button
          type="ghost"
          className="button-bg-hover-transparent"
          disabled={validateItemButtonDisabled}
          onClick={validateItems}
          isLoading={loading}
          data-testid="validate-item"
        >
          <EnterpriseIcon
            icon={CheckIcon}
            size="sm"
            className={
              validateItemButtonDisabled
                ? 'hc-clr-grey03'
                : 'hc-clr-contrast-weak'
            }
          />
        </Button>
      </Tooltip>
    </>
  )
}

export default ValidateItemData
