import { CheckboxFilters } from './resuableFilters/checkboxFilters'
import { DateFilters } from './resuableFilters/dateFilters'
import { TextBoxFilter } from './filters/tcinFilter'
import { MerchandiseHierarchyFilter } from './filters/merchandiseHierarchyFilter'
import { VendorFilter } from './filters/vendorFilter'
import { MerchItemTypeFilter } from './filters/merchItemTypeFilter'
import { ProductBrandFilter } from './filters/productBrandFilter'
import { PyramidDivisionFilter } from './filters/pyramidDivision'
import { BarcodeFilter } from './filters/barcodeFilter'
import { DateFilterRelative } from './filters/dateFilterRelative'
import { DpciFilterComponent } from './filters/dpciFilter'
import { ManufacturerStyleNumberFilter } from './filters/manufacturerStyleNumberFilter'

const mapping = {
  tcin: TextBoxFilter,
  dpci: DpciFilterComponent,
  barcode: BarcodeFilter,
  manufacturerStyleNumber: ManufacturerStyleNumberFilter,
  itemCreateDateRelative: DateFilterRelative,
  merchandiseHierarchy: MerchandiseHierarchyFilter,
  launchDateRelative: DateFilterRelative,
  launchDate: DateFilters,
  vendor: VendorFilter,
  imnComplete: null,
  launchPadVendorComplete: null,
  launchPadComplete: null,
  launchPadCreatedTimestamp: DateFilters,
  itemSetupStatus: CheckboxFilters,
  partnersApproved: null,
  hazmatPublished: null,
  hasPrimaryImage: null,
  importApprovalStatus: CheckboxFilters,
  hasRetailPrice: null,
  merchantPublished: null,
  hasDataQualityIssues: null,
  commitmentDate: DateFilters,
  launchPadReadyForMaintainer: null,
  assortmentPlanName: null,
  itemLocationStatus: CheckboxFilters,
  merchItemType: MerchItemTypeFilter,
  productBrand: ProductBrandFilter,
  pyramidDivision: PyramidDivisionFilter,
  relationshipType: CheckboxFilters,
  commitmentDateRelative: DateFilterRelative,
  maintenanceRequestDateRelative: DateFilterRelative,
  maintenanceRequestType: null,
  maintenanceRequestTaskStatus: null,
  maintenanceRequestUpdateUser: null,
  maintenanceRequestRequestorUser: null,
  legacyCreateType: CheckboxFilters,
  launchPadStatus: CheckboxFilters,
  assortmentType: CheckboxFilters,
}

export const getFilterForFilterId = (id) => {
  const input = mapping[id]

  return input
}
