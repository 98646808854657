import '@enterprise-ui/canvas-ui-css'
import { useState } from 'react'
import { isEmpty } from 'lodash'
import { Form } from '@enterprise-ui/canvas-ui-react'
import { getBarcodesFromInput } from './helper'
import '../../../../../../globalStyles/search.scss'
import { SEARCH_TEXT } from '../../../../searchConstants'
import { useEffect } from 'react'

export const BarcodeFilter = (filter) => {
  const {
    id: filterId,
    name,
    onFilterChange,
    isFav,
    selectedValue,
    setSelectedValues,
  } = filter
  const [errorState, setErrorState] = useState('')
  // eslint-disable-next-line no-unused-vars
  const [resetString, setResetString] = useState('')
  // Passing only valid barcodes to redux
  const validate = (value) => {
    const results = getBarcodesFromInput(value)
    const validCode = results?.valid?.map((barcode) => ({
      value: barcode,
      displayValue: barcode,
    }))
    const resetStringLocal = results?.invalid

    const isValid = results?.invalid?.length === 0
    setErrorState(!isValid)

    onFilterChange(filterId, !isEmpty(validCode) ? validCode : null)
    setResetString(resetStringLocal?.join())
  }

  const handleUpdate = (e) => {
    const newValue = e.target.value
    setSelectedValues((prev) => ({ ...prev, [filterId]: newValue }))
    validate(newValue)
  }
  useEffect(() => {
    // Validate the initial selectedValue on component mount
    if (selectedValue) {
      validate(selectedValue)
    } else {
      setSelectedValues((prev) => ({ ...prev, [filterId]: '' }))
      setErrorState(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedValue])

  return (
    <Form.Field
      id={`${filterId}${isFav}-filter`}
      key={`${filterId}${isFav}-filter`}
      label={name}
      className={errorState ? 'dpci_filter' : ''}
      placeholder={name}
      type="text"
      error={errorState}
      errorText={SEARCH_TEXT.ERROR_TEXT.BARCODE_ERROR}
      value={selectedValue}
      onChange={(e) => handleUpdate(e)}
    />
  )
}
