import gql from 'graphql-tag'
// Download DQISSUES
export const DOWNLOAD_DQ_ISSUES = gql`
  query downloadDQIssuesLookup(
    $attributeId: String
    $tcins: [String]
    $includeExcelDownload: Boolean
  ) {
    downloadDQIssuesLookup(
      attributeId: $attributeId
      tcins: $tcins
      includeExcelDownload: $includeExcelDownload
    ) {
      attributeId
      tcins
      headings {
        key
        displayName
      }
      rowsByTcin
      errors
      excelFile
    }
  }
`

// Redirect to Maintain Screen: Need to set TCINS with the User Session
export const SET_USER_SESSION_MUTATION = gql`
  mutation userSession($session: JSON!) {
    result: userSession(session: $session) {
      tcins
    }
  }
`

// Delete ITEMS
export const DELETE_ITEMS = gql`
  mutation deleteItems($tcins: [String]) {
    deleteItems(deleteParams: { tcins: $tcins }) {
      status
      deleteResponses {
        tcin
        status
        errorMessage
      }
    }
  }
`
export const CROSS_OVER = gql`
  mutation crossOverItems(
    $tcins: [String!]!
    $departmentId: Int!
    $classId: Int!
    $vendorId: Int!
  ) {
    crossOverItems(
      crossOverItemsParams: {
        tcins: $tcins
        departmentId: $departmentId
        classId: $classId
        vendorId: $vendorId
      }
    ) {
      result {
        tcin
        status
        errorMesg
      }
      errors
    }
  }
`

export const PUBLISH_UNPUBLISH_MMB = gql`
  mutation updateMerchantPublishingStatus(
    $tcins: [String!]!
    $merchantPublishFlag: Boolean!
    $unpublishReason: String
  ) {
    updateMerchantPublishingStatus(
      updateMerchantPublishingStatusParams: {
        tcins: $tcins
        merchantPublishFlag: $merchantPublishFlag
        unpublishReason: $unpublishReason
      }
    ) {
      result {
        tcin
        status
        errorMesg
      }
      errors
    }
  }
`
