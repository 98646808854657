import { createSlice } from '@reduxjs/toolkit'

const searchHeaderSlice = createSlice({
  name: 'searchHeader',
  initialState: {
    activeTab: 'data_ready_status',
  },
  reducers: {
    setActiveTab: (state, action) => {
      state.activeTab = action.payload
    },
  },
})

export const { setActiveTab } = searchHeaderSlice.actions
export default searchHeaderSlice.reducer
