import PropTypes from 'prop-types'
import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { Button, Tooltip } from '@enterprise-ui/canvas-ui-react'
import EnterpriseIcon, { CancelIcon } from '@enterprise-ui/icons'
import { setHeaderCheckboxState } from '../../../store/searchColumns'

const ClearItems = ({ gridApi }) => {
  const dispatch = useDispatch()
  const clearSelectedItems = useCallback(() => {
    dispatch(setHeaderCheckboxState(false))
    gridApi?.current?.api?.deselectAll()
  }, [dispatch, gridApi])

  return (
    <Tooltip content="Clear Items" location="top">
      <Button
        type="ghost"
        className="button-bg-hover-transparent"
        onClick={clearSelectedItems}
        disabled={!gridApi}
      >
        <EnterpriseIcon
          icon={CancelIcon}
          size="sm"
          className="hc-clr-contrast-weak"
        />
      </Button>
    </Tooltip>
  )
}

ClearItems.propTypes = {
  gridApi: PropTypes.shape({
    current: PropTypes.shape({
      api: PropTypes.shape({
        deselectAll: PropTypes.func.isRequired,
      }).isRequired,
    }).isRequired,
  }),
}

export default ClearItems
