import { Button } from '@enterprise-ui/canvas-ui-react'
import { useState } from 'react'
import { useDispatch } from 'react-redux'
import CommentInputModal from './CommentInputModal'
import { refreshSearchFilters } from '../../../../store/userSearchProfile'

const TcinComment = ({ tcin, mostRecentComment }) => {
  const dispatch = useDispatch()
  const [isModalVisible, setModalVisibility] = useState(false)
  const onClose = () => {
    setModalVisibility(false)
    dispatch(refreshSearchFilters())
  }
  const className = mostRecentComment ? 'hc-clr-contrast' : ''
  return (
    <>
      <Button
        type="ghost"
        onClick={() => setModalVisibility(true)}
        data-testid="add-comment-button"
        aria-label="Add comment button"
      >
        <u className={className}>
          {mostRecentComment ? mostRecentComment : 'Add Comment'}
        </u>
      </Button>
      {isModalVisible && (
        <CommentInputModal
          visible={isModalVisible}
          onClose={() => onClose()}
          batchAdd={false}
          tcins={[tcin]}
        />
      )}
    </>
  )
}

export default TcinComment
