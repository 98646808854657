import { useMutation } from '@apollo/client'
import { useToaster } from '@enterprise-ui/canvas-ui-react'
import { SET_USER_SESSION_MUTATION } from '../../../apiItemGraphql/searchActionBar'
import apiConfig from '../../../globalConfig/apiConfig'
import { useFirefly } from '../../../globalUtils/useAnalytics'
import { FIREFLY_EVENTS } from '../../../constants/search'

export const useMaintainNav = () => {
  const maintainUrl = `${apiConfig.launchpad.host}${apiConfig.launchpad.page.maintain}`
  const makeToast = useToaster()
  const { handleTrackCustomEvent } = useFirefly()

  const [setUserSession] = useMutation(SET_USER_SESSION_MUTATION, {
    fetchPolicy: 'no-cache',
  })
  const NavigateToMaintainPage = async (eve, tcins) => {
    eve.preventDefault()
    const session = {
      tcins: tcins,
    }
    try {
      const sessionData = await setUserSession({ variables: { session } })

      if (sessionData?.data?.result?.tcins) {
        //event capture
        handleTrackCustomEvent(
          FIREFLY_EVENTS.EVENT_TYPE.GRID_ACTION,
          FIREFLY_EVENTS.CUSTOM_INTERACTION_KEYS.MAINTAIN,
          tcins?.length,
        )
        window.location.assign(maintainUrl)
      }
    } catch (error) {
      makeToast({
        type: 'error',
        heading: 'Error Redirecting to maintain page',
        message: error,
      })
    }
  }

  return {
    NavigateToMaintainPage,
    maintainUrl,
  }
}
