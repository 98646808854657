import '@enterprise-ui/canvas-ui-css'
import { Grid } from '@enterprise-ui/canvas-ui-react'
import { Autocomplete } from '@enterprise-ui/canvas-ui-react-autocomplete'
import { capitalize } from 'lodash'
import { useState, useEffect } from 'react'
import { useFetchLov } from '../../../../../hooks/useLov'
import { useDispatch } from 'react-redux'
import { updateSearchDependentFilter } from '../../../../../../store/userSearchProfile'
import useFetchOptions from '../../../../../hooks/useFetchOptions'
import { SEARCH_TEXT } from '../../../../searchConstants'

const FilterComponent = ({
  filterId,
  attributeId,
  attributeKey,
  dependentAttributeId,
  dependentAttributeKey,
  label,
  dependentLabel,
  onFilterChange,
  placeholder,
  isFav,
  selectedValue,
  setSelectedValues,
}) => {
  const [selectedOption, setselectedOption] = useState(null)
  const [selectedDependentValue, setSelectedDependentValue] = useState(null)
  const dispatch = useDispatch()
  const { refetch: fetchMainOptions } = useFetchLov({
    variables: { attributeId },
    skip: true,
  })
  const { refetch: fetchDependentOptions } = useFetchLov({
    variables: {
      attributeId: dependentAttributeId,
      attributeParams: { [attributeId]: [selectedOption?.value] },
    },
    skip: true,
  })

  const {
    fetchedOptions: options,
    loading: mainLoading,
    setFetch: setMainFetch,
    setSearchText: setMainSearchText,
  } = useFetchOptions(fetchMainOptions, attributeId)

  const {
    fetchedOptions: dependentOptions,
    loading: dependentLoading,
    setFetch: setDependentFetch,
    setSearchText: setDependentSearchText,
  } = useFetchOptions(
    fetchDependentOptions,
    dependentAttributeId,
    attributeId,
    selectedOption?.value ? selectedOption?.value : null,
  )

  const handleUpdate = (id, value, isDependent) => {
    if (isDependent) {
      setSelectedDependentValue(value)
      setSelectedValues((prev) => ({
        ...prev,
        [filterId]: {
          ...(prev?.[filterId] || {}),
          [dependentAttributeKey]: value,
        },
      }))
      const valuesList = [
        {
          value: {
            [dependentAttributeKey]: value?.value,
            [attributeKey]: selectedOption?.value,
          },
          pending: true,
          displayValue: `${capitalize(attributeId)} ${selectedOption?.value || ''} ${value?.value !== undefined ? `${capitalize(dependentAttributeId)} ${value.value}` : ''}`,
        },
      ]
      onFilterChange(filterId, valuesList)
      // handleDispatch(dispatch, filterId, valuesList)
    } else {
      if (id.includes('department')) {
        //handleDispatch(dispatch, { id: attributeId, value })
        dispatch(
          updateSearchDependentFilter({ id: attributeId, value: value?.value }),
        )
      }
      setSelectedValues((prev) => ({
        ...prev,
        [filterId]: {
          [attributeKey]: value,
        },
      }))
      setselectedOption(value)
      setSelectedDependentValue(null)
      const valuesList = value
        ? [
            {
              value: {
                [dependentAttributeKey]: null,
                [attributeKey]: value.value,
              },
              pending: true,
              displayValue: `${capitalize(attributeId)} ${value?.value}`,
            },
          ]
        : null // cuz we want to delete entire filter id if the dependent attribute is null
      onFilterChange(filterId, valuesList)
      //handleDispatch(dispatch, filterId, valuesList)
    }
  }

  useEffect(() => {
    setDependentFetch(false)
    if (selectedOption) {
      setDependentSearchText('')
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedOption])

  useEffect(() => {
    if (selectedValue) {
      setselectedOption(selectedValue[attributeKey] || null)
      setSelectedDependentValue(selectedValue[dependentAttributeKey] || null)
    } else {
      setselectedOption(null)
      setSelectedDependentValue(null)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedValue])

  return (
    <Grid.Item>
      <Grid.Item onClick={() => setMainFetch(true)}>
        <Autocomplete
          id={`${attributeId}-filter`}
          key={`${attributeId}${isFav}-filter`}
          label={label}
          value={selectedOption}
          options={(searchText) => {
            setMainSearchText(searchText)
            return options
          }}
          noResultsMessage={
            mainLoading ? SEARCH_TEXT.LOV.LOADING : SEARCH_TEXT.LOV.NO_RESULTS
          }
          placeholder={placeholder ? placeholder : label}
          onUpdate={(id, value) => handleUpdate(id, value, false)}
        />
      </Grid.Item>
      <Grid.Item onClick={() => setDependentFetch(true)}>
        <Autocomplete
          id={`${dependentAttributeId}-filter`}
          key={`${dependentAttributeId}${isFav}-filter`}
          label={dependentLabel}
          required={false}
          value={selectedDependentValue}
          options={(searchText) => {
            setDependentSearchText(searchText)
            return dependentOptions
          }}
          noResultsMessage={
            dependentLoading
              ? SEARCH_TEXT.LOV.LOADING
              : SEARCH_TEXT.LOV.NO_RESULTS
          }
          disabled={!selectedOption}
          placeholder={placeholder ? placeholder : dependentLabel}
          loading={dependentLoading}
          onUpdate={(id, value) => handleUpdate(id, value, true)}
        />
      </Grid.Item>
    </Grid.Item>
  )
}

export default FilterComponent
