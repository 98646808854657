import { useState } from 'react'

export const useQuickSearch = () => {
  const [activeFilterTab, setFilterTab] = useState('all-filters')
  const onTabSelect = (event, tab) => {
    setFilterTab(tab.name)
  }

  return {
    onTabSelect,
    activeFilterTab,
  }
}
