import {
  Grid,
  Input,
  Button,
  Tooltip,
  Card,
} from '@enterprise-ui/canvas-ui-react'
import EnterpriseIcon, { PencilIcon, TrashIcon } from '@enterprise-ui/icons'
import { SavedSearchModal } from './SavedSearchModal'
import { useSavedSearchModal } from './useSavedSearchModal'
import '../../../../../globalStyles/customStyles.scss'
import '../../../../../globalStyles/search.scss'
import { useSavedFilters } from './useSavedFilters'
export const SavedSearchComponent = (props) => {
  const {
    data,
    handleEdit,
    handleDelete,
    type,
    handleButtonClick,
    activeSavedSearchId,
  } = props
  const { handleInfoClick } = useSavedFilters()
  const { handelModal, showEditModal, editSavedSearch, modalName } =
    useSavedSearchModal()
  return (
    <>
      {data.map((savedSearch, index) => (
        <Grid.Container
          align="center"
          className="hc-ma-none saved-filters"
          style={{ width: '97%' }}
          // className="hc-pl-min hc-pr-min hc-pb-normal "
          key={`saved-search-grid-${type}-${index}`}
        >
          <Grid.Item
            key={`saved-search-fav-${type}-${index}`}
            xs={1}
            className="hc-pa-none"
          >
            <Tooltip content="Mark as favorite for quick access" location="top">
              <Input.IconToggle
                className={type === 'all' ? 'default' : ''}
                key={`saved-search-fav-icon-${type}-${index}`}
                id={`saved-search-fav-${type}-${index}`}
                checked={savedSearch.favorite}
                onChange={(e) => {
                  handleEdit({
                    ...savedSearch,
                    favorite: e.target.checked,
                  })
                }}
              />
            </Tooltip>
          </Grid.Item>
          <Grid.Item
            xs={9}
            className="hc-pa-none"
            key={`saved-search-info-${type}-${index}`}
          >
            <Card
              className={`hc-ma-normal savedContainer ${activeSavedSearchId === savedSearch.id ? 'active' : ''}`}
              onClick={() => {
                handleInfoClick(savedSearch)
              }}
            >
              <p className="hc-pl-dense hc-pt-dense hc-mb-min">
                {savedSearch.name}
              </p>
              <p className="hc-pl-dense hc-pt-min hc-pb-dense">
                {savedSearch?.count > 0 ? (
                  <strong>{savedSearch?.count?.toLocaleString()}</strong>
                ) : (
                  <span> </span>
                )}
              </p>
            </Card>
          </Grid.Item>
          <Grid.Item
            xs={1}
            key={`saved-search-edit-${type}-${index}`}
            className="hc-pa-none"
          >
            <Tooltip content="Edit name" location="top">
              <Button
                iconOnly
                aria-label={`Click to Edit ${savedSearch.name}`}
                type="ghost"
                key={`edit_${savedSearch.id}`}
                className="hc-pl-none"
                onClick={(e) => {
                  handelModal(savedSearch, 'edit')
                }}
              >
                <EnterpriseIcon icon={PencilIcon} />
              </Button>
            </Tooltip>
          </Grid.Item>
          <Grid.Item
            xs={1}
            className="hc-pa-none"
            key={`saved-search-delete-${type}-${index}`}
          >
            <Tooltip content="Delete saved search" location="top">
              <Button
                iconOnly
                aria-label={`Click to Delete ${savedSearch.name}`}
                type="ghost"
                key={`delete_${savedSearch.id}`}
                className="hc-pl-none"
                onClick={(e) => {
                  handelModal(savedSearch, 'delete')
                }}
              >
                <EnterpriseIcon icon={TrashIcon} />
              </Button>
            </Tooltip>
          </Grid.Item>
        </Grid.Container>
      ))}
      <SavedSearchModal
        handelModal={handelModal}
        showEditModal={showEditModal}
        editSavedSearch={editSavedSearch}
        handleEdit={handleEdit}
        handleDelete={handleDelete}
        handleButtonClick={handleButtonClick}
        modalName={modalName}
      />
    </>
  )
}
