import { useDispatch, useSelector } from 'react-redux'
import {
  clearQuickSearch,
  updateSavedSearchFilters,
  clearSticker,
} from '../../../../store/userSearchProfile'

export const useAppliedFilters = () => {
  const dispatch = useDispatch()

  const appliedFilters = useSelector((state) => {
    const { userSearchProfile } = state
    return userSearchProfile.savedSearch.activeSavedFilters
  }, [])
  const searchText = useSelector((state) => {
    const { userSearchProfile } = state
    const { filters } = userSearchProfile
    return filters.searchText
  }, [])
  const stickerType = useSelector((state) => {
    const { userSearchProfile } = state
    const { filters } = userSearchProfile
    return filters.stickerTypeId[0]?.values[0]?.displayValue
  }, [])
  const deleteAllFilters = (eve) => {
    eve.preventDefault()
    dispatch(updateSavedSearchFilters('all'))
    localStorage.removeItem('LP_SEARCH_CRITERIA')
  }
  const handleDeleteQuickSearch = (eve) => {
    dispatch(clearQuickSearch([]))
  }
  const handleDeleteSticker = (eve) => {
    dispatch(clearSticker([]))
  }

  return {
    appliedFilters,
    searchText,
    stickerType,
    deleteAllFilters,
    handleDeleteQuickSearch,
    handleDeleteSticker,
  }
}
