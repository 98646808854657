import { debounce } from 'lodash'
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setSearchText as setSearchTextAction } from '../../../../store/userSearchProfile'
import { useFirefly } from '../../../../globalUtils/useAnalytics'
import { FIREFLY_EVENTS } from '../../../../constants/search'

export const useQuickSearch = () => {
  const [inputText, setInputText] = useState('')
  const { handleTrackCustomEvent } = useFirefly()
  const searchText = useSelector((state) => {
    const { userSearchProfile } = state
    const { filters } = userSearchProfile
    return filters.searchText
  }, [])

  const dispatch = useDispatch()
  const handleInputChange = (e) => {
    setInputText(e.target.value)
  }
  const updateSearchText = debounce((text) => {
    dispatch(setSearchTextAction(text))
  }, 1000)

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault()
      updateSearchText(inputText)
      // event capture
      handleTrackCustomEvent(
        FIREFLY_EVENTS.EVENT_TYPE.FILTER_APPLIED,
        FIREFLY_EVENTS.CUSTOM_INTERACTION_KEYS.QUICK_SEARCH,
        inputText,
      )
      setInputText('')
    }
  }
  const handleInputClear = () => {
    setInputText('')
  }

  return {
    handleInputChange,
    handleKeyDown,
    searchText,
    inputText,
    handleInputClear,
  }
}
