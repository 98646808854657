import { BrowserRouter as Router } from 'react-router-dom'
import { Provider } from 'react-redux'
// eslint-disable-next-line
import Symbol_observable from 'symbol-observable'
import { ApolloProvider } from '@apollo/client'
import { HelveticaForTarget } from '@enterprise-ui/component-font'
import { AuthProvider } from '@praxis/component-auth'
import { AnalyticsProvider } from '@praxis/component-analytics'
// import { itemCommonUtilis } from 'item-component'
import { Main } from './views/Main'
import apiConfig from './globalConfig/apiConfig'
import { client } from './apiItemGraphql'
import store from './store' // Import Redux store
import '@enterprise-ui/canvas-ui-css'
import './globalStyles/customStyles.scss'

const App = () => {
  return (
    <>
      <HelveticaForTarget variants={['n4', 'n5', 'n7']} />
      <Router>
        <AuthProvider {...apiConfig.auth}>
          <ApolloProvider client={client}>
            <Provider store={store}>
              <AnalyticsProvider
                eventManagerConfiguration={apiConfig.analytics}
                trackLocations
              >
                <Main />
              </AnalyticsProvider>
            </Provider>
          </ApolloProvider>
        </AuthProvider>
      </Router>
    </>
  )
}

export default App
