import { Card, Button } from '@enterprise-ui/canvas-ui-react'
import EnterpriseIcon, { SupportIcon } from '@enterprise-ui/icons'
import apiConfig from '../../../globalConfig/apiConfig'

export const NeedHelp = () => {
  return (
    <Card className="hc-pa-md hc-mt-normal" style={{ minHeight: 215 }}>
      <h4 className="hc-fs-md hc-lh-expanded font-color-default font-weight-700 display-flex hc-mb-normal">
        <EnterpriseIcon icon={SupportIcon} className="hc-mr-dense" />
        <span>Need Help?</span>
      </h4>
      <div>
        <p className="hc-fs-sx font-weight-400 hc-mb-dense">
          If you have questions or find an issue with your data or the system,
          let us know!
        </p>
        <Button
          as="a"
          type="secondary"
          target="_blank"
          href={apiConfig.support.host}
          data-testid="submit-ticket"
        >
          Submit Ticket
        </Button>
      </div>
      {/*<div className="hc-mt-expanded">
        <p className="hc-fs-sx font-weight-400 hc-mb-dense">
          Also feel free to refer FAQs for some known concerns
        </p>
        <Button type="secondary" onClick={(e) => {}} data-testid="faqs">
          Refer FAQs
        </Button>
      </div>*/}
    </Card>
  )
}
