import React from 'react'
import { Grid } from '@enterprise-ui/canvas-ui-react'
import EnterpriseIcon, {
  InfoFilledIcon,
  BellIcon,
  CautionIcon,
  ErrorIcon,
} from '@enterprise-ui/icons'
import '../../globalStyles/BannerStyles.scss'

export const Banner = (props) => {
  const bannerData = props.bannerData
  let bannerType = 'warning'
  if (bannerData && bannerData?.type) {
    switch (bannerData?.type) {
      case 'red':
        bannerType = 'redAlert'
        break
      case 'yellow':
        bannerType = 'yellowAlert'
        break
      case 'green':
        bannerType = 'greenAlert'
        break
      default:
        bannerType = 'infoAlert'
        break
    }
  }
  return (
    <React.Fragment>
      {bannerData && bannerData.type && (
        <Grid.Container
          className={[
            `${bannerType}`,
            bannerData ? 'banner-header-position' : '',
          ].join(' ')}
          data-testid="banner-component"
        >
          <Grid.Item xs={12} sm={12} md={12} lg={12} className="titleContainer">
            <div style={{ marginRight: '1em' }}>
              {(() => {
                if (bannerData.type === 'red') {
                  return (
                    <EnterpriseIcon
                      icon={CautionIcon}
                      className="hc-mr-dense"
                      data-testid="banner-icon-red"
                    />
                  )
                } else if (bannerData.type === 'yellow') {
                  return (
                    <EnterpriseIcon
                      icon={ErrorIcon}
                      className="hc-mr-dense"
                      data-testid="banner-icon-yellow"
                    />
                  )
                } else if (bannerData.type === 'green') {
                  return (
                    <EnterpriseIcon
                      icon={BellIcon}
                      className="hc-mr-dense"
                      data-testid="banner-icon-green"
                    />
                  )
                } else {
                  return (
                    <EnterpriseIcon
                      icon={InfoFilledIcon}
                      className="hc-mr-dense"
                      data-testid="banner-icon-blue"
                    />
                  )
                }
              })()}
            </div>
            <div style={{ width: '100%' }}>
              <h2 data-testid="banner-title" className="bannerTitle">
                {bannerData?.heading}
              </h2>
              <p data-testid="banner-body" className="bannerdesc">
                <span>{bannerData?.body}</span>
                <a
                  // eslint-disable-next-line react/jsx-no-target-blank
                  target="_blank"
                  className="helplinkStyle"
                  href={bannerData?.link}
                  rel="noreferrer"
                >
                  {bannerData?.linkText}
                </a>
              </p>
            </div>
          </Grid.Item>
        </Grid.Container>
      )}
    </React.Fragment>
  )
}

export default Banner
