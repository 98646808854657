import MultipleFilterComponent from '../resuableFilters/mulitpleFilterComponenet'

export const MerchItemTypeFilter = (filter) => {
  const {
    id: filterId,
    onFilterChange,
    isFav,
    selectedValue,
    setSelectedValues,
  } = filter
  return (
    <MultipleFilterComponent
      filterId={filterId}
      isFav={isFav}
      onFilterChange={onFilterChange}
      attributeId="merchandiseType"
      attributeKey="merchTypeId"
      dependentAttributeId="itemType"
      dependentAttributeKey="itemTypeId"
      label="Merchandise Type"
      dependentLabel="Item Type"
      selectedValue={selectedValue}
      setSelectedValues={setSelectedValues}
    />
  )
}
