import { useState } from 'react'
import {
  Card,
  Grid,
  Button,
  Divider,
  Modal,
} from '@enterprise-ui/canvas-ui-react'
import EnterpriseIcon, { FixturesIcon } from '@enterprise-ui/icons'
import moment from 'moment'
import { useQuery } from '@apollo/client'
import { getReleaseNotesContentQuery } from '../../../apiItemGraphql/dashboardReleaseNotesContent'
import { WhatNewPlaceholder } from './WhatNewPlaceholder'

export const WhatNew = () => {
  const [isReadMoreModalOpen, setIsReadMoreModalOpen] = useState(false)
  const [isPreviousReleaseModalOpen, setIsPreviousReleaseModalOpen] =
    useState(false)
  const [releaseContent, setReleaseContent] = useState(null)
  const { loading, data } = useQuery(getReleaseNotesContentQuery())
  const readReleaseContent = (releaseContent) => {
    setReleaseContent(releaseContent)
    setIsReadMoreModalOpen(true)
  }
  const viewPreviousReleaseContent = () => {
    setIsPreviousReleaseModalOpen(true)
  }
  return (
    <div>
      <Card className="hc-pa-md" style={{ minHeight: 695 }}>
        <h4 className="hc-fs-md hc-lh-expanded font-color-default font-weight-700 display-flex hc-mb-normal">
          <EnterpriseIcon icon={FixturesIcon} className="hc-mr-dense" />
          <span> What’s New?</span>
        </h4>
        {loading && <WhatNewPlaceholder />}
        {!loading && (
          <Grid.Container>
            {data?.getReleaseNotesContent?.results
              ?.slice(0, 2)
              .map((releaseNote, index) => (
                <Grid.Item md={12} xs={12} key={index}>
                  <Card
                    className="hc-pa-md bg-default-contrast-weak font-color-default"
                    padding="dense"
                  >
                    <Grid.Container>
                      <Grid.Item md={12}>
                        <h6 className="hc-fs-md font-color-default font-weight-500">
                          {releaseNote?.title}
                        </h6>
                        <span className="hc-fs-xs product-relase-launched-date font-weight-500">
                          {`Released on ${moment(releaseNote?.releaseDate).format('MMM D, YYYY')}`}
                        </span>
                      </Grid.Item>
                    </Grid.Container>
                    <p
                      className="hc-fs-sm app-description font-color-default hc-mt-normal font-weight-400"
                      dangerouslySetInnerHTML={{
                        __html: releaseNote?.releaseContent.substring(0, 300),
                      }}
                    />
                    <Button
                      type="ghost"
                      className="hc-fs-sm hc-clr-interactive hc-pl-none font-weight-500"
                      onClick={(e) => readReleaseContent(releaseNote)}
                    >
                      Read More
                    </Button>
                  </Card>
                </Grid.Item>
              ))}
            <Grid.Item md={12} xs={12}>
              <Button
                type="ghost"
                className="hc-fs-sm hc-clr-interactive hc-pl-none font-weight-500"
                onClick={(e) => viewPreviousReleaseContent()}
              >
                View Previous Releases
              </Button>
            </Grid.Item>
          </Grid.Container>
        )}
      </Card>
      {/* View Release Notes when click on Read More button*/}
      <Modal
        headingText={releaseContent?.title}
        isVisible={isReadMoreModalOpen}
        onRefuse={() => setIsReadMoreModalOpen(false)}
        scrollBehavior="contained"
        footer
        size="normal"
      >
        <div style={{ padding: '0 24px' }}>
          <span className="hc-fs-md product-relase-launched-date font-weight-400 hc-clr-interactive">
            {`Released on ${moment(releaseContent?.releaseDate).format('MMM D, YYYY')}`}
          </span>
          <p
            className="hc-fs-md hc-mt-normal app-description font-color-default font-weight-400"
            dangerouslySetInnerHTML={{
              __html: releaseContent?.releaseContent,
            }}
          />
        </div>
      </Modal>

      {/* View All Previous Release Notes */}
      <Modal
        headingText="LaunchPad Releases"
        isVisible={isPreviousReleaseModalOpen}
        onRefuse={() => setIsPreviousReleaseModalOpen(false)}
        scrollBehavior="contained"
        footer
        size="expanded"
      >
        <div style={{ padding: '0 24px' }}>
          {data?.getReleaseNotesContent?.results?.map((releaseNote, index) => (
            <Grid.Item md={12} xs={12} key={index}>
              <Card
                className="hc-pa-md hc-mt-md bg-default-contrast-weak font-color-default"
                padding="dense"
              >
                <Grid.Container>
                  <Grid.Item md={12}>
                    <Grid.Container directon="column" justify="space-between">
                      <Grid.Item>
                        <h6 className="hc-fs-md font-color-default font-weight-500">
                          {releaseNote?.title}
                        </h6>
                      </Grid.Item>
                      <Grid.Item>
                        <span className="hc-fs-md product-relase-launched-date font-color-secondary font-weight-500">
                          {`${moment(releaseNote?.releaseDate).format('MMM D, YYYY')}`}
                        </span>
                      </Grid.Item>
                    </Grid.Container>
                    <Divider className="hc-ma-dense" />
                  </Grid.Item>
                </Grid.Container>
                <p
                  className="hc-fs-sm app-description font-color-default hc-mt-normal font-weight-400"
                  dangerouslySetInnerHTML={{
                    __html: releaseNote?.releaseContent,
                  }}
                />
              </Card>
            </Grid.Item>
          ))}
        </div>
      </Modal>
    </div>
  )
}
