import { Card } from '@enterprise-ui/canvas-ui-react'
import { RenderFilterComponent } from './FilterComponetRender'

export const FilterList = ({
  filters,
  handleFilterChange,
  handleFavoriteChange,
  favoriteFilters,
  className,
  isFavoriteSection,

  selectedValues,
  setSelectedValues,
}) => {
  let sortedNames
  if (filters) {
    sortedNames = filters?.sort((a, b) => {
      return a.displayName.localeCompare(b.displayName)
    })
  }

  return (
    <Card corners="none" elevation="0" className={className}>
      {sortedNames &&
        sortedNames.map((filter, index) => (
          <RenderFilterComponent
            filter={filter}
            key={index}
            setSelectedValues={setSelectedValues}
            selectedValue={selectedValues ? selectedValues[filter?.id] : null}
            handleFilterChange={handleFilterChange}
            handleFavoriteChange={handleFavoriteChange}
            favoriteFilters={favoriteFilters}
            isFavoriteSection={isFavoriteSection}
          />
        ))}
    </Card>
  )
}
