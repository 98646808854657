import { useEffect } from 'react'
import { Button, Grid, Modal, Form } from '@enterprise-ui/canvas-ui-react'
import { useSavedSearchModal } from './useSavedSearchModal'
import { FILTER_MODAL } from '../../../../../constants/search'
export const SavedSearchModal = (props) => {
  const {
    showEditModal,
    handelModal,
    index,
    editSavedSearch,
    handleButtonClick,
    modalName,
  } = props

  const { inputValue, setInputValue, isChecked, setIsChecked } =
    useSavedSearchModal()

  useEffect(() => {
    setInputValue(editSavedSearch?.name)
    setIsChecked(editSavedSearch?.showInDashboard)
  }, [editSavedSearch, setInputValue, setIsChecked])

  let heading = ''
  let label = ''
  let button = 'Save'

  switch (modalName) {
    case 'edit':
      heading = FILTER_MODAL.MODAL_HEADING_EDIT
      label = FILTER_MODAL.SAVE_EDIT_LABEL
      break
    case 'delete':
      heading = FILTER_MODAL.MODAL_HEADING_DELETE
      label = FILTER_MODAL.DELETE_LABEL
      button = 'Delete'
      break
    default:
      heading = FILTER_MODAL.MODAL_HEADING_SAVE
      label = FILTER_MODAL.SAVE_EDIT_LABEL
      break
  }

  return (
    <Modal
      isVisible={showEditModal}
      headingText={heading}
      onRefuse={() => {
        handelModal(index)
      }}
      size="dense"
    >
      <Grid.Container className="hc-pa-normal">
        <Grid.Item xs={12}>
          <Form.Field
            id="saved_search"
            label={label}
            errorText="Please provide the name"
            disabled={modalName === 'delete'}
            onChange={(e) => {
              setInputValue(e.target.value)
            }}
            value={inputValue}
          />
          {modalName !== 'delete' && (
            <Form.Field
              type="checkbox"
              label={FILTER_MODAL.MODAL_SHOW_ON_HOME_PAGE}
              className="input-checkbox"
              checked={isChecked}
              onClick={(event) => {
                setIsChecked(event.target.checked)
              }}
            />
          )}
        </Grid.Item>
      </Grid.Container>
      <Grid.Container
        type="primary"
        direction="row-reverse"
        spacing="dense"
        className="hc-pa-normal"
      >
        <Grid.Item>
          {modalName === 'delete' && (
            <Button
              onClick={(e) => {
                handelModal(index)
              }}
              type="secondary"
              className="hc-mr-dense"
            >
              Cancel
            </Button>
          )}
          <Button
            onClick={(e) => {
              handelModal(index)
              handleButtonClick(
                {
                  ...editSavedSearch,
                  name: inputValue,
                  showInDashboard: isChecked,
                },
                modalName,
              )
            }}
            type="primary"
            disabled={!inputValue || inputValue?.length < 2}
          >
            {button}
          </Button>
        </Grid.Item>
      </Grid.Container>
    </Modal>
  )
}
