import { useState, useEffect } from 'react'
import { Form } from '@enterprise-ui/canvas-ui-react'
import moment from 'moment'
import '@enterprise-ui/canvas-ui-css'
import { SEARCH_TEXT } from '../../../../searchConstants'

export const DateFilters = (filter) => {
  const [startDate, setStartDate] = useState(null)
  const [endDate, setEndDate] = useState(null)
  const [startDateErrorMessage, setStartDateErrorMessage] = useState(false)
  const [endDateErrorMessage, setEndDateErrorMessage] = useState(false)

  const [error, setError] = useState(false)
  const [startDateError, setStartDateError] = useState(false)
  const {
    id: filterId,
    name,
    onFilterChange,
    selectedValue,
    setSelectedValues,
  } = filter

  useEffect(() => {
    // Initialize startDate and endDate from selectedValue
    if (selectedValue) {
      if (selectedValue.startDate) {
        setStartDate(selectedValue.startDate)
      }
      if (selectedValue.endDate) {
        setEndDate(selectedValue.endDate)
      }
      validateAndUpdate(
        selectedValue.startDate ? selectedValue.startDate : null,
        selectedValue.endDate ? selectedValue.endDate : null,
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedValue])

  const validateAndUpdate = (start, end) => {
    const startDateIsValid = start
      ? moment(start, 'YYYY-MM-DD', true).isValid()
      : true
    const endDateIsValid = end
      ? moment(end, 'YYYY-MM-DD', true).isValid()
      : true
    const endDateWithoutStartDate = !start && end
    const isEndDateBeforeStartDate =
      start && end ? moment(end).isBefore(moment(start)) : false
    const endDateAllValidation =
      endDateIsValid && !isEndDateBeforeStartDate && !endDateWithoutStartDate

    // Validate start date
    if (!startDateIsValid) {
      setStartDateError(true)
      setStartDateErrorMessage(
        SEARCH_TEXT.ERROR_TEXT.DATE_ERROR.START_DATE_ERROR,
      )
    } else if (endDateWithoutStartDate) {
      setStartDateError(true)
      setStartDateErrorMessage(
        SEARCH_TEXT.ERROR_TEXT.DATE_ERROR.END_DATE_WITHOUT_START_DATE,
      )
    } else {
      //clear start date errors
      setStartDateError(false)
      setStartDateErrorMessage('')
    }

    // Validate end date
    if (!endDateIsValid) {
      setError(true)
      setEndDateErrorMessage(SEARCH_TEXT.ERROR_TEXT.DATE_ERROR.END_DATE_ERROR1)
    }
    // Check if end date is before start date
    else if (isEndDateBeforeStartDate) {
      setError(true)
      setEndDateErrorMessage(SEARCH_TEXT.ERROR_TEXT.DATE_ERROR.END_DATE_ERROR2)
    } else if (endDateIsValid && !isEndDateBeforeStartDate) {
      //clear end date errors
      setError(false)
      setEndDateErrorMessage('')
    }
    const validatedEndDate = end || start

    if (endDateAllValidation && startDateIsValid && start && validatedEndDate) {
      const newValue = [
        {
          value: {
            startDate: start,
            endDate: validatedEndDate,
          },
          displayValue: `${moment(start).format('MMM D, YYYY')}${end ? ` - ${moment(end).format('MMM D, YYYY')}` : ''}`,
          pending: false,
        },
      ]

      onFilterChange(filterId, newValue)
    } else {
      // Do not apply the filter if there is error is any of the dates
      onFilterChange(filterId, null)
    }
  }

  const handleStartDateChange = (e) => {
    const newDate = e.target.value
    setStartDate(newDate)
    setSelectedValues((prev) => ({
      ...prev,
      [filterId]: {
        ...(prev?.[filterId] || {}),
        startDate: newDate,
      },
    }))
    validateAndUpdate(newDate, endDate)
  }

  const handleEndDateChange = (e) => {
    const newDate = e.target.value
    setEndDate(newDate)
    setSelectedValues((prev) => ({
      ...prev,
      [filterId]: {
        ...(prev?.[filterId] || {}),

        endDate: newDate,
      },
    }))
    validateAndUpdate(startDate, newDate)
  }

  return (
    <div className="date-filter">
      <Form.Field
        id={`${filterId}-filter-start-date`}
        label={name}
        type="date"
        data-testid="start-date-filter"
        className="hc-clr-grey02"
        placeholder={name}
        value={startDate || ''}
        onChange={handleStartDateChange}
        hintText="Start Date"
        error={startDateError}
        errorText={startDateErrorMessage ? startDateErrorMessage : ''}
      />
      <div style={{ padding: '5px' }}></div>
      <Form.Field
        id={`${filterId}-filter-end-date`}
        type="date"
        className="hc-clr-grey02"
        data-testid="end-date-filter"
        placeholder={name}
        value={endDate || ''}
        disabled={!startDate && !endDate}
        minDate={startDate}
        error={error}
        onChange={handleEndDateChange}
        hintText="End Date (optional)"
        errorText={endDateErrorMessage ? endDateErrorMessage : ''}
      />
      {/* {errorMessage && (
        <HelperText helperText={errorMessage} error={!!errorMessage} />
      )} */}
    </div>
  )
}

export default DateFilters
