export const userRole = {
  ADMIN: 'ADMIN',
  VENDOR: 'VENDOR',
  VIEWER: 'VIEWER',
  VALIDATOR: 'VALIDATOR',
  MAINTAINER: 'MAINTAINER',
  SUPPORT: 'SUPPORT',
  MAP: 'MAP',
  MASSMAINTAINER: 'MASSMAINTAINER',
  ITEMATTRIBUTEUPDATEREQUESTOR: 'ITEMATTRIBUTEUPDATEREQUESTOR',
  IAUVIEWER: 'IAUVIEWER',
}
const userConstants = {
  USER_ROLES: userRole,
  USER_ROLE_GROUPINGS: {
    ALL_ROLES: [
      userRole.VIEWER,
      userRole.VALIDATOR,
      userRole.MAINTAINER,
      userRole.SUPPORT,
      userRole.MAP,
      userRole.ADMIN,
      userRole.VENDOR,
      userRole.ITEMATTRIBUTEUPDATEREQUESTOR,
      userRole.IAUVIEWER,
    ],
    INTERNAL_NON_ADMIN: [
      userRole.VIEWER,
      userRole.VALIDATOR,
      userRole.MAINTAINER,
      userRole.SUPPORT,
      userRole.MAP,
    ],
    ITEM_MAINTAINERS: [
      userRole.VALIDATOR,
      userRole.MAINTAINER,
      userRole.SUPPORT,
      userRole.MAP,
      userRole.ADMIN,
      userRole.VENDOR,
    ],
  },
}
export default userConstants
