import constants, { userRole } from '../../constants/constants'
import apiConfig from '../../globalConfig/apiConfig'

export const QUICK_NAVIGATION = [
  {
    title: 'Task Board',
    description:
      'Create, maintain or view variations, collections, bundles, and add-ons.',
    icon: 'WorkIcon',
    path: `${apiConfig.launchpad.host}${apiConfig.launchpad.page.dashboard}`,
    enabled: true,
  },
  {
    title: 'Search',
    description: 'Find item(s) and view item data.  ',
    icon: 'SearchIcon',
    path: '/search',
    enabled: [
      ...constants.USER_ROLE_GROUPINGS.ITEM_MAINTAINERS,
      userRole.VIEWER,
      userRole.IAUVIEWER,
    ],
  },
  {
    title: 'Create Item',
    description: 'Manually create new item(s) in LaunchPad/IMN',
    icon: 'PlusIcon',
    path: `${apiConfig.launchpad.host}${apiConfig.launchpad.page.create}`,
    enabled: [...constants.USER_ROLE_GROUPINGS.ITEM_MAINTAINERS],
  },
  {
    title: 'Bulk Export',
    description:
      'Download LaunchPad item data to Excel, on all items within a single Item Type.',
    icon: 'ArrowsVerticalIcon',
    path: `${apiConfig.launchpad.host}${apiConfig.launchpad.page.bulk}`,
    enabled: true,
  },
  // {
  //   title: 'Mass Maintain',
  //   description:
  //     'Enter, update, or view item data on new and/or existing item(s).',
  //   icon: 'PencilIcon',
  //   path: `${apiConfig.massMaintain.host}`,
  //   enabled: [userRole.ADMIN, userRole.SUPPORT, userRole.MASSMAINTAINER],
  // },
  {
    title: 'Item Update Request',
    description:
      'Submit an item maintenance request to update item data in LaunchPad or IMN.',
    icon: 'ClipboardIcon',
    path: `${apiConfig.launchpad.host}${apiConfig.launchpad.page.iau}`,
    enabled: [
      userRole.ADMIN,
      userRole.SUPPORT,
      userRole.MAINTAINER,
      userRole.IAUVIEWER,
      userRole.ITEMATTRIBUTEUPDATEREQUESTOR,
    ],
  },
]

export const tabMapping = {
  'Not Ready for Order': 'order_blockers',
  'Not Ready for Launch': 'launch_blockers',
  'Ready for Launch': 'launch_blockers',
}
