import { Card } from '@enterprise-ui/canvas-ui-react'
import { useSelector } from 'react-redux'
import {
  getUserInfo,
  userIsInSomeRole,
} from '../../../globalUtils/commonUtills'
import NavigateToMaintainScreen from './NavigateToMaintain'
import RedirectToPipeline from './RedirectToPipeline'
import UpdateTaskStatus from './UpdateTaskStatus'
import AddComments from './AddComments'
import DownloadDQIssues from './DownloadDQIssues'
import CopyTcins from './CopyTcins'
import ValidateItemData from './ValidateItemData'
import DeleteItems from './DeleteItems'
import MMBActions from './MMBAction'
// import CrossOver from './CrossOver'
// import Unpublished from './Unpublished'
import ClearItems from './ClearItems'
import constants from '../../../constants/constants'
import {
  filterTCINs,
  filterWithMultipleMatches,
} from '../../../globalUtils/searchUtills'

export const Actionbar = (props) => {
  const { gridApi, selectedItems } = props
  const selectedTcins = selectedItems?.map((item) => item.tcin, [])

  const getUserProfile = useSelector((state) => {
    return getUserInfo(state)
  })
  const canUserDelete = userIsInSomeRole(getUserProfile?.roles, [
    constants.USER_ROLES.ADMIN,
    constants.USER_ROLES.SUPPORT,
    constants.USER_ROLES.MAINTAINER,
  ])

  // Is Item Validator Role
  const isItemValidator = userIsInSomeRole(getUserProfile?.roles, [
    constants.USER_ROLES.VALIDATOR,
  ])

  // Is Vendor Role
  // const isVendor = userIsInSomeRole(getUserProfile?.roles, [
  //   constants.USER_ROLES.VENDOR,
  // ])

  // IsMMBItems
  const isMMB = filterTCINs(
    filterWithMultipleMatches(selectedItems, { isMMBItem: true }),
  )

  return (
    <>
      {selectedItems?.length > 0 && (
        <Card className="bg-default-interactive hc-mt-md hc-pa-normal footer-actions-section">
          <div className="action-buttons action-buttons-left-section">
            <span className="hc-clr-contrast-weak">
              {selectedTcins?.length} Items Selected
            </span>
            {!isItemValidator && (
              <NavigateToMaintainScreen selectedItems={selectedItems} />
            )}
            {!isItemValidator && (
              <RedirectToPipeline selectedTcins={selectedTcins} />
            )}
            {!isItemValidator && (
              <UpdateTaskStatus selectedTcins={selectedTcins} />
            )}
            {!isItemValidator && <AddComments selectedTcins={selectedTcins} />}
            {!isItemValidator && (
              <DownloadDQIssues selectedTcins={selectedTcins} />
            )}
            <CopyTcins selectedTcins={selectedTcins} />
            {isItemValidator && (
              <ValidateItemData selectedItems={selectedItems} />
            )}
            {canUserDelete && <DeleteItems selectedItems={selectedItems} />}
            {isMMB.length !== 0 && (
              <>
                <span className="hc-clr-contrast-weak">|</span>
                <MMBActions
                  selectedTcins={selectedTcins}
                  selectedItems={props?.selectedItems}
                />
              </>
            )}
          </div>
          <div className="action-buttons action-buttons-right-section">
            <ClearItems gridApi={gridApi} />
          </div>
        </Card>
      )}
    </>
  )
}
