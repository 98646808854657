import '@enterprise-ui/canvas-ui-css'
import '@enterprise-ui/canvas-ui-css-autocomplete'
import { Grid } from '@enterprise-ui/canvas-ui-react'
import { Autocomplete } from '@enterprise-ui/canvas-ui-react-autocomplete'
import { useFetchLov } from '../../../../../hooks/useLov'
import { isEmpty } from 'lodash'
import { SEARCH_TEXT } from '../../../../searchConstants'
import { useEffect } from 'react'
import useFetchOptions from '../../../../../hooks/useFetchOptions'

export const CheckboxFilters = (filter) => {
  const { id, name, onFilterChange, isFav, selectedValue, setSelectedValues } =
    filter
  const fetchLovId = id === 'itemLocationStatus' ? 'locationStatusCodes' : id

  const { refetch } = useFetchLov({
    variables: { attributeId: fetchLovId },
    skip: true,
  })

  const { fetchedOptions, loading, setFetch } = useFetchOptions(
    refetch,
    fetchLovId,
  )

  const handleUpdate = (id, value, filterId) => {
    setSelectedValues((prev) => ({
      ...prev,
      [id]: value,
    }))

    // [{"value":"DMST","id":"DMST","label":"DA Master"},{"value":"DCMP","id":"DCMP","label":"DA Component"},{"value":"GPRT","id":"GPRT","label":"GPR Component"}]
    const valueList = !isEmpty(value)
      ? value.map((valueObj) => ({
          value: valueObj?.value,
          displayValue: valueObj?.label,
          pending: true,
        }))
      : null
    onFilterChange(filterId, valueList)
  }
  useEffect(() => {
    if (!selectedValue) {
      setSelectedValues((prev) => ({
        ...prev,
        [id]: [],
      }))
    }
  })

  return (
    <Grid.Item onClick={() => setFetch(true)}>
      <Autocomplete
        id={id}
        key={`${id}${isFav}-filter`}
        loadOnce
        label={name}
        data-testid="checkbox-filter"
        selectFirstOnEnter
        noResultsMessage={
          loading ? SEARCH_TEXT.LOV.LOADING : SEARCH_TEXT.LOV.NO_RESULTS
        }
        options={fetchedOptions}
        value={selectedValue}
        placeholder={name}
        multiselect
        onUpdate={(id, value) => {
          handleUpdate(id, value, id)
        }}
      />
    </Grid.Item>
  )
}
