import '@enterprise-ui/canvas-ui-css'
import { useState } from 'react'
import { Form } from '@enterprise-ui/canvas-ui-react'
import { getNewStyleNumbersFromString } from './helper'
import '../../../../../../globalStyles/search.scss'
import { SEARCH_TEXT } from '../../../../searchConstants'
import { useEffect } from 'react'

export const ManufacturerStyleNumberFilter = (filter) => {
  const {
    id: filterId,
    name,
    onFilterChange,
    selectedValue,
    setSelectedValues,
  } = filter

  const [errorState, setErrorState] = useState(false)
  const [resetString, setResetString] = useState('')

  const validate = (value) => {
    const styleNumberResult = getNewStyleNumbersFromString(value)
    const validStyleNumbers = styleNumberResult.styleNumbers.map(
      (styleNumber) => ({
        value: styleNumber.value,
        displayValue: styleNumber.displayValue,
      }),
    )
    const resetStringLocal = styleNumberResult.styleNumbers.filter(
      (sn) => !sn.value,
    )

    if (resetStringLocal.length) {
      setErrorState(true)
    } else {
      setErrorState(false)
    }
    if (validStyleNumbers.length > 0) {
      onFilterChange(filterId, validStyleNumbers)
    } else {
      onFilterChange(filterId, null)
    }
    setResetString(resetStringLocal.map((sn) => sn.value).join(', '))
  }

  const handleUpdate = (e) => {
    const newValue = e.target.value
    setSelectedValues((prev) => ({ ...prev, [filterId]: newValue }))
    validate(newValue)
  }

  const handleBlur = () => {
    if (errorState) {
      setSelectedValues((prev) => ({ ...prev, [filterId]: resetString }))
      setErrorState(false)
    }
  }

  useEffect(() => {
    // Validate the initial selectedValue on component mount
    if (selectedValue) {
      validate(selectedValue)
    } else {
      setSelectedValues((prev) => ({ ...prev, [filterId]: '' }))
      setErrorState(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedValue])

  return (
    <Form.Field
      id={`${filterId}-filter`}
      label={name}
      //className={errorState ? 'style_number_filter' : ''}
      placeholder={name}
      type="text"
      error={errorState}
      errorText={SEARCH_TEXT.ERROR_TEXT.STYLE_NUMBER_ERROR}
      value={selectedValue}
      onChange={(e) => handleUpdate(e)}
      onBlur={handleBlur}
    />
  )
}
