import { Table } from '@enterprise-ui/canvas-ui-react'
import moment from 'moment'
import { commentsTableColumns } from './commentConstants'
import CommentActions from './CommentActions'

const CommentsTable = ({ comments, refetchComments, userId }) => {
  const rows = comments.map((comment) => {
    const dateTime = moment(parseFloat(comment.timestamp))
    const isActionsAvailable = userId === comment.userID
    return {
      dateTime: `${dateTime.format('MMM D, YYYY')} ${dateTime.format('h:mm a')}`,
      user: comment.userName,
      comment: {
        cellDisplay: isActionsAvailable ? (
          <CommentActions
            commentId={comment._id}
            comment={comment.comment}
            refetchComments={refetchComments}
          />
        ) : (
          <div>{comment.comment}</div>
        ),
      },
      id: comment._id,
    }
  })

  return (
    <Table
      data={{
        columnDefs: commentsTableColumns,
        rowData: rows,
      }}
      name="Comments Table"
      showGrid={false}
      cellPadding="dense"
      data-testid="comments-table"
      roundCorner
      className="comments-table"
    />
  )
}

export default CommentsTable
