import { useDispatch, useSelector } from 'react-redux'
import { setActiveTab } from '../../../store/searchHeader'

export const useSearchHeader = () => {
  const dispatch = useDispatch()
  const activeTab = useSelector((state) => state.searchHeader.activeTab)

  const onTabSelect = (event, tab) => {
    dispatch(setActiveTab(tab.name))
  }

  return {
    activeTab,
    onTabSelect,
  }
}
