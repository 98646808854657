import React, { useState } from 'react'
import { useQuery } from '@apollo/client'
import { Button, useToaster, Tooltip } from '@enterprise-ui/canvas-ui-react'
import { downloadExcelFileFromBinary } from '../../../globalUtils/commonUtills'
import { DOWNLOAD_DQ_ISSUES } from '../../../apiItemGraphql/searchActionBar'
import { useFirefly } from '../../../globalUtils/useAnalytics'
import { FIREFLY_EVENTS } from '../../../constants/search'

const DownloadDQIssues = ({ selectedTcins }) => {
  const { handleTrackCustomEvent } = useFirefly()
  const toaster = useToaster()
  const [downloadParams, setDownloadParams] = useState({
    attributeId: null,
    tcins: [],
    includeExcelDownload: false,
  })

  const { loading, error, data } = useQuery(DOWNLOAD_DQ_ISSUES, {
    variables: downloadParams,
    skip: !downloadParams.attributeId,
  })

  const downloadDQIssuesExcelResults = () => {
    handleTrackCustomEvent(
      FIREFLY_EVENTS.EVENT_TYPE.GRID_ACTION,
      FIREFLY_EVENTS.CUSTOM_INTERACTION_KEYS.EXCEL,
      FIREFLY_EVENTS.CUSTOM_INTERACTION_VALUES.DQ_ISSUE,
    )
    setDownloadParams({
      attributeId: 'itemDataQualityIssues',
      tcins: selectedTcins,
      includeExcelDownload: true,
    })
  }

  // Handle download result and errors
  React.useEffect(() => {
    if (data) {
      downloadExcelFileFromBinary(
        'Launchpad Data Quality Issues',
        data?.downloadDQIssuesLookup?.excelFile,
      )
    }
    if (error) {
      toaster({
        type: 'error',
        heading: 'Download Error',
        message: 'There was an error downloading your Excel. Please try again.',
      })
    }
  }, [data, error, toaster])

  return (
    <Tooltip content="Download DQ Issues" location="top">
      <Button
        type="ghost"
        onClick={downloadDQIssuesExcelResults}
        disabled={selectedTcins.length === 0}
        className="button-bg-hover-transparent"
        isLoading={loading}
      >
        <span className="hc-clr-contrast-weak">DQ</span>
      </Button>
    </Tooltip>
  )
}

export default DownloadDQIssues
