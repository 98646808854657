import '@enterprise-ui/canvas-ui-css'
import '../../../../../../globalStyles/search.scss'
import SingleFilterComponent from '../resuableFilters/singleFilterComponent'

export const DateFilterRelative = (filter) => {
  const { id, name, onFilterChange, isFav, selectedValue, setSelectedValues } =
    filter

  return (
    <SingleFilterComponent
      filterId={id}
      attributeId={id}
      isFav={isFav}
      label={name}
      selectedValue={selectedValue}
      setSelectedValues={setSelectedValues}
      onFilterChange={onFilterChange}
    />
  )
}
