import { Button, Layout } from '@enterprise-ui/canvas-ui-react'
import EnterpriseIcon, {
  ChevronLeftDoubleIcon,
  ChevronRightDoubleIcon,
} from '@enterprise-ui/icons'
import 'ag-grid-community/styles/ag-grid.css' // Core CSS
import 'ag-grid-community/styles/ag-theme-quartz.css'
import { SearchRail } from './leftRail'
import { SearchGrid } from './grid'
import { useSearchPage } from './useSearchPage'
import { Helmet } from 'react-helmet'
export const SearchHome = () => {
  const { isCollapsed, setIsCollapsed } = useSearchPage()

  return (
    <Layout.Body
      data-testid="lp-search"
      includeRail
      className="hc-pr-none hc-pl-none hc-pb-none layout-body-section"
    >
      <Helmet defaultTitle="LaunchPad: Search" />
      <div className={`search-page-layout ${isCollapsed ? 'collapsed' : ''}`}>
        <div className="top-left search-rail">
          <SearchRail
            isCollapsed={isCollapsed}
            setIsCollapsed={setIsCollapsed}
          />
        </div>
        <div
          className={`bottom-left expand-collapse-btn ${isCollapsed ? 'collapsed' : 'expanded'}`}
        >
          <Button
            iconOnly
            type="ghost"
            aria-label="Menu Icon"
            onClick={() => setIsCollapsed(!isCollapsed)}
            className=""
          >
            <EnterpriseIcon
              icon={
                !isCollapsed ? ChevronLeftDoubleIcon : ChevronRightDoubleIcon
              }
            />
          </Button>
        </div>
        <SearchGrid />
      </div>
    </Layout.Body>
  )
}
