import React, { useState } from 'react'
import { useQuery } from '@apollo/client'
import EnterpriseIcon, { DownloadIcon } from '@enterprise-ui/icons'
import { Button, useToaster } from '@enterprise-ui/canvas-ui-react'
import { SEARCH_INFO_TABLE } from '../../../../apiItemGraphql/searchInfoTable'
import { downloadExcelFileFromBinary } from '../../../../globalUtils/commonUtills'
import DOWNLOAD_INFO_TABLE_ERROR from '../../../../../src/constants/message-constants'

const DownloadInfoTable = (props) => {
  const toaster = useToaster()
  const { title, tcins, attributeId } = props
  const [downloadParams, setDownloadParams] = useState({
    attributeId: null,
    tcins: tcins,
    includeExcelDownload: false,
  })

  const {
    data: infoTableData,
    error,
    refetch,
  } = useQuery(SEARCH_INFO_TABLE, {
    variables: downloadParams,
    skip: !downloadParams.attributeId,
  })

  const downloadInfoTableViaExcel = async () => {
    if (!downloadParams.attributeId) {
      setDownloadParams({
        attributeId: attributeId,
        tcins: tcins,
        includeExcelDownload: true,
      })
    } else {
      await refetch()
    }
  }

  React.useEffect(() => {
    if (infoTableData?.searchInfoTableLookup?.excelFile) {
      downloadExcelFileFromBinary(
        title,
        infoTableData.searchInfoTableLookup.excelFile,
      )
    }
    if (error) {
      toaster({
        type: 'error',
        heading: 'Download Error',
        message: DOWNLOAD_INFO_TABLE_ERROR,
      })
    }
  }, [infoTableData, error, title, toaster])

  return (
    <Button
      onClick={downloadInfoTableViaExcel}
      className="download-infotable-btn hc-mr-sm"
      data-testid="download-infotable-button"
      iconOnly={true}
      aria-label="Download via excel"
    >
      <EnterpriseIcon icon={DownloadIcon} />
    </Button>
  )
}

export default DownloadInfoTable
