import SingleFilterComponent from '../resuableFilters/singleFilterComponent'

export const ProductBrandFilter = (filter) => {
  const { id, name, onFilterChange, isFav, selectedValue, setSelectedValues } =
    filter
  return (
    <SingleFilterComponent
      filterId={id}
      isFav={isFav}
      selectedValue={selectedValue}
      attributeId="businessPartnerBrands"
      label={name}
      setSelectedValues={setSelectedValues}
      placeholder="Enter Brand Name"
      onFilterChange={onFilterChange}
    />
  )
}
