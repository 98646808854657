import { createAction } from '@reduxjs/toolkit'

// Action types
export const maintenanceAction = 'maintenance'

// Action creators
export const maintenance = createAction(
  maintenanceAction,
  (maintenanceMode) => ({
    maintenanceMode,
  }),
)
