import { Card, Layout, Heading, Spinner } from '@enterprise-ui/canvas-ui-react'
import { useLocation } from 'react-router-dom'
import { useAuth } from '@praxis/component-auth'
import icon from '../../globalImagse/favicon.svg?url'
import '../../globalStyles/login.scss'
import { localStorageData } from '../../globalUtils/commonUtills'

export const LogIn = () => {
  const auth = useAuth()
  const location = useLocation()

  const { isAuthenticated, login } = auth
  if (!isAuthenticated()) {
    localStorageData.set('pathname', location.pathname)
    login()
  }

  return (
    <Layout data-testid="loginLayout" fullWidth>
      <Layout.Body includeRail>
        <Card
          className="hc-pa-normal hc-ta-center center"
          style={{ minHeight: '90vh' }}
        >
          <div className="progress-wrapper">
            <Spinner size="expanded" className="lp-spinner" />
            <img src={icon} alt="loading..." />
          </div>
          <Heading className="hc-pl-normal hc-pb-normal">
            Beaming item data from orbit...
          </Heading>
        </Card>
      </Layout.Body>
    </Layout>
  )
}
