import { Card, Divider, Grid, Spinner } from '@enterprise-ui/canvas-ui-react'
import EnterpriseIcon, { CautionIcon } from '@enterprise-ui/icons'
import { useSavedFilters } from './useSavedFilters'
import { SavedSearchComponent } from './SavedSearchComponent'
import { formatToTwoDigits } from '../../../../../globalUtils/commonUtills'

export const SavedFilters = () => {
  const {
    loading,
    savedSearchData,
    activeSavedSearchId,
    handleEdit,
    handleDelete,
    handleButtonClick,
  } = useSavedFilters()

  const nonFavSavedSearches =
    savedSearchData !== undefined &&
    savedSearchData?.filter((savedSearch) => savedSearch.favorite !== true)

  const allFavSavedSearches =
    savedSearchData !== undefined &&
    savedSearchData?.filter((savedSearch) => savedSearch.favorite === true)
  return (
    <Card
      corners="none"
      elevation="0"
      className="card-saved-search show-scroll"
    >
      <Grid.Container className="hc-ma-none" style={{ width: '100%' }}>
        {loading && (
          <Grid.Item xs={12} className="hc-pa-none">
            <Spinner size="dense" className="hc-ma-dense" />
          </Grid.Item>
        )}
        {savedSearchData.length === 0 && (
          <Grid.Item xs={12}>
            <div className="hc-mt-normal hc-clr-error hc-mr-md">
              <EnterpriseIcon icon={CautionIcon} className="hc-mr-dense" />
              <span className="hc-mt-dense">Saved Search not found</span>
            </div>
          </Grid.Item>
        )}

        {!loading && allFavSavedSearches && (
          <Grid.Item xs={12} className="hc-pa-none">
            {allFavSavedSearches?.length > 0 && (
              <>
                <p className="hc-mt-dense hc-mb-min hc-clr-grey01">
                  My Favorite Search (
                  {formatToTwoDigits(allFavSavedSearches.length)})
                </p>
                <SavedSearchComponent
                  data={allFavSavedSearches}
                  handleEdit={handleEdit}
                  handleDelete={handleDelete}
                  activeSavedSearchId={activeSavedSearchId}
                  handleButtonClick={handleButtonClick}
                  type="favs"
                />
                <Divider />
              </>
            )}
            {nonFavSavedSearches?.length > 0 && (
              <>
                <p className="hc-mt-dense hc-mb-min hc-clr-grey01">
                  All Saved Search (
                  {formatToTwoDigits(nonFavSavedSearches.length)})
                </p>
                <SavedSearchComponent
                  data={nonFavSavedSearches}
                  handleEdit={handleEdit}
                  handleDelete={handleDelete}
                  activeSavedSearchId={activeSavedSearchId}
                  handleButtonClick={handleButtonClick}
                  type="all"
                />
              </>
            )}
          </Grid.Item>
        )}
      </Grid.Container>
    </Card>
  )
}
