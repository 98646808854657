import {
  Grid,
  Card,
  Divider,
  Anchor,
  Tooltip,
  Button,
  Chip,
} from '@enterprise-ui/canvas-ui-react'
import { useDispatch } from 'react-redux'
import EnterpriseIcon, { PlusCircleIcon } from '@enterprise-ui/icons'

import { useAppliedFilters } from './useAppliedFilters'
import { AppliedFiltersChips } from './AppliedFiltersChips'
import { SavedSearchModal } from './SavedSearchModal'
import { useSavedSearchModal } from './useSavedSearchModal'
import { useSavedFilters } from '../filterSection/savedFilters/useSavedFilters'
import { writeFiltersToLocalStorage } from '../../../../globalUtils/searchUtills'
import { setHeaderCheckboxState } from '../../../../store/searchColumns'

export const AppliedFilters = (props) => {
  const dispatch = useDispatch()
  const {
    appliedFilters,
    searchText,
    stickerType,
    deleteAllFilters,
    handleDeleteQuickSearch,
    handleDeleteSticker,
  } = useAppliedFilters()
  const { saveAppliedFilters, handleButtonClick, activeSavedFiltersUpdated } =
    useSavedFilters()
  const { handelModal, showEditModal } = useSavedSearchModal()
  const filtersLength = appliedFilters?.length

  return (
    <>
      <Card className="hc-ma-dense applied-filters-card">
        <Grid.Container
          className="hc-ma-none"
          style={{ width: '100%' }}
          justify="space-between"
        >
          <Grid.Item xs={6}>
            <p>
              <strong>Applied Filters</strong>
            </p>
          </Grid.Item>
          <Grid.Item xs={6} className="hc-ta-right">
            {(!!stickerType || !!searchText || filtersLength > 0) && (
              <Anchor
                href="#"
                onClick={(eve) => {
                  deleteAllFilters(eve)
                  writeFiltersToLocalStorage('LP_SEARCH_CRITERIA', {})
                  dispatch(setHeaderCheckboxState(false))
                }}
              >
                Clear All
              </Anchor>
            )}
          </Grid.Item>
          <Grid.Item xs={12} className="hc-pa-none">
            <Divider style={{ width: '100%' }} />
          </Grid.Item>
        </Grid.Container>

        <Grid.Container
          className="hc-ma-none "
          style={{ width: '100%' }}
          justify="space-between"
        >
          {(!!stickerType || !!searchText || filtersLength > 0) && (
            <Grid.Item xs={12} className="hc-pb-none">
              <Card
                className="filters-card show-scroll"
                corners="none"
                elevation="0"
              >
                {searchText && (
                  <>
                    <p className="hc-fs-sm hc-clr-grey01 hc-mb-min hc-mt-dense">
                      Quick Search
                    </p>
                    <div style={{ display: 'inline-block' }}>
                      <Chip
                        className="hc-bg-grey06 search-text"
                        onRequestDelete={() => handleDeleteQuickSearch()}
                      >
                        {searchText}
                      </Chip>
                    </div>
                  </>
                )}
                {stickerType && (
                  <>
                    <p className="hc-fs-sm hc-clr-grey01 hc-mb-min hc-mt-dense">
                      <Tooltip location="top" tooltip="Blockers">
                        Blockers
                      </Tooltip>
                    </p>
                    <div style={{ display: 'inline-block' }}>
                      <Chip
                        className="hc-bg-grey06"
                        onRequestDelete={() => handleDeleteSticker()}
                      >
                        {stickerType}
                      </Chip>
                    </div>
                  </>
                )}
                {appliedFilters?.map((filters, index) => {
                  return (
                    <AppliedFiltersChips
                      key={`applied_filters_chips_${index}`}
                      allFilters={appliedFilters}
                      filtersValue={filters}
                    />
                  )
                })}
              </Card>
            </Grid.Item>
          )}
        </Grid.Container>
        <div className="hc-pl-sm hc-pb-sm btn-holder">
          <Button
            type="secondary"
            onClick={() => handelModal('add')}
            disabled={!searchText && filtersLength <= 0}
          >
            <EnterpriseIcon
              icon={PlusCircleIcon}
              size="inline"
              className="hc-mr-min"
            />
            Save as New
          </Button>
          <Button
            type="secondary"
            onClick={() => saveAppliedFilters('update')}
            disabled={!activeSavedFiltersUpdated || filtersLength <= 0}
            className="hc-ml-dense hc-pt-min"
          >
            Update
          </Button>
        </div>
      </Card>
      <div>
        <SavedSearchModal
          handelModal={handelModal}
          showEditModal={showEditModal}
          handleButtonClick={handleButtonClick}
          editSavedSearch={appliedFilters}
          modalName="add"
        />
      </div>
    </>
  )
}
