import { isEmpty } from 'lodash'

export const getBarcodesFromInput = (value) => {
  const splitStrings = value.split(/ |,|;|\n/)
  const barcodes = splitStrings.filter((str) => !isEmpty(str))

  const valid = []
  const invalid = []
  barcodes.forEach((barcode) => {
    if (barcode.match(/^[0-9]{8,18}$/)) {
      // Numeric only, must be between 8 and 18 digits
      valid.push(barcode)
    } else {
      invalid.push(barcode)
    }
  })

  return { valid, invalid }
}

export const getNewDpcisFromString = (input) => {
  const splitStrings = input.split(/ |,|;|\n|\s/)
  const dpcis = splitStrings.filter((str) => !isEmpty(str))
  const invalidDpcis = []
  const validDpcis = []

  if (dpcis.length) {
    dpcis.forEach((dpci) => {
      const numRegex = /^[0-9]*$/
      const dpciDelimiter = /[/-]/
      const departmentStringLength = 3
      const classStringLength = 2
      const itemStringLength = 4

      const dpciStringArray = dpci.split(dpciDelimiter)

      if (dpciStringArray.length === 3) {
        // Checking for any of the fields being too long / Checking for numerical input only.
        if (
          dpciStringArray[0].length > 3 ||
          dpciStringArray[1].length > 2 ||
          dpciStringArray[2].length > 4 ||
          !dpciStringArray[0].match(numRegex) ||
          !dpciStringArray[1].match(numRegex) ||
          !dpciStringArray[2].match(numRegex)
        ) {
          invalidDpcis.push(dpci)
        } else if (dpciStringArray.length > 3 || dpciStringArray.length < 3) {
          invalidDpcis.push(dpci)
        } else {
          const dpciDepartment = DPCILeadingZeroStuffer(
            departmentStringLength,
            dpciStringArray[0],
          )
          const dpciClass = DPCILeadingZeroStuffer(
            classStringLength,
            dpciStringArray[1],
          )
          const dpciItem = DPCILeadingZeroStuffer(
            itemStringLength,
            dpciStringArray[2],
          )

          const newDPCIStringArray = [dpciDepartment, dpciClass, dpciItem]
          validDpcis.push(DPCIStringBuilder(newDPCIStringArray))
        }
      } else {
        invalidDpcis.push(dpci)
      }
    })
  }
  return { valid: validDpcis, invalid: invalidDpcis }
}

const DPCILeadingZeroStuffer = (necessaryStringLength, givenString) => {
  if (
    necessaryStringLength === givenString.length ||
    givenString.length > necessaryStringLength
  ) {
    return givenString
  } else {
    return '0'.repeat(necessaryStringLength - givenString.length) + givenString
  }
}

const DPCIStringBuilder = (DPCIStringArray) => {
  const returnedDPCIString = DPCIStringArray.reduce((acc, val) => {
    return acc + '-' + val
  })

  return returnedDPCIString
}

export const getNewTcinsFromString = (value) => {
  const splitStrings = value.split(/ |,|;|\n/)
  const tcins = splitStrings.filter((str) => !isEmpty(str))

  if (tcins.length) {
    return {
      tcins: tcins.map((tcin) => ({
        value: tcin,
        displayValue: tcin,
        pending: true,
      })),
    }
  }

  return { tcins: [] }
}
export const getNewStyleNumbersFromString = (value) => {
  const splitStrings = value.split(/ |,|;|\n/)
  const styleNumbers = splitStrings.filter((str) => !isEmpty(str))

  if (styleNumbers.length) {
    return {
      styleNumbers: styleNumbers.map((styleNumber) => ({
        value: styleNumber,
        displayValue: styleNumber,
      })),
      clearInput: true,
    }
  }

  return { styleNumbers: [], clearInput: false }
}
