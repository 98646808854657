import ExcelJS from 'exceljs'
import { saveAs } from 'file-saver'

import {
  catHeaderStyle,
  logBase64Image,
  InstructionSheetStyle,
  alignCenter,
  warpText,
  boldItalic,
  BULK_TEXT,
} from '../constants/bulkExport'

export const constructInstruction = (workbook, isMultiSheet) => {
  const line4 = isMultiSheet
    ? BULK_TEXT.EXPORT.INSTRUCTION.LINE4_M
    : BULK_TEXT.EXPORT.INSTRUCTION.LINE4

  const logoImg = workbook.addImage({
    base64: logBase64Image,
    extension: 'png',
  })

  const InstructionSheet = workbook.addWorksheet('Instructions')
  InstructionSheet.views = [{ zoomScale: 120 }]
  InstructionSheet.mergeCells('A1:Y9')
  InstructionSheet.mergeCells('A10:Y10')
  InstructionSheet.addImage(logoImg, {
    tl: { col: 12, row: 1 },
    ext: { width: 118, height: 160 },
  })
  InstructionSheet.mergeCells('A11:Y11')
  InstructionSheet.getCell('A11').value = BULK_TEXT.EXPORT.INSTRUCTION.LINE1
  InstructionSheet.getCell('A11').font = InstructionSheetStyle.header.font
  InstructionSheet.getCell('A11').alignment = alignCenter
  InstructionSheet.getCell('A12').value = BULK_TEXT.EXPORT.INSTRUCTION.LINE2
  InstructionSheet.mergeCells('A12:Y12')
  InstructionSheet.getCell('A13').value = BULK_TEXT.EXPORT.INSTRUCTION.LINE3
  InstructionSheet.getCell('A13').font = InstructionSheetStyle.exportTitle.font
  InstructionSheet.getCell('A13').fill = InstructionSheetStyle.exportTitle.fill
  InstructionSheet.mergeCells('A13:Y13')
  InstructionSheet.getCell('A14').style = InstructionSheetStyle.text
  InstructionSheet.getCell('A14').value = line4
  InstructionSheet.mergeCells('A14:Y18')
  InstructionSheet.getCell('A14').style.alignment = warpText
  if (!isMultiSheet) {
    InstructionSheet.getCell('A19').value = BULK_TEXT.EXPORT.INSTRUCTION.LINE7
    InstructionSheet.getCell('A19').style = InstructionSheetStyle.text
    InstructionSheet.getCell('A19').style.alignment = warpText
    InstructionSheet.mergeCells('A19:Y20')
  }
  return InstructionSheet
}

/* Function to convert inter to Excel cell position */
const covertToExcelPosition = (num) => {
  const res = String.fromCharCode(65 + (num % 26))
  return num >= 26 ? covertToExcelPosition(Math.floor(num / 26) - 1) + res : res
}

export const flattenObject = (attributeObj) => {
  const flattened = []
  const flattenHelper = (attributeValues) => {
    const flattenedObj = []
    Object.entries(attributeValues).forEach(([, value]) => {
      if (Array.isArray(value)) {
        if (Array.isArray(value[0])) {
          value.forEach((item) => {
            const arrayVal = item.join().split('::')
            if (arrayVal[1]) {
              flattenedObj.push(arrayVal[1].replace(/[\[\]']+/g, "")); //eslint-disable-line
            }
          })
        } else {
          flattenedObj.push(value.join(', '))
        }
      } else {
        flattenedObj.push(value)
      }
    })
    flattened.push(flattenedObj)
  }
  attributeObj.forEach((item) => {
    flattenHelper(item)
  })

  return flattened
}

const constructExcelDataMultiSheet = async (workbook, data, itemTypeLabel) => {
  const catStart = 4 // Start position for category
  const subCatStart = 5 // Start position for category
  const rowTitleSpace = 1 // For ROW Title Shift
  data.forEach((sheetData, index) => {
    const dataSheet = workbook.addWorksheet(
      `${sheetData.itemType.substr(11)} - ${sheetData.itemTypeId}`,
      {
        properties: {
          defaultColWidth: 20,
        },
        views: [
          {
            state: 'frozen',
            xSplit: 4,
            ySplit: 6,
            zoomScale: 120,
            activeCell: 'C7', // Point the cursor
          },
        ],
      },
    )
    dataSheet.getCell('A1').value = BULK_TEXT.EXPORT.DATATEXT.NOTE1
    dataSheet.getCell('A1').font = { size: 11 }
    dataSheet.getCell('A2').value = BULK_TEXT.EXPORT.DATATEXT.NOTE2
    dataSheet.getCell('A2').font = { size: 11, ...boldItalic }
    dataSheet.getCell(`A${catStart}`).value = BULK_TEXT.EXPORT.ROWHEAD.GROUP
    dataSheet.getCell(`A${catStart}`).font = { size: 10, ...boldItalic }
    dataSheet.getCell(`A${subCatStart}`).value =
      BULK_TEXT.EXPORT.ROWHEAD.ATTR_GROUP
    dataSheet.getCell(`A${subCatStart}`).font = { size: 10, ...boldItalic }
    // /* Iterate categories */
    sheetData.categoriesObj.forEach((category, index) => {
      const catCellStart = covertToExcelPosition(category.start + rowTitleSpace)
      const catCellEnd = covertToExcelPosition(category.end + rowTitleSpace)
      const catCell = `${catCellStart}${catStart}:${catCellEnd}${catStart}`
      dataSheet.mergeCells(catCell)
      if (category.displayName !== '') {
        const getCell = dataSheet.getCell(
          `${covertToExcelPosition(category.start + 1)}${catStart}`,
        )

        let iterationIndex =
          index <= catHeaderStyle.length
            ? index - 1
            : (index % catHeaderStyle.length) - 1
        getCell.value = category.displayName
        iterationIndex = iterationIndex >= 0 ? iterationIndex : 0
        getCell.fill = {
          type: 'pattern',
          pattern: 'solid',
          ...catHeaderStyle[iterationIndex].fill,
        }
        getCell.font = { size: 12, ...catHeaderStyle[iterationIndex].font }
        getCell.value = getCell.value.split('_').join(' ')
        getCell.value = getCell.value.toUpperCase()
      }
    })

    /* Iterate Sub categories */
    sheetData.subCategoriesObj.forEach((subCategory) => {
      const subCatCellStart = covertToExcelPosition(
        subCategory.start + rowTitleSpace,
      )
      const subCatCellEnd = covertToExcelPosition(
        subCategory.end + rowTitleSpace,
      )
      const subCatCell = `${subCatCellStart}${subCatStart}:${subCatCellEnd}${subCatStart}`
      dataSheet.mergeCells(subCatCell)
      const getCell = dataSheet.getCell(`${subCatCellStart}${subCatStart}`)
      const prevCell = `${subCatCellStart}${subCatStart - 1}`
      getCell.value = subCategory.displayName
      const prevRoeCell = dataSheet.getCell(prevCell).font // Get the font color from above cell
      getCell.font = { size: 12, bold: true, ...prevRoeCell }
    })

    // Add the row with attribute header
    const attributeHeader = dataSheet.addRow(
      sheetData.attributeName.map((row) => row.attributeDisplayName),
    )
    // Add the row with attribute Value
    const rows = flattenObject(sheetData.attributeValues)
    rows.forEach((row) => {
      dataSheet.addRow(row).splice(1, 0, '')
    })
    attributeHeader.splice(1, 0, BULK_TEXT.EXPORT.ROWHEAD.ATTR)
    attributeHeader.font = { bold: true, size: 12 }

    // Loop thru all rows and set wrap text
    dataSheet.eachRow((row, rowNumber) => {
      if (rowNumber > subCatStart) {
        row.alignment = warpText // eslint-disable-line no-param-reassign
      }
    })
    applyColumnFilter(dataSheet, subCatStart, sheetData)
  })
}

export const applyColumnFilter = async (worksheet, subCatStart, sheetData) => {
  const lastRow = worksheet.rowCount
  const lastColumn = sheetData.attributeName.length + 1
  worksheet.autoFilter = {
    from: `A${subCatStart + 1}`,
    to: {
      row: lastRow,
      column: lastColumn,
    },
  }
}

export const generateExportExcelMuLtiSheet = async (
  itemData,
  fileName,
  itemTypeLabel,
) => {
  let downloaded
  const data = itemData
  const workbook = new ExcelJS.Workbook()
  workbook.category = 'LaunchPad'
  workbook.lastModifiedBy = `LaunchPad`
  workbook.created = new Date()
  workbook.modified = new Date()
  workbook.views = [
    {
      x: 0,
      y: 0,
      width: 37000,
      height: 20000,
      firstSheet: 0,
      activeTab: 1,
      visibility: 'visible',
    },
  ]

  constructInstruction(workbook, true)
  await constructExcelDataMultiSheet(workbook, data, itemTypeLabel)
  await workbook.xlsx
    .writeBuffer()
    .then((buffer) => {
      saveAs(
        new Blob([buffer], { type: 'application/octet-stream' }),
        `${fileName}.xlsx`,
      )
      downloaded = 'success'
    })
    .catch(() => {
      downloaded = 'failed'
    })
  return downloaded
}
