import { useState, useEffect, useCallback } from 'react'
import { useDebounce } from './useDebounce'
import { formatOptions } from '../../globalUtils/searchUtills'

const useFetchOptions = (
  refetch,
  attributeId,
  dependentAttributeId,
  dependentValue,
) => {
  const [fetchedOptions, setFetchedOptions] = useState([])
  const [searchText, setSearchText] = useState('')
  const [fetch, setFetch] = useState(false)
  const [loading, setLoading] = useState(false)

  const debouncedSearchText = useDebounce(searchText, 250)

  const getOptions = useCallback(
    async (searchtextParam) => {
      let formattedOptions = []
      setLoading(true)
      if (fetch) {
        setFetchedOptions([])
        try {
          const { data } = await refetch({
            attributeId,
            attributeParams: dependentAttributeId
              ? {
                  [dependentAttributeId]: dependentValue
                    ? [dependentValue]
                    : [],
                }
              : '',
            searchText: searchtextParam,
          })

          if (data?.result?.options) {
            formattedOptions = formatOptions(data.result.options)
          }

          setLoading(false)
          setFetchedOptions(formattedOptions)
        } catch {
          setLoading(false)
          setFetchedOptions([])
        }
      }
      return formattedOptions
    },
    [fetch, refetch, attributeId, dependentAttributeId, dependentValue],
  )

  useEffect(() => {
    if (fetch) {
      getOptions(debouncedSearchText)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchText, fetch])

  return {
    fetchedOptions,
    setFetchedOptions,
    loading,
    setFetch,
    setSearchText,
  }
}

export default useFetchOptions
