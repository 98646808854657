import { Input, Button } from '@enterprise-ui/canvas-ui-react'
import EnterpriseIcon, { PencilIcon, TrashIcon } from '@enterprise-ui/icons'
import { useState } from 'react'
import { useMutation } from '@apollo/client'
import {
  DELETE_COMMENT,
  UPDATE_COMMENT,
} from '../../../../apiItemGraphql/comment'

const CommentActions = ({ comment, commentId, refetchComments }) => {
  const [isEditMode, setEditMode] = useState(false)
  const [commentInput, setCommentInput] = useState('')

  const [updateCommentGql] = useMutation(UPDATE_COMMENT)
  const [deleteCommentGql] = useMutation(DELETE_COMMENT)

  const updateComment = async () => {
    const res = await updateCommentGql({
      variables: {
        newComment: commentInput,
        previousComment: comment,
        commentId: commentId,
      },
    })
    if (res?.data) {
      setEditMode(false)
      await refetchComments()
    }
  }
  const deleteComment = async () => {
    const res = await deleteCommentGql({
      variables: {
        commentId: commentId,
      },
    })
    if (res?.data) {
      setEditMode(false)
      await refetchComments()
    }
  }
  const inputval = commentInput ? commentInput : comment
  return (
    <div className="flex-space-between">
      <div className="flex-space-between width-70-percent">
        {!isEditMode && <div>{comment}</div>}
        {isEditMode && (
          <>
            <Input.Text
              value={commentInput ? commentInput : comment}
              onChange={(event) => {
                setCommentInput(event.target.value)
              }}
              data-testid="update-comment-input"
            />
            <Button
              type="primary"
              className="hc-ml-min width-fit-content"
              onClick={() => updateComment()}
              data-testid="update-comment-save-button"
              disabled={inputval.length < 1}
            >
              SAVE
            </Button>
          </>
        )}
      </div>

      <div>
        <Button
          iconOnly
          type="ghost"
          className="hc-mr-md comment-actions-icon"
          onClick={() => setEditMode(true)}
          data-testid="comment-edit-icon"
          aria-label="comment update button"
        >
          <EnterpriseIcon icon={PencilIcon} size="lg" />
        </Button>
        <Button
          iconOnly
          type="ghost"
          className="hc-mr-md comment-actions-icon"
          onClick={() => deleteComment()}
          data-testid="comment-delete-icon"
          aria-label="comment delete button"
        >
          <EnterpriseIcon icon={TrashIcon} size="lg" />
        </Button>
      </div>
    </div>
  )
}

export default CommentActions
