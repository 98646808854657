import { Pagination, Input, Grid } from '@enterprise-ui/canvas-ui-react'
import '../../../globalStyles/search.scss'

const PaginationControls = (props) => {
  const {
    getTotalPage,
    getCurrentPage,
    onBtLast,
    onBtNext,
    onBtSpecificPage,
    onBtPrevious,
    onBtFirst,
    paginationPageSizeValue,
    handlePaginationPageSizeChange,
    loading,
    displayText,
  } = props
  const options = [
    {
      value: 50,
      label: '50',
      disabled: loading,
    },
    {
      value: 75,
      label: '75',
      disabled: loading,
    },
    {
      value: 100,
      label: '100',
      disabled: loading,
    },
  ]

  return (
    <Grid.Container className="pagination_main">
      <span className="hc-clr-grey01 display_text">{displayText}</span>
      <Grid.Item className="pagination_control">
        <Pagination
          // pageSelector // Todo Page frozen with this
          totalPages={getTotalPage}
          currentPage={getCurrentPage}
          onRequestLast={() => {
            onBtLast()
          }}
          onRequestNext={() => {
            onBtNext()
          }}
          onRequestPage={(event, value) => {
            onBtSpecificPage(event, value)
          }}
          onRequestPrev={() => {
            onBtPrevious()
          }}
          onRequestFirst={() => {
            onBtFirst()
          }}
        />
      </Grid.Item>
      <Grid.Item className="pagination_page_count hc-mb-normal">
        <span className="hc-clr-grey01">Items per page</span>
        <Input.Select
          id="searchGrid-pagination-select"
          options={options}
          onUpdate={(id, value) => handlePaginationPageSizeChange(id, value)}
          value={paginationPageSizeValue}
        />
      </Grid.Item>
    </Grid.Container>
  )
}

export default PaginationControls
