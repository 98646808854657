import MultipleFilterComponent from '../resuableFilters/mulitpleFilterComponenet'

export const MerchandiseHierarchyFilter = ({
  id,
  onFilterChange,
  isFav,
  selectedValue,
  setSelectedValues,
}) => {
  return (
    <MultipleFilterComponent
      filterId={id}
      isFav={isFav}
      attributeId="department"
      attributeKey="deptId"
      dependentAttributeId="class"
      dependentAttributeKey="classId"
      label="Department"
      dependentLabel="Class (Optional)"
      onFilterChange={onFilterChange}
      selectedValue={selectedValue}
      setSelectedValues={setSelectedValues}
    />
  )
}
