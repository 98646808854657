export const internal_columns = [
  'calculatedSetupStatus',
  'tcin',
  'dpci',
  'pyramid',
  'division',
  'departmentId',
  // 'Department_Name',
  'classId',
  'Class_Name',
  'merchandiseTypeName',
  'itemTypeName',
  'legacyCreateType',
  'productTitle',
  'vendorDescription',
  'POS Description',
  'Shelf Description',
  'shortDescription',
  'launchPadCreatedTimestamp',
  'launchPadVendorComplete',
  'launchPadComplete',
  'imnLifecycleStatus',
  'imnCreateTimestamp',
  'mtaCompletionDate',
  'launchDate',
  'streetDate',
  'Target Street Date & Time',
  'assortmentType',
  'intendedSellingChannels',
  'vendor',
  'allBarcodes',
  'allManufacturerStyleNumber',
  'allBrands',
  'complianceReady',
  'Recall Indicator',
  'hazmatBlockReasons',
  'hazmatPublish',
  'importApprovalStatus',
  'relationshipType',
  'variationParent',
  'collectionParent',
  'bundleMaster',
  'dynamicAssortmentMaster',
  'onlineLocationStatus',
  'itemCost', //'regularRetail3991',   // 'Retail Ticketed',
  'hasPrimaryImage',
  // 'Sample Status',
  // 'Sample Due Date',
  // 'Sample Received Date',   // 'Pipeline Pending Image Count',
  'merchantPublishFlag',
  'hasInventory',
  'longCopy',
  'softBullets',
  'previewUrl',
  // 'Task Status',
  'mostRecentComment',
  // 'IDS',
  'assortmentPlanName',
  'apexBuyerComments',
  'apexCommitManagementSystemIdentification',
  'commitmentDate',
  'apexProductTitleNotes',
  'apexVendorEmail',
  // 'item_validation_data',
  'itemDataQualityIssues',
  'vendorDetails',
  'primaryVendorName',
  'parentTcin',
  'primaryImageUrl',
]
