import { configureStore } from '@reduxjs/toolkit'
import userProfileReducer from './userProfile'
import userSearchProfileReducer from './userSearchProfile'
import searchHeaderReducer from './searchHeader'
import bannerHeaderReducer from './bannerHeader'
import searchColumnsReducer from './searchColumns'

const store = configureStore({
  reducer: {
    userProfile: userProfileReducer,
    userSearchProfile: userSearchProfileReducer,
    searchHeader: searchHeaderReducer,
    bannerHeader: bannerHeaderReducer,
    searchColumns: searchColumnsReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }),
})

export const dispatch = store.dispatch

export default store
