import {
  Button,
  Card,
  Chip,
  Grid,
  Input,
  Modal,
  Placeholder,
  Tooltip,
  useToaster,
} from '@enterprise-ui/canvas-ui-react'
import EnterpriseIcon, {
  ChartPieIcon,
  PlusCircleIcon,
} from '@enterprise-ui/icons'
import { NavLink } from 'react-router-dom'

import { SavedSearchDashboard } from './SavedSearchDashboard'

import { useMyDashboard } from './useMyDashboard'
import { DASHBOARD_TEXT } from '../../../constants/dashboard-contants'

export const MyDashboard = () => {
  const {
    loading,
    error,
    savedSearches,
    isSavedSearchOpen,
    editSearch,
    handleSavedSearchesModal,
    handleCheckboxChange,
    handleConfirm,
  } = useMyDashboard()
  const makeToast = useToaster()

  // Check if there are saved searches and none should be shown
  const noVisibleSavedSearches =
    savedSearches && savedSearches.every((search) => !search.showInDashboard)

  if (loading) return <Placeholder.Rect emphasized />

  if (error) {
    makeToast({
      type: 'error',
      heading: 'Error',
      message: error.message,
    })
  }

  return (
    <Card className="hc-pa-expanded hc-mt-expanded dashboard-saved-search-action hc-pl-min hc-ov-scroll">
      <Grid.Container>
        <Grid.Item xs={12} className="mydashboard-action-header">
          <h4 className="hc-fs-md hc-lh-expanded font-color-default font-weight-700 display-flex">
            <EnterpriseIcon icon={ChartPieIcon} className="hc-mr-dense" />
            <span data-testid="dashboardTitle"> {DASHBOARD_TEXT.title}</span>
          </h4>
          <Tooltip content="Add saved search" location="top">
            <Button
              type="ghost"
              iconOnly
              className="hc-mr-dense"
              onClick={handleSavedSearchesModal}
              aria-label={DASHBOARD_TEXT.buttonLabel}
              data-testid="plusCircleIcon"
            >
              <EnterpriseIcon icon={PlusCircleIcon} />
            </Button>
          </Tooltip>
        </Grid.Item>
        {noVisibleSavedSearches ? (
          <Grid.Item xs={12} className="hc-pt-none">
            <p
              className="hc-fs-md hc-lh-expanded hc-mt-normal font-color-default font-weight-400"
              data-testid="dashboardDescription"
            >
              {DASHBOARD_TEXT.description}
            </p>
            <Button
              type="primary"
              onClick={handleSavedSearchesModal}
              className="saved-search-button"
            >
              {DASHBOARD_TEXT.buttonLabel}
            </Button>
          </Grid.Item>
        ) : null}
        <Grid.Item xs={12} className="hc-pt-none">
          <SavedSearchDashboard
            savedSearches={savedSearches}
            editSearch={editSearch}
          />
        </Grid.Item>
      </Grid.Container>
      <Modal
        isVisible={isSavedSearchOpen}
        size="dense"
        headingText="Add Saved Search to Dashboard"
        onRefuse={handleSavedSearchesModal}
        className="modal-container-dashboard"
      >
        <div className="hc-pa-normal ">
          <div className="scrollable-content">
            {savedSearches?.length !== 0 && (
              <>
                <Grid.Container direction="column">
                  {savedSearches?.map((search) =>
                    search.showInDashboard ? (
                      <Grid.Item key={search.id}>
                        <Grid.Container className="hc-pa-dense" direction="row">
                          <Grid.Item xs={10} className="hc-bg-grey07 ">
                            <Input.Checkbox
                              className="hc-pa-dense"
                              id={search.id}
                              label={search.name}
                              defaultChecked
                              onChange={() => handleCheckboxChange(search.id)}
                            />
                          </Grid.Item>
                          <Grid.Item xs={2} className="hc-bg-grey07 ">
                            <Chip
                              size="normal"
                              className="chip-color-interactive bg-color-interactive"
                            >
                              {DASHBOARD_TEXT.appliedLabel}
                            </Chip>
                          </Grid.Item>
                        </Grid.Container>
                      </Grid.Item>
                    ) : (
                      <Grid.Item
                        key={search.id}
                        xs={12}
                        className="hc-bg-grey07 hc-mv-dense"
                      >
                        <Input.Checkbox
                          className="hc-pa-dense hc-ml-dense"
                          id={search.id}
                          label={search.name}
                          onChange={() => handleCheckboxChange(search.id)}
                        />
                      </Grid.Item>
                    ),
                  )}
                </Grid.Container>
              </>
            )}
          </div>
          {savedSearches?.length > 0 && (
            <Grid.Container direction="row-reverse">
              <Grid.Item>
                <Button
                  className="hc-mt-normal"
                  onClick={handleConfirm}
                  type="primary"
                >
                  {DASHBOARD_TEXT.confirmButtonLabel}
                </Button>
              </Grid.Item>
            </Grid.Container>
          )}
          {savedSearches?.length === 0 && (
            <Grid.Container direction="column">
              <Grid.Item className="app-bg-color">
                {DASHBOARD_TEXT.noSearchesText}
                <NavLink className="font-color-interactive" to="/search">
                  {' Search '}
                </NavLink>
                {DASHBOARD_TEXT.noSearchesText1}
              </Grid.Item>
            </Grid.Container>
          )}
        </div>
      </Modal>
    </Card>
  )
}
