import { createSlice } from '@reduxjs/toolkit'

const searchColumnsSlice = createSlice({
  name: 'searchColumns',
  initialState: {
    activeTab: 'my_columns',
    headerCheckbox: false,
  },
  reducers: {
    setActiveTab: (state, action) => {
      state.activeTab = action.payload
    },
    setHeaderCheckboxState: (state, action) => {
      state.headerCheckbox = action.payload
    },
  },
})

export const { setActiveTab, setHeaderCheckboxState } =
  searchColumnsSlice.actions
export default searchColumnsSlice.reducer
