import gql from 'graphql-tag'
export const bannerQuery = () => gql`
  query getBannerDetail {
    getBannerDetail {
      bannerDetails {
        _id
        body
        heading
        isDismissable
        applicableBrowser
        link
        linkText
        showFrom
        showTo
        type
      }
      error
    }
  }
`
