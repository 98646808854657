import gql from 'graphql-tag'
// import { omitTypenameDeep } from '../globalUtils/commonUtills'

export const getUserProfileFromGql = () => gql`
  query getUserProfile {
    getUserProfile {
      userProfile {
        Common {
          userDeptandClasses {
            deptId
            classId
          }
          completedWalkthroughs
        }
        Dashboard {
          userFiltersandGroupBy {
            taskId
            filters {
              id
              values {
                displayValue
                value
              }
            }
            groupBy
            ignoreUserDashboardFilters
          }
        }
        Search {
          favoriteFilterIds
          columnPreference {
            id
            width
            visible
          }
          useColumnPreference
          isSearchActionPanelExpanded
          savedSearchesOrder
        }
      }
      error
    }
  }
`

export const setUserProfileColumnPreferenceApi = () => gql`
  mutation setUserProfileProperty(
    $userProfileProperty: [UserProfileProperty!]!
  ) {
    setUserProfileProperty(userProfileProperty: $userProfileProperty) {
      userProfile {
        Search {
          columnPreference {
            id
            width
            visible
          }
        }
      }
      error
    }
  }
`

export const getSearchColumns = () => gql`
  query searchColumns(
    $filters: [SearchFilter]!
    $useColumnPreference: Boolean
  ) {
    searchColumns(
      filters: $filters
      useColumnPreference: $useColumnPreference
    ) {
      results {
        id
        width
        visible
      }
      errors
    }
  }
`
