import { Button } from '@enterprise-ui/canvas-ui-react'
import EnterpriseIcon, { FilterFunnelIcon } from '@enterprise-ui/icons'
import { QuickSearch } from './quickSearch'
import { FilterSection } from './filterSection'
import { AppliedFilters } from './appliedFilters'
export const SearchRail = (props) => {
  const { isCollapsed, setIsCollapsed } = props
  return (
    <>
      <div className="section">
        {isCollapsed ? (
          <div className="no-filter vertical-align">
            <Button type="ghost" onClick={() => setIsCollapsed(!isCollapsed)}>
              <EnterpriseIcon
                icon={FilterFunnelIcon}
                className="vertical-align-icon hc-mb-dense"
              />
              Filters and Saved Search
            </Button>
          </div>
        ) : (
          <>
            <div id="quick-search">
              <QuickSearch />
            </div>
            <div id="all-filters">
              <FilterSection />
            </div>
            <div id="applied-filters">
              <AppliedFilters />
            </div>
          </>
        )}
      </div>
    </>
  )
}
