/* eslint-disable react-hooks/exhaustive-deps */
import { List, Grid } from '@enterprise-ui/canvas-ui-react'

import SavedSearchDashboardItem from './SavedSearchDashboardItem'

import { TABLE_HEADERS } from '../../../constants/dashboard-contants'
import useSavedSearchDashboard from './useSavedSearchDashboard'

export const SavedSearchDashboard = ({ savedSearches, editSearch }) => {
  const { removeSavedSearch } = useSavedSearchDashboard(
    savedSearches,
    editSearch,
  )

  const showHeaders =
    savedSearches && savedSearches.some((search) => search.showInDashboard)

  return (
    <>
      {showHeaders && (
        <div className="hc-mt-normal saved-search-lists-main-block">
          <Grid.Item xs={3}>
            <h5 className="hc-fs-xs font-weight-500 font-color-secondary hc-lh-dense">
              {TABLE_HEADERS.savedSearch}
            </h5>
          </Grid.Item>
          <Grid.Item xs={2}>
            <h5 className="hc-fs-xs font-weight-500 font-color-secondary hc-lh-dense">
              {TABLE_HEADERS.notReadyForOrder}
            </h5>
          </Grid.Item>
          <Grid.Item xs={2}>
            <h5 className="hc-fs-xs font-weight-500 font-color-secondary hc-lh-dense">
              {TABLE_HEADERS.notReadyForLaunch}
            </h5>
          </Grid.Item>
          <Grid.Item xs={2}>
            <h5 className="hc-fs-xs font-weight-500 font-color-secondary hc-lh-dense">
              {TABLE_HEADERS.readyForLaunch}
            </h5>
          </Grid.Item>
          <Grid.Item xs={2}>
            <h5 className="hc-fs-xs font-weight-500 font-color-secondary hc-lh-dense">
              {TABLE_HEADERS.total}
            </h5>
          </Grid.Item>
          <Grid.Item xs={1}>
            <h5 className="hc-fs-xs font-weight-500 font-color-secondary hc-lh-dense visibility-hidden">
              {TABLE_HEADERS.action}
            </h5>
          </Grid.Item>{' '}
        </div>
      )}
      <List className="hc-mt-normal" id="saved-search-lists">
        {savedSearches
          ?.filter((search) => search?.showInDashboard)
          .map((search) => (
            <SavedSearchDashboardItem
              key={search.name}
              search={search}
              removeSavedSearch={removeSavedSearch}
            />
          ))}
      </List>
    </>
  )
}
