import { useState, useEffect } from 'react'
export const useSavedSearchModal = () => {
  const [showEditModal, setShowEditModal] = useState(false)
  const [editSavedSearch, setEditSavedSearch] = useState()
  const [inputValue, setInputValue] = useState()
  const [isChecked, setIsChecked] = useState(false)
  const [modalName, setModalName] = useState('edit')
  // Get saved searches
  useEffect(() => {
    setInputValue(inputValue)
  }, [inputValue])
  const handelModal = (value, type) => {
    setModalName(type)
    setShowEditModal(!showEditModal)
    setEditSavedSearch(value)
  }

  return {
    showEditModal,
    editSavedSearch,
    inputValue,
    setInputValue,
    isChecked,
    setIsChecked,
    modalName,
    handelModal,
  }
}
