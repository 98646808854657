import { useAllFilters } from './useAllFilters'
import {
  Input,
  Card,
  Divider,
  Button,
  Grid,
  Spinner,
} from '@enterprise-ui/canvas-ui-react'
import { useState, useEffect } from 'react'
import { isEmpty } from 'lodash'
import { useToaster } from '@enterprise-ui/canvas-ui-react'
import { useDispatch } from 'react-redux'
import { updateFilters } from '../../../../../store/userSearchProfile'
import { useMainRouter } from '../../../../../globalComponents/useMainRouter'
import { useMutation } from '@apollo/client'
import { setUserProfilePropertyMutation } from '../../../../../apiItemGraphql/userSearch'
import { FilterList } from './FilterList'
import { useFirefly } from '../../../../../globalUtils/useAnalytics'
import { formatToTwoDigits } from '../../../../../globalUtils/commonUtills'
import { getUserProfileFromGql } from '../../../../../apiItemGraphql'
import { FIREFLY_EVENTS } from '../../../../../constants/search'

export const AllFilters = () => {
  const { searchFilter, loading } = useAllFilters()
  const [selectedValues, setSelectedValues] = useState(null)
  const makeToast = useToaster()
  const [filters, setFilters] = useState([])
  const [searchFilterQuery, setSearchFilterQuery] = useState('')
  const dispatch = useDispatch()
  const [favoriteFilters, setFavoriteFilters] = useState([])
  const { data: userProfileData } = useMainRouter()
  const [setUserProfileProperty] = useMutation(setUserProfilePropertyMutation())
  const { handleTrackCustomEvent } = useFirefly()

  useEffect(() => {
    if (userProfileData) {
      const favoriteFilterIds =
        userProfileData.getUserProfile?.userProfile?.Search
          ?.favoriteFilterIds || []

      setFavoriteFilters(favoriteFilterIds)
    }
  }, [userProfileData])

  const handleApply = () => {
    const removePendingFromFilters = filters.map((filter) => {
      const updatedValues = filter?.values?.map(({ pending, ...rest }) => rest)
      return {
        ...filter,
        values: updatedValues,
      }
    })

    setFilters(removePendingFromFilters)

    // event capture
    removePendingFromFilters.forEach((filter) => {
      filter.values?.forEach((value) => {
        handleTrackCustomEvent(
          FIREFLY_EVENTS.EVENT_TYPE.FILTER_APPLIED,
          filter?.id,
          value?.displayValue,
        )
      })
    })

    dispatch(updateFilters(removePendingFromFilters))
    setSelectedValues(null)
    setFilters([])
  }

  const handleFilterChange = (id, values) => {
    setFilters((prevFilters) => {
      const existingFilter = prevFilters.some((filter) => filter?.id === id)
      if (existingFilter) {
        if (values === null) {
          return prevFilters.filter((filter) => filter?.id !== id)
        } else {
          return prevFilters.map((filter) =>
            filter?.id === id ? { ...filter, values } : filter,
          )
        }
      } else {
        if (values != null) {
          return [...prevFilters, { id, values }]
        }
        return [...prevFilters]
      }
    })
  }

  const handleFavoriteChange = (filterId) => {
    // event capture
    if (!favoriteFilters.includes(filterId)) {
      handleTrackCustomEvent(
        FIREFLY_EVENTS.EVENT_TYPE.FILTER_EDITED,
        FIREFLY_EVENTS.CUSTOM_INTERACTION_KEYS.FAVORITE,
        filterId,
      )
    }
    const updatedFavorites = favoriteFilters.includes(filterId)
      ? favoriteFilters.filter((id) => id !== filterId)
      : [...favoriteFilters, filterId]

    setFavoriteFilters(updatedFavorites)
    try {
      setUserProfileProperty({
        variables: {
          userProfileProperty: [
            {
              parentKey: 'Search',
              propertyKey: 'favoriteFilterIds',
              value: updatedFavorites,
            },
          ],
        },
        refetchQueries: [{ query: getUserProfileFromGql() }],
      }).then((r) => {
        makeToast({
          type: 'success',
          heading: 'Success',
          message: 'Updated successfully!',
        })
      })
    } catch (e) {
      makeToast({
        type: 'error',
        heading: 'Error',
        message: 'Error in deleting',
      })
    }
  }

  const filteredFilters = searchFilter?.filter((filter) =>
    filter.displayName.toLowerCase().includes(searchFilterQuery.toLowerCase()),
  )
  const favoriteFilteredFilters = filteredFilters
    ?.filter((filter) => favoriteFilters.includes(filter.id))
    .sort((a, b) => (a.isPopular === b.isPopular ? 0 : a.isPopular ? -1 : 1)) //sorting based on popularity

  const nonFavoriteFilteredFilters = filteredFilters
    ?.filter((filter) => !favoriteFilters.includes(filter.id))
    .sort((a, b) => (a.isPopular === b.isPopular ? 0 : a.isPopular ? -1 : 1))

  return (
    <Card corners="none" elevation="0" className="tab-card">
      {loading && <Spinner />}
      <Grid.Item className="card-section hc-pt-none show-scroll">
        <Input.Text
          id="find-filter"
          placeholder="Find Filter"
          onChange={(event) => {
            setSearchFilterQuery(event.target.value)
          }}
        />
        {favoriteFilteredFilters?.length > 0 && (
          <>
            <p className="hc-mt-dense hc-mb-min  hc-clr-grey01 filter-title">
              My Favorite Filters (
              {formatToTwoDigits(favoriteFilteredFilters.length)})
            </p>
            <FilterList
              filters={favoriteFilteredFilters}
              handleFilterChange={handleFilterChange}
              handleFavoriteChange={handleFavoriteChange}
              favoriteFilters={favoriteFilters}
              isFavoriteSection={true}
              selectedValues={selectedValues}
              setSelectedValues={setSelectedValues}
            />
          </>
        )}
        <Divider />
        {nonFavoriteFilteredFilters?.length > 0 && (
          <>
            <p className="hc-mt-dense hc-mb-min hc-clr-grey01 filter-title">
              All Filters (
              {formatToTwoDigits(nonFavoriteFilteredFilters?.length)})
            </p>
            <FilterList
              filters={nonFavoriteFilteredFilters}
              handleFilterChange={handleFilterChange}
              handleFavoriteChange={handleFavoriteChange}
              favoriteFilters={favoriteFilters}
              isFavoriteSection={false}
              selectedValues={selectedValues}
              setSelectedValues={setSelectedValues}
            />
          </>
        )}
      </Grid.Item>
      <div className="hc-mr-xs">
        <Button
          type="primary"
          size="normal"
          fullWidth={true}
          onClick={(e) => {
            handleApply(e)
          }}
          disabled={isEmpty(filters)}
          className="hc-ta-right"
        >
          Apply Filter
        </Button>
      </div>
    </Card>
  )
}
