import { useState, useEffect, useCallback } from 'react'
import { useMutation } from '@apollo/client'
import Sortable from 'sortablejs'
import {
  setSavedSearchDashboardOrderMutation,
  getUserSavedSearchesDashboardQuery,
} from '../../../apiItemGraphql/userSearch'

const useSavedSearchDashboard = (savedSearches, editSearch) => {
  const [dashboardList, setDashboardList] = useState([])
  const [setOrder] = useMutation(setSavedSearchDashboardOrderMutation())

  useEffect(() => {
    if (savedSearches) {
      const filteredSearches = savedSearches.filter(
        (search) => search.showInDashboard,
      )
      setDashboardList(filteredSearches)
    }
  }, [savedSearches])

  const updateOrderInDatabase = useCallback(
    (list) => {
      const savedSearchOrders = list.map((item, index) => ({
        id: item.id,
        order: index,
      }))
      setOrder({
        variables: { orders: savedSearchOrders },
      })
    },
    [setOrder],
  )

  useEffect(() => {
    const el = document.getElementById('saved-search-lists')
    const sortable = Sortable.create(el, {
      handle: '.my-handle',
      easing: 'cubic-bezier(1, 0, 0, 1)',
      dragClass: 'sortable-drag-attribute',
      ghostClass: 'blue-background-class',
      onEnd: function (evt) {
        const { oldIndex, newIndex } = evt
        const currentList = [...dashboardList]
        const [movedItem] = currentList.splice(oldIndex, 1)
        currentList.splice(newIndex, 0, movedItem)
        setDashboardList(currentList)
        updateOrderInDatabase(currentList)
      },
    })

    return () => sortable.destroy()
  }, [dashboardList, updateOrderInDatabase])

  const removeSavedSearch = (removeItem) => {
    editSearch({
      variables: {
        id: removeItem,
        showInDashboard: false,
      },
      refetchQueries: [
        {
          query: getUserSavedSearchesDashboardQuery(),
          variables: { orderInput: true },
        },
      ],
    })
  }

  return {
    dashboardList,
    removeSavedSearch,
  }
}

export default useSavedSearchDashboard
