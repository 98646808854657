import { saveAs } from 'file-saver'
import { isArray } from 'lodash'
export const checkUser = (session) => {
  let memberOf,
    user,
    companyName,
    isUser = true
  if (session) {
    const { business_category } = session?.identity
    memberOf = session?.identity?.memberof
    companyName = session?.identity?.company
    isUser = business_category === 'E'
  }
  user = { memberOf: memberOf, isUser: isUser, companyName: companyName }
  return user
}
export const isTarget = (url) => {
  url = url ? url : window.location.href
  // return /(localhost|^([^/]+:)?\/{2,3}[^/]+?(\.target\.com)(:|\/|$))/i.test(url)
  return /(^([^/]+:)?\/{2,3}[^/]+?(\.target\.com)(:|\/|$))/i.test(url)
}
export const isProd = (url) => {
  url = url ? url : window.location.href
  return /https?:\/\/(vm(\.(prod))?)\.(target|partnersonline)\.com/.test(url)
}
// HACK: remove __typename from object
// This can be useful when you attempt to use a GQL output as an input
// GQL will fail if you pass in an explicit __typename which does not match
// https://github.com/apollographql/apollo-client/issues/1564#issuecomment-357492659
export function omitTypenameDeep(obj) {
  return JSON.parse(JSON.stringify(obj), (key, value) =>
    key === '__typename' ? undefined : value,
  )
}

export const formatToTwoDigits = (num) => {
  return num ? num.toString().padStart(2, '0') : ''
}
export const parseJwt = (token) => {
  try {
    return JSON.parse(atob(token.split('.')[1]))
  } catch (e) {
    return null
  }
}

export function downloadExcelFileFromBinary(fileName, fileBinary) {
  const binaryFile = atob(fileBinary)
  const len = binaryFile.length
  const buffer = new ArrayBuffer(len)
  const readyToBlob = new Uint8Array(buffer)
  for (let i = 0; i < len; i++) {
    readyToBlob[i] = binaryFile.charCodeAt(i)
  }

  const excelBlob = new Blob([readyToBlob], {
    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  })
  saveAs(excelBlob, `${fileName}.xlsx`)
}

export const getUserInfo = (state) => {
  const { userProfile } = state
  return parseJwt(userProfile?.token)
}

export const userIsInSomeRole = (roles, checkRoleArray) => {
  /* Example
    const rolesArray = ['ADMIN', 'SUPPORT', 'MAINTAINER'];
    const checkRoleArray = ['ADMIN', 'MAINTAINER'];
    console.log(userIsInSomeRole(rolesArray, checkRoleArray)) */
  if (isArray(roles)) {
    return checkRoleArray.some((arrRole) => roles.includes(arrRole))
  } else {
    /* Example
    const singleRole = 'SUPPORT';
    console.log(userIsInSomeRole(singleRole, checkRoleArray)) */
    return checkRoleArray.includes(roles)
  }
}

export const localStorageData = {
  set(key, value) {
    localStorage.setItem(key, value)
  },
  get(key) {
    const stored = localStorage.getItem(key)
    return stored == null ? undefined : JSON.parse(stored)
  },
  remove(key, value) {
    localStorage.removeItem(key)
  },
}

export const truncateString = (str, maxLength) => {
  if (str.length > maxLength) {
    return str.substring(0, maxLength - 3) + '...'
  }
  return str
}
