export const stickerConstants = [
  {
    name: 'data_ready_status',
    label: 'Data Ready Status',
    content: 'Data Ready Status Content',
    blocker: 'base',
  },
  {
    name: 'order_blockers',
    label: 'Order Blockers',
    content: 'Order Blockers Content',
    blocker: 'rfo',
  },
  {
    name: 'launch_blockers',
    label: 'Launch Blockers',
    content: 'Launch Blockers Content',
    blocker: 'rfl',
  },
]
