import { Layout, Grid } from '@enterprise-ui/canvas-ui-react'
import '../../globalStyles/dashboard.scss'
import { WhatNew } from './components/WhatNew'
import { QuickNavigation } from './components/QuickNavigation'
import { NeedHelp } from './components/NeedHelp'
import { MyDashboard } from './components/MyDashboard'
import { Welcome } from './components/Welcome'
import { Helmet } from 'react-helmet'

export const DashboardPage = () => {
  return (
    <Layout.Body
      data-testid="dashboard-page"
      includeRail
      className="layout-body-section"
    >
      <Helmet defaultTitle="LaunchPad: Home" />
      <Grid.Container>
        <Grid.Item xs={8} className="display-grid welcome-section">
          <Welcome />
          <MyDashboard />
        </Grid.Item>
        <Grid.Item xs={4}>
          <WhatNew />
        </Grid.Item>
      </Grid.Container>
      <Grid.Container>
        <Grid.Item xs={8} className="display-grid">
          <QuickNavigation />
        </Grid.Item>
        <Grid.Item xs={4}>
          <NeedHelp />
        </Grid.Item>
      </Grid.Container>
    </Layout.Body>
  )
}
