import { some } from 'lodash'
import { useEffect, useState } from 'react'
import moment from 'moment'
import { useMutation, useLazyQuery } from '@apollo/client'
import { useSearchParams } from 'react-router-dom'

import {
  fetchExcelFromGql,
  getMultiSheetBulkExport,
} from '../../../apiItemGraphql/userSearch'
import {
  downloadExcelFileFromBinary,
  omitTypenameDeep,
} from '../../../globalUtils/commonUtills'
import { useToaster } from '@enterprise-ui/canvas-ui-react'
import { generateExportExcelMuLtiSheet } from '../../../globalUtils/ExcelJS'
import { useSelector } from 'react-redux'
import { useFirefly } from '../../../globalUtils/useAnalytics'
import { FIREFLY_EVENTS } from '../../../constants/search'

export const useGridDownload = () => {
  const { handleTrackCustomEvent } = useFirefly()
  const [filtersVariables, setFiltersVariables] = useState({})
  const [searchParams] = useSearchParams()

  const makeToast = useToaster()
  // const [downloadVariables, setDownloadVariables] = useState({})
  const [downloadMta, { loading: downloadMtaInProgress }] = useMutation(
    getMultiSheetBulkExport(),
  )
  const { filters, savedSearch } = useSelector(
    (state) => state.userSearchProfile,
  )
  useEffect(() => {
    const tcins = searchParams.get('tcin')
    const tcinArray = tcins ? tcins.split(',') : []
    const searchText = tcinArray.length
      ? tcinArray.join(', ')
      : filters.searchText
    const allFilters = [
      ...filters.stickerTypeId,
      ...savedSearch?.activeSavedFilters,
    ]
    const variables = {
      filters: allFilters,
      searchText: searchText,
      pageSize: 100,
    }
    setFiltersVariables(variables)
  }, [filters, savedSearch?.activeSavedFilters, searchParams])
  // const [fetchDownload, { loading, data }] = useLazyQuery(fetchExcelFromGql(), {
  //   variables: variables,
  // })
  const [runQuery, { loading: downloadInProgress }] =
    useLazyQuery(fetchExcelFromGql())

  const handleDownload = (e, visibleColumns) => {
    const defaultColumns = [
      'tcin',
      'dpci',
      'primaryBarcode',
      'vendorDescription',
      'productTitle',
      'launchPadCreatedTimestamp',
      'launchDate',
      'commitmentDate',
      'assortmentPlanName',
      'itemTypeName',
      'primaryVendorId',
      'primaryVendorName',
      'itemDataQualityIssues',
      'vendorDetails',
      'isMMBItem',
    ]
    const variables = omitTypenameDeep({
      filters: filtersVariables.filters,
      attributes: visibleColumns
        ? visibleColumns.filter((id) => id)
        : defaultColumns,
      searchText: filtersVariables.searchText,
    })
    runQuery({ variables: variables }).then((result) => {
      const { downloadExcel } = result.data
      const { error, file } = downloadExcel
      if (some(error) || !file) {
        makeToast({
          type: 'error',
          heading: 'Error',
          message:
            'There was an error downloading your Excel. Please try again.',
        })
      } else {
        // event capture
        handleTrackCustomEvent(
          FIREFLY_EVENTS.EVENT_TYPE.GRID_ACTION,
          FIREFLY_EVENTS.CUSTOM_INTERACTION_KEYS.EXCEL,
          FIREFLY_EVENTS.CUSTOM_INTERACTION_VALUES.WITHOUT_MTA,
        )
        downloadExcelFileFromBinary('launchpad_search_results', file)
      }
    })
  }

  const handleMTADownload = (e, selectedTcins) => {
    let { searchText } = filtersVariables
    const appliedFilters = filtersVariables.filters
    let updatedFilter = []
    if (selectedTcins) {
      searchText = selectedTcins
    }
    if (appliedFilters && selectedTcins === '') {
      updatedFilter = appliedFilters.filter(
        (selectedFilter) => selectedFilter.id !== 'sticker',
      )
      // Todo need to handle this in GQL
    }
    try {
      downloadMta({
        variables: {
          purpose: '',
          itemTypeId: '',
          itemTypeLabel: '',
          filters: updatedFilter,
          searchText: searchText,
        },
      }).then(async (result) => {
        const { ExcelExportResponse, errors } =
          result?.data?.getMultiSheetBulkExportData
        const fileName = `Item_Export_${moment().unix()}`
        if (!!ExcelExportResponse && !errors) {
          const downloaded = await generateExportExcelMuLtiSheet(
            ExcelExportResponse,
            fileName,
            '',
          )
          if (downloaded === 'success') {
            makeToast({
              type: 'success',
              heading: 'Success',
              message: 'File processed! Ready to Download.',
            })
            // event capture
            handleTrackCustomEvent(
              FIREFLY_EVENTS.EVENT_TYPE.GRID_ACTION,
              FIREFLY_EVENTS.CUSTOM_INTERACTION_KEYS.EXCEL,
              FIREFLY_EVENTS.CUSTOM_INTERACTION_VALUES.WITH_MTA,
            )
          } else if (downloaded === 'failed') {
            makeToast({
              type: 'error',
              heading: 'Error',
              message:
                "File processing failed! Click on 'Download Excel File' again.",
            })
          }
        }
      })
    } catch (e) {
      makeToast({
        type: 'error',
        heading: 'Error',
        message:
          "File processing failed! Click on 'Download Excel File' again.",
      })
    }
  }

  return {
    handleDownload,
    handleMTADownload,
    downloadInProgress,
    downloadMtaInProgress,
  }
}
