import { useSelector } from 'react-redux'
import SingleFilterComponent from '../resuableFilters/singleFilterComponent'

export const VendorFilter = (filter) => {
  const { id, name, onFilterChange, isFav, selectedValue, setSelectedValues } =
    filter
  const getDepartmentId = useSelector((state) => {
    return state.userSearchProfile?.searchDependentFilter?.value
  })

  return (
    <SingleFilterComponent
      filterId={id}
      attributeId={id}
      isFav={isFav}
      dependentValue={getDepartmentId}
      dependentAttributeId="department"
      label={name}
      selectedValue={selectedValue}
      setSelectedValues={setSelectedValues}
      onFilterChange={onFilterChange}
    />
  )
}
