import MultipleFilterComponent from '../resuableFilters/mulitpleFilterComponenet'

export const PyramidDivisionFilter = ({
  id,
  onFilterChange,
  isFav,
  selectedValue,
  setSelectedValues,
}) => {
  return (
    <MultipleFilterComponent
      filterId={id}
      attributeId="pyramid"
      attributeKey="pyramidId"
      dependentAttributeId="division"
      dependentAttributeKey="divisionId"
      label="Pyramid"
      isFav={isFav}
      dependentLabel="Division"
      onFilterChange={onFilterChange}
      selectedValue={selectedValue}
      setSelectedValues={setSelectedValues}
    />
  )
}
