import { Card, Heading, Input, Tooltip } from '@enterprise-ui/canvas-ui-react'
import EnterpriseIcon, {
  InfoIcon,
  SearchIcon,
  CancelIcon,
} from '@enterprise-ui/icons'
import constants from '../../../../constants/message-constants'
import { useQuickSearch } from './useQuickSearch'

export const QuickSearch = () => {
  const { handleInputChange, handleKeyDown, inputText, handleInputClear } =
    useQuickSearch()
  return (
    <Card elevation="0" className="hc-pa-dense quick-search">
      <Heading size={4} className="hc-fs-sm hc-mb-dense display-inline-block ">
        Quick Search
      </Heading>
      <Tooltip
        content={constants.QUICK_SEARCH.TOOLTIP_CONTENT}
        location="right"
        className="hc-clr-grey01 icon-position"
      >
        <EnterpriseIcon icon={InfoIcon} size="sm" />
      </Tooltip>
      <Input.Text
        id="QuickSearch"
        aria-label="search available icons"
        placeholder={constants.QUICK_SEARCH.INPUT}
        className="hc-pl-xl hc-mh-none"
        value={inputText}
        onChange={(e) => {
          handleInputChange(e)
        }}
        onKeyPress={(e) => {
          handleKeyDown(e)
        }}
      />
      <div className="search-icon">
        <EnterpriseIcon icon={SearchIcon} size="sm" />
      </div>
      {inputText.length > 0 && (
        <div
          className="cancel-icon"
          onClick={handleInputClear}
          aria-label="Clear search text"
          role="button"
          tabIndex="0"
        >
          <EnterpriseIcon icon={CancelIcon} size="sm" />
        </div>
      )}
    </Card>
  )
}
