import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

import { Card, Layout, Heading, Spinner } from '@enterprise-ui/canvas-ui-react'
import icon from '../../globalImagse/favicon.svg?url'

export const AuthLogin = () => {
  const navigate = useNavigate()
  useEffect(() => {
    const loginPath = '/auth/login'
    const pathname = localStorage.getItem('pathname')
    localStorage.removeItem('pathname')
    let path = '/'
    if (pathname && pathname !== loginPath) {
      path = pathname
    }
    navigate(path)
  }, [navigate])
  return (
    <Layout data-testid="loadingAuthProfile" fullWidth>
      <Layout.Body includeRail>
        <Card
          className="hc-pa-normal hc-ta-center center"
          style={{ minHeight: '90vh' }}
        >
          <div className="progress-wrapper">
            <Spinner size="expanded" className="lp-spinner" />
            <img src={icon} alt="loading..." />
          </div>
          <Heading className="hc-pl-normal hc-pb-normal">
            Beaming profile data from orbit...
          </Heading>
        </Card>
      </Layout.Body>
    </Layout>
  )
}
