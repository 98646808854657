import { useEffect, useState } from 'react'
import { isArray, isEmpty } from 'lodash'
import { useQuery } from '@apollo/client'
import { Grid, Modal, Table, useToaster } from '@enterprise-ui/canvas-ui-react'
import { SEARCH_INFO_TABLE } from '../../../../apiItemGraphql/searchInfoTable'
import DownloadInfoTable from './DownloadInfoTable'
import INFO_TABLE_LOOKUP_ERROR from '../../../../../src/constants/message-constants'

const InfoTableModal = (props) => {
  const toaster = useToaster()
  const { visible, onClose, tcin, title, dpci, productTitle, attributeId } =
    props

  const [headings, setHeadings] = useState([])
  const [rows, setRows] = useState([])

  const { data: searchInfoData, error } = useQuery(SEARCH_INFO_TABLE, {
    variables: {
      attributeId,
      tcins: [tcin],
    },
  })

  const transformHeadings = (headings) => {
    return headings.map((heading, index) => {
      return {
        field: heading.key,
        headerName: heading.displayName,
        isRowHeader: index === 1,
      }
    })
  }

  const transformRows = (rows) => {
    return rows.map((row) => {
      let newRow = {}
      for (let key in row) {
        if (isArray(row[key])) {
          newRow[key] = row[key].join(', ')
        } else {
          newRow[key] = row[key]
        }
      }
      return newRow
    })
  }

  useEffect(() => {
    if (searchInfoData?.searchInfoTableLookup) {
      setHeadings(
        transformHeadings(searchInfoData.searchInfoTableLookup.headings),
      )
      setRows(
        transformRows(searchInfoData.searchInfoTableLookup.rowsByTcin[tcin]),
      )
    }
    if (error) {
      toaster({
        type: 'error',
        heading: 'Error',
        message: INFO_TABLE_LOOKUP_ERROR,
      })
    }
  }, [tcin, attributeId, error, toaster, searchInfoData])

  return (
    <Modal
      isVisible={visible}
      headingText={title}
      onRefuse={onClose}
      size="expanded"
      data-testid="info-table-modal"
    >
      <Grid.Container className="info-table-content">
        <Grid.Item className="hc-pa-md">
          <Grid.Container className="hc-pl-xs" justify="space-between">
            <Grid.Item>
              {attributeId !== 'itemDataQualityIssues' && (
                <div>
                  <span className="hc-clr-grey01">Product Title: </span>
                  {productTitle}
                </div>
              )}
              <div className="hc-mt-xs">
                <span className="hc-clr-grey01">TCIN: </span>
                {tcin}
                <span className="hc-clr-grey01 hc-ml-3x">DPCI: </span>
                {dpci}
              </div>
            </Grid.Item>
            <Grid.Item>
              <DownloadInfoTable
                title={title}
                tcins={[tcin]}
                attributeId={attributeId}
              ></DownloadInfoTable>
            </Grid.Item>
          </Grid.Container>
        </Grid.Item>
        {!isEmpty(headings) && !isEmpty(rows) && (
          <Grid.Item xs={12} className="info-table hc-pt-none show-scrol">
            <Table
              data={{
                columnDefs: headings,
                rowData: rows,
              }}
              name="Info Table"
              showGrid={false}
              className="hc-pa-dense"
              cellPadding="dense"
              data-testid="info-table"
            />
          </Grid.Item>
        )}
      </Grid.Container>
    </Modal>
  )
}

export default InfoTableModal
