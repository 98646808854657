import gql from 'graphql-tag'

export const getReleaseNotesContentQuery = () => gql`
  query getReleaseNotesContent($releaseId: String) {
    getReleaseNotesContent(releaseId: $releaseId) {
      results {
        title
        releaseContent
        releaseDate
      }
      total
      errors
    }
  }
`
