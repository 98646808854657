export const TABLE_HEADERS = {
  savedSearch: 'Saved Search',
  notReadyForOrder: 'Not Ready for Order',
  notReadyForLaunch: 'Not Ready for Launch',
  readyForLaunch: 'Ready for Launch',
  total: 'Total',
  action: 'Action', // Even if not visible, maintaining the header for consistency
}

export const DASHBOARD_TEXT = {
  title: 'My Dashboard',
  description:
    'Dashboard features saved searches enabling you to revisit frequently monitored set of items. You can save search queries and applied filters by selecting “Save as New” on the Search page. Saved searches can be added and ordered on the dashboard to monitor the progress of item setup.',
  buttonLabel: 'Add a Saved Search',
  modalTitle: 'Add Saved Search to Dashboard',
  noSearchesText: 'You do not have any saved searches yet, you can visit',
  noSearchesText1:
    'page to save. You can save search queries and applied filters by clicking on “Save as New” on the Search page.',
  confirmButtonLabel: 'Confirm',
  appliedLabel: 'Applied',
}
