export const calculatePercentage = (rflTotal, itemTotal) => {
  let rawPercentage = (rflTotal / itemTotal) * 100
  let blockerPercentage = Math.round(rawPercentage)
  if (blockerPercentage === 0 && rflTotal !== 0) {
    blockerPercentage = rawPercentage.toFixed(2)
  } else if (blockerPercentage === 100 && rflTotal !== itemTotal) {
    blockerPercentage = rawPercentage.toFixed(2)
  } else if (rflTotal === 0 && itemTotal === 0) {
    blockerPercentage = 0
  }
  //Displays '-' while calculation is working instead of NAN
  if (isNaN(blockerPercentage)) {
    blockerPercentage = '-'
  } else {
    blockerPercentage += '%'
  }
  return blockerPercentage
}
