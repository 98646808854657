const constants = {
  SEARCH_ACTION_DELETE_NETWORK_ERROR_UNAUTHORIZED:
    'You do not have permission to perform this operation. Try logging out or contact support if the issue persists.',
  SEARCH_ACTION_DELETE_SUCCESS:
    'The deletion of your items has been requested and may take up to 20 minutes to be processed and reflected in LaunchPad.',
  SEARCH_ACTION_DEFAULT_ERROR:
    'Deletion request failed. Please try again later.',
  MMB_CROSSOVER_SUCCESS: 'Crossover Item successfully',
  MMB_CROSSOVER_FAILED: 'There was an error creating the crossover.',
  MMB_UNPUBLISH_SUCCESS: 'MMB Un/Publish Created',
  MMB_UNPUBLISH_ERROR: 'There was an error creating the Publish/UnPublish.',
  QUICK_SEARCH: {
    TOOLTIP_CONTENT:
      'Search using DPCIs, TCINs, Barcodes,Manufacturer Style Numbers, or Product Titles. **DPCI formatting must use hyphens.',
    INPUT: 'Search by TCIN, DPCI, Product Title etc..',
    INFO_TABLE_LOOKUP_ERROR:
      'There was an error in fetching the details. Please try again.',
    DOWNLOAD_INFO_TABLE_ERROR:
      'There was an error downloading your Excel. Please try again.',
  },
}

export default constants
