import { createSlice } from '@reduxjs/toolkit'

export const userProfileSlice = createSlice({
  name: 'userProfile',
  initialState: {
    token: '',
  },
  reducers: {
    setUserProfile: (state, action) => {
      return {
        ...state,
        token: action.payload,
      }
    },
  },
})

export const { setUserProfile } = userProfileSlice.actions

export default userProfileSlice.reducer

export const getUserSessionToken = (state) => {
  return state.userProfile.token
}
