import { useState } from 'react'

export const useSearchPage = () => {
  const [isCollapsed, setIsCollapsed] = useState(false)

  return {
    setIsCollapsed,
    isCollapsed,
  }
}
