import { Card, Placeholder, Grid } from '@enterprise-ui/canvas-ui-react'

export const WhatNewPlaceholder = () => {
  return (
    <Placeholder>
      <Grid.Container>
        {[0, 1].map((index) => {
          return (
            <Grid.Item md={12} xs={12} key={index}>
              <Card
                className="hc-pa-md bg-default-contrast-weak font-color-default"
                padding="dense"
              >
                <Grid.Container>
                  <Grid.Item md={12}>
                    <Placeholder.Text emphasized />
                    <Placeholder.Text emphasized />
                  </Grid.Item>
                </Grid.Container>
                <Placeholder.Rect height="200px" />
                <Placeholder.Text emphasized />
              </Card>
            </Grid.Item>
          )
        })}
        <Grid.Item md={12} xs={12}>
          <Placeholder.Text height="50px" />
        </Grid.Item>
      </Grid.Container>
    </Placeholder>
  )
}
