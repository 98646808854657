import { Button, Tooltip, useToaster } from '@enterprise-ui/canvas-ui-react'
import EnterpriseIcon, { ImagesIcon } from '@enterprise-ui/icons'
import apiConfig from '../../../globalConfig/apiConfig'

const RedirectToPipeline = ({ selectedTcins }) => {
  const toaster = useToaster()
  const redirectToPipelineDetails = async () => {
    try {
      const PIPELINE_DETAIL_URL = `${apiConfig.pipeline.baseUrl}${apiConfig.pipeline.detailsPath}`
      const url = `${PIPELINE_DETAIL_URL}${selectedTcins}`
      window.open(url, '_blank', 'noopener, noreferrer')
    } catch (error) {
      toaster({
        type: 'error',
        heading: 'Error Redirecting to Pipeline',
        message: error,
      })
    }
  }

  return (
    <>
      <Tooltip content="Manage Images" location="top">
        <Button
          type="ghost"
          onClick={redirectToPipelineDetails}
          className="button-bg-hover-transparent"
          disabled={selectedTcins.length === 0}
        >
          <EnterpriseIcon
            icon={ImagesIcon}
            size="sm"
            className="hc-clr-contrast-weak"
          />
        </Button>
      </Tooltip>
    </>
  )
}

export default RedirectToPipeline
