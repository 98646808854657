import { createAction } from '@reduxjs/toolkit'

// Action types
export const fetchBeginAction = '@@fetch/initiated'
export const fetchErrorAction = '@@fetch/rejected'
export const fetchSuccessAction = '@@fetch/resolved'

// Action creators
export const fetchBegin = createAction(fetchBeginAction, (id, input, init) => ({
  payload: { id, input, init },
}))

export const fetchError = createAction(fetchErrorAction, (id, error) => ({
  payload: { id, error },
}))

export const fetchSuccess = createAction(
  fetchSuccessAction,
  (id, durationInMs, res) => ({
    payload: { id, durationInMs, res },
  }),
)
