import { filter, isArray } from 'lodash'
import {
  DQ_COLUMNS,
  EXTERNAL_COLUMNS,
  INTERNAL_COLUMNS,
  searchColumns,
} from '../views/searchGrid/searchConstants'

export const filterWithMultipleMatches = (collections, filterObj) => {
  /* Example
    const collection = [
        {"tcin": "51945064", "isMaintainable": true, "isDeletable": true},
        {"tcin": "52231985", "isMaintainable": true, "isDeletable": true},
        {"tcin": "52764237","isMaintainable": true,"isDeletable": false}
    ]
    const filterObj = {isMaintainable: true, isDeletable: false}
    filter(collection, filterObj) ---> Result -> [{"tcin": "52764237","isMaintainable": true,"isDeletable": false}]
    */
  if (isArray(collections)) {
    return filter(collections, filterObj)
  }
}

// Filter TCIN's only from the collections, Result --> ["52764237", "51945064", "52231985"]
export const filterTCINs = (collections) =>
  collections?.map((item) => item.tcin)

export const mapFiltersToArray = (filters) => {
  const appliedFilters = filters?.applied
  const resultArray = []
  if (appliedFilters) {
    for (const key in appliedFilters) {
      if (appliedFilters[key]) {
        const filterObject = {
          id: key,
          values: appliedFilters[key].values.map(({ value, displayValue }) => ({
            value,
            displayValue,
          })),
        }
        resultArray.push(filterObject)
      }
    }
  }

  return resultArray
}

/**
 * to convert an array of objects
 * @param {Array} array
 * @param {string} key
 * @returns {Object}
 */

export const arrayToObject = (array, key) =>
  array.reduce((acc, curr) => {
    acc[curr[key]] = curr
    return acc
  }, {})

/**
 * to convert our exisiting AttributeSchemaValue to canvas option format
 * @param {options} array // Options array with object of type AttributeSchemaValue
 */

export const formatOptions = (options) => {
  return options.map((option) => ({
    value: option.value,
    id: JSON.stringify(option.value),
    label: option.displayValue,
  }))
}

/**
 * to convert object to array
 * @param {Object} filterObject
 * @returns {Object}
 */

export const convertFiltersToArray = (filterObject) => {
  if (!filterObject || typeof filterObject !== 'object') {
    return []
  }

  return Object.keys(filterObject).map((key) => filterObject[key])
}

// Detect the Partneronline | External or Internal users
export const isPartnerOnline = () => {
  const url = window.location.href
  return /(^([^/]+:)?\/{2,3}[^/]+?(\.partnersonline\.com)(:|\/|$))/i.test(url)
}
const isItemHubDomainExternal = () => {
  const regex =
    /^https:\/\/itemhub\.(?:[a-zA-Z]+\.)?partnersonline\.com(?:\/.*)?$/i // Itemhub External environment - Partners online
  return regex.test(window.location.href)
}
export const LoginRedirectPath = () => {
  let url = `${window.location.origin}/auth/login`
  if (isItemHubDomainExternal()) {
    url = `${window.location.origin}/${window.location.pathname.split('/')[1]}/auth/login`
  }
  return url
}

export const getUserColumns = (userType, roles) => {
  let displayColumns = userType === 'I' ? INTERNAL_COLUMNS : EXTERNAL_COLUMNS
  if (userType === 'I' && roles.includes('VALIDATOR')) {
    displayColumns = DQ_COLUMNS
  }
  const displaySet = new Set(displayColumns)
  return searchColumns.filter((item) => displaySet.has(item.id))
}
export const typeMapper = {
  vendor: 'string',
  productBrand: 'number',
}
export const mapValueTypeWithId = (id, value) => {
  const type = typeMapper[id]

  switch (type) {
    case 'string':
      return String(value)
    case 'number':
      return Number(value)
    default:
      return value
  }
}

export function writeFiltersToLocalStorage(key, obj) {
  const data = {
    filters: {
      ...obj?.filters,
      searchText: obj?.searchText,
    },
    savedSearch: {
      activeSavedSearchId: obj?.savedSearch?.activeSavedSearchId,
    },
  }
  localStorage.setItem(key, JSON.stringify(data))
}
export function readJsonFromLocalStorage(key) {
  const localValue = localStorage.getItem(key)
  return localValue ? JSON.parse(localValue) : ''
}
