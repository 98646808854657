import { Input } from '@enterprise-ui/canvas-ui-react'
import useHeaderCheckbox from './useHeaderCheckbox'

const HeaderCheckbox = (props) => {
  const { isHeaderChecked, isDisabled, handleCheckboxChange } =
    useHeaderCheckbox(props.api)

  return (
    <Input.Checkbox
      type="checkbox"
      checked={isHeaderChecked}
      onChange={handleCheckboxChange}
      disabled={isDisabled}
    />
  )
}

export default HeaderCheckbox
