import { intersection } from 'lodash'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useQuery } from '@apollo/client'
import { userSessionTokenQuery } from '../apiItemGraphql/userSession'
import { bannerQuery } from '../apiItemGraphql/banner'
import { setUserProfile } from '../store/userProfile'
import { setBanner } from '../store/bannerHeader'
import { getUserInfo } from '../globalUtils/commonUtills'
import { mainMenus } from '../constants/menu'

export const useMain = () => {
  const dispatch = useDispatch()
  const { loading, error, data } = useQuery(userSessionTokenQuery())
  const getUserProfile = useSelector((state) => {
    return getUserInfo(state)
  })
  useEffect(() => {
    dispatch(setUserProfile(data?.userSessionToken?.token))
  }, [data, dispatch])
  const { data: bannerData } = useQuery(bannerQuery())
  useEffect(() => {
    dispatch(setBanner(bannerData?.getBannerDetail?.bannerDetails))
  }, [bannerData, dispatch])

  const bannerHeader = useSelector((state) => state.bannerHeader)

  const hasRole = (userRoles, allowedRoles) => {
    return intersection(userRoles, allowedRoles).length > 0
  }
  const constructedMenu = (allowedRoles) => {
    let menus = mainMenus.filter((feature) => feature.enabled === true)
    if (getUserProfile) {
      const filteredMenu = mainMenus.map((feature) => {
        const canViewMenu =
          feature.enabled === true ||
          hasRole(getUserProfile.roles, feature?.enabled)
        return canViewMenu ? feature : null
      })
      menus = filteredMenu.filter((n) => n)
    }
    return menus
  }

  return {
    loading,
    error,
    data,
    bannerHeader,
    constructedMenu,
    getUserProfile,
  }
}
