import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { Card, Layout, Heading } from '@enterprise-ui/canvas-ui-react'
import EnterpriseIcon, { ErrorIcon } from '@enterprise-ui/icons'

export const PageNotFound = () => {
  const navigate = useNavigate()
  useEffect(() => {
    navigate('/') // Todo Need to remove once fastly issue fixed
  }, [navigate])
  return (
    <Layout data-testid="pageNotFound" fullWidth>
      <Layout.Body includeRail>
        <Card
          className="hc-pa-normal hc-ta-center center"
          style={{ minHeight: '90vh' }}
        >
          <div className="progress-wrapper">
            <EnterpriseIcon
              icon={ErrorIcon}
              className="hc-clr-error"
              size="xl"
            />
          </div>
          <Heading className="hc-pl-normal hc-pb-normal hc-mt-normal">
            Page Not Found.
          </Heading>
        </Card>
      </Layout.Body>
    </Layout>
  )
}
