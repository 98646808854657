import { keyBy, isEqual } from 'lodash'
import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useQuery, useMutation } from '@apollo/client'
import { useToaster } from '@enterprise-ui/canvas-ui-react'
import {
  getUserSavedSearchesQuery,
  getUserSavedSearchesCountQuery,
  DeleteSavedSearchMutation,
  setSavedSearchMutation,
} from '../../../../../apiItemGraphql/userSearch'
import {
  savedSearchInfo,
  saveActiveSearchId,
  setActiveSavedSearchId,
} from '../../../../../store/userSearchProfile'
import { omitTypenameDeep } from '../../../../../globalUtils/commonUtills'

export const useSavedFilters = () => {
  const makeToast = useToaster()
  const dispatch = useDispatch()
  const [savedSearches, setSavedSearches] = useState([])
  const [savedSearchIds, setSavedSearchIds] = useState(null)
  const [savedSearchData, setSavedSearchData] = useState([])

  const { loading, error, data } = useQuery(getUserSavedSearchesQuery())

  // Get saved searches counts
  const { data: savedSearchCnts } = useQuery(getUserSavedSearchesCountQuery(), {
    skip: !savedSearchIds, // Skip query if selectedUserId is null
    variables: {
      savedSearchIds: savedSearchIds,
    },
  })

  const [deleteSearch] = useMutation(DeleteSavedSearchMutation())
  const [editSearch] = useMutation(setSavedSearchMutation())

  useEffect(() => {
    if (!data) return
    const savedSearches = omitTypenameDeep(
      data?.getUserSavedSearches.savedSearches,
    )
    setSavedSearches(savedSearches)

    const saveSearchIds = savedSearches?.map((saveSearch) => saveSearch.id)
    setSavedSearchIds(saveSearchIds)
  }, [data, dispatch])

  useEffect(() => {
    if (!savedSearchCnts) return
    const savedSearchCounts = savedSearchCnts?.getItemCountsBySavedSearch.counts
    const mapSavedSearchCounts = keyBy(savedSearchCounts, 'id')
    const savedSearchData = savedSearches.map((savedSearch) => {
      return {
        ...savedSearch,
        ...mapSavedSearchCounts[savedSearch.id],
      }
    })
    setSavedSearchData(savedSearchData)
    dispatch(savedSearchInfo(savedSearchData))
  }, [dispatch, savedSearchCnts, savedSearches])

  const {
    activeSavedFilters,
    activeSavedSearchId,
    activeSavedFiltersUpdated,
    ...savedSearchInfoData
  } = useSelector((state) => {
    const { userSearchProfile } = state
    return {
      activeSavedFilters: userSearchProfile.savedSearch.activeSavedFilters,
      activeSavedSearchId: userSearchProfile.savedSearch.activeSavedSearchId,
      activeSavedFiltersUpdated:
        userSearchProfile.savedSearch.activeSavedFiltersUpdated,
      ...userSearchProfile.savedSearch,
    }
  }, [])

  const { searchText } = useSelector((state) => {
    const { userSearchProfile } = state
    return {
      searchText: userSearchProfile.filters.searchText,
    }
  }, [])

  const handleEdit = (savedSearches) => {
    let { name, id, searchText, viewId, filters, favorite, showInDashboard } =
      savedSearches

    const preparedFilters = filters.map((filter) => {
      return {
        id: filter.id,
        values: filter.values.map((val) => {
          return {
            value: val.value,
            displayValue: val.displayValue,
          }
        }),
      }
    })

    try {
      editSearch({
        variables: {
          filters: preparedFilters,
          name: name,
          id: `${id}`,
          favorite,
          searchText,
          showInDashboard,
          viewId,
        },
        refetchQueries: [{ query: getUserSavedSearchesQuery() }],
      }).then((res) => {
        if (id) {
          makeToast({
            type: 'success',
            heading: 'Success',
            message: 'Updated successfully!',
          })
        } else {
          const { savedSearch } = res?.data?.setSavedSearch
          const { id } = savedSearch
          // const { showInDashboard } = savedSearch
          dispatch(setActiveSavedSearchId(id))
          makeToast({
            type: 'success',
            heading: 'Success',
            message: 'Added successfully!',
          })
        }
      })
    } catch (e) {
      makeToast({
        type: 'error',
        heading: 'Error',
        message: 'Error in updating',
      })
    }
  }

  const handleDelete = (id) => {
    try {
      deleteSearch({
        variables: { id: id },
        refetchQueries: [{ query: getUserSavedSearchesQuery() }],
      }).then((r) => {
        makeToast({
          type: 'success',
          heading: 'Success',
          message: 'Deleted successfully!',
        })
      })
    } catch (e) {
      makeToast({
        type: 'error',
        heading: 'Error',
        message: 'Error in deleting',
      })
    }
  }
  const handleInfoClick = (savedSearch) => {
    dispatch(saveActiveSearchId(savedSearch.id))
  }
  const handleButtonClick = (savedSearches, type) => {
    let { id } = savedSearches
    if (type === 'edit') {
      handleEdit(savedSearches)
    } else if (type === 'delete') {
      handleDelete(id)
    } else if (type === 'add') {
      saveAppliedFilters(
        type,
        savedSearches.name,
        savedSearches.showInDashboard,
      )
    }
  }
  const activeSavedFilterModified = () => {
    const filtered =
      savedSearchInfoData &&
      savedSearchInfoData?.filter(
        (filters) => filters.id === activeSavedSearchId,
      )
    return !isEqual(filtered[0]?.filters, activeSavedFilters)
  }
  const saveAppliedFilters = (type, name, showInDashboard) => {
    let preparedFilters
    const filtered =
      savedSearchInfoData &&
      savedSearchInfoData?.savedFilters.filter(
        (filters) => filters.id === activeSavedSearchId,
      )

    const filteredData = filtered[0]
    if (type === 'update') {
      preparedFilters = {
        ...filteredData,
        filters: activeSavedFilters,
        searchText: searchText,
      }
    } else {
      preparedFilters = {
        ...filteredData,
        filters: activeSavedFilters,
        name: name,
      }
      if (type === 'add') {
        preparedFilters = {
          ...preparedFilters,
          id: '',
          searchText: searchText,
          showInDashboard,
        }
      }
    }
    handleEdit(preparedFilters)
  }

  return {
    loading,
    error,
    savedSearchData,
    savedSearchInfoData,
    activeSavedFiltersUpdated,
    activeSavedSearchId,
    handleEdit,
    handleDelete,
    handleInfoClick,
    handleButtonClick,
    activeSavedFilterModified,
    saveAppliedFilters,
  }
}
