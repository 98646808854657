import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
// import { writeFiltersToLocalStorage } from '../globalUtils/searchUtills'
// import { isEmpty } from 'lodash'

export const updateFilters = createAsyncThunk(
  'userSearch/updateFilters',
  async (newFilters, thunkApi) => {
    // Clear selected TCINs //todo
    // thunkApi.dispatch(clearSelectedTcins());
    return newFilters
  },
)

export const userSearchProfileSlice = createSlice({
  name: 'userSearch',
  initialState: {
    savedSearch: {
      savedFilters: '',
      activeSavedSearchId: '',
      activeSavedFilters: [],
      activeSavedFiltersUpdated: false,
    },
    filters: {
      searchText: '',
      stickerTypeId: [],
      allFilters: [],
    },
    searchDependentFilter: {},
    searchFilterDefinitions: [],
  },
  reducers: {
    setSearchText: (state, action) => {
      const outputObj = {
        ...state,
        filters: {
          ...state.filters,
          searchText: action.payload,
        },
        savedSearch: {
          ...state.savedSearch,
          activeSavedFiltersUpdated: true,
        },
      }
      //writeFiltersToLocalStorage('LP_SEARCH_CRITERIA', outputObj)
      return outputObj
    },
    setBlockerFilters: (state, action) => {
      const outputObj = {
        ...state,
        filters: {
          ...state.filters,
          stickerTypeId: action.payload,
        },
      }
      //writeFiltersToLocalStorage('LP_SEARCH_CRITERIA', outputObj)
      return outputObj
    },
    savedSearchInfo: (state, action) => {
      return {
        ...state,
        savedSearch: {
          ...state.savedSearch,
          savedFilters: action.payload,
        },
      }
    },
    refreshSearchFilters: (state) => {
      return {
        ...state,
        filters: {
          ...state.filters,
        },
      }
    },
    updateSearchDependentFilter: (state, { payload }) => {
      return {
        ...state,
        searchDependentFilter: payload,
      }
    },
    searchFilterDefinitions: (state, { payload }) => {
      return {
        ...state,
        searchFilterDefinitions: payload,
      }
    },
    saveActiveSearchId: (state, action) => {
      const activeId = action.payload
      const appliedFilters = state.savedSearch.savedFilters.find(
        (todo) => todo.id === activeId,
      )
      const filterObj =
        appliedFilters?.filters.length > 0 ? appliedFilters.filters : []

      const outputObj = {
        ...state,
        filters: {
          ...state.filters,
          ...state.filters.stickerTypeId,
          ...filterObj,
          searchText: appliedFilters.searchText,
        },
        savedSearch: {
          ...state.savedSearch,
          activeSavedSearchId: action.payload,
          activeSavedFilters: filterObj,
          activeSavedFiltersUpdated: false,
        },
      }
      //writeFiltersToLocalStorage('LP_SEARCH_CRITERIA', outputObj)
      return outputObj
    },
    updateSavedSearchFilters: (state, { payload }) => {
      let clearData = {}
      if (payload === 'all') {
        clearData = {
          filters: {
            searchText: '',
            stickerTypeId: [],
          },
        }
      } else {
        clearData = {
          filters: {
            ...state.filters,
            ...payload,
          },
        }
      }
      const outputObj = {
        ...state,
        ...clearData,
        savedSearch: {
          ...state.savedSearch,
          activeSavedFilters: payload === 'all' ? [] : payload,
          activeSavedFiltersUpdated: true,
        },
      }
      //writeFiltersToLocalStorage('LP_SEARCH_CRITERIA', outputObj)
      return outputObj
    },
    clearQuickSearch: (state) => {
      const outputObj = {
        ...state,
        filters: {
          ...state.filters,
          searchText: '',
        },
        savedSearch: {
          ...state.savedSearch,
          activeSavedFiltersUpdated: true,
        },
      }
      //writeFiltersToLocalStorage('LP_SEARCH_CRITERIA', outputObj)
      return outputObj
    },
    clearSticker: (state) => {
      return {
        ...state,
        filters: {
          ...state.filters,
          stickerTypeId: [],
        },
      }
    },
    setFilters: (state, { payload }) => {
      const { searchText, stickerTypeId, savedSearch, ...rest } =
        payload.filters
      const restArray = Object.values(rest)
      const activeId = payload?.savedSearch?.activeSavedSearchId

      const outputObj = {
        ...state,
        filters: {
          ...payload.filters,
          searchText: payload.filters.searchText,
          stickerTypeId: '', //payload.filters.stickerTypeId,
        },
        savedSearch: {
          ...state.savedSearch,
          activeSavedSearchId: activeId,
          activeSavedFilters: restArray,
        },
      }
      return outputObj
    },
    setActiveSavedSearchId: (state, { payload }) => {
      return {
        ...state,
        savedSearch: {
          ...state.savedSearch,
          activeSavedSearchId: payload,
          activeSavedFiltersUpdated: true,
        },
      }
    },
  },

  extraReducers: (builder) => {
    builder.addCase(updateFilters.fulfilled, (state, action) => {
      //this logic is to merge the new and existing filters
      //if a filter already exists, we concatenate only the new and non dup values to the existing values array
      //if a filter does not exist, we add it to the state
      const newFilters = action.payload

      newFilters.forEach((newFilter) => {
        const existingFilterIndex =
          state.savedSearch.activeSavedFilters?.findIndex(
            (filter) => filter.id === newFilter.id,
          )

        if (existingFilterIndex > -1) {
          const existingFilter =
            state.savedSearch.activeSavedFilters[existingFilterIndex]
          const existingValues = existingFilter?.values.map((value) =>
            JSON.stringify(value),
          )
          const newValues = newFilter?.values?.filter(
            (value) => !existingValues.includes(JSON.stringify(value)),
          )

          if (newValues.length > 0) {
            state.savedSearch.activeSavedFilters[existingFilterIndex] = {
              ...existingFilter,
              values: existingFilter?.values.concat(newValues),
            }
          }
        } else {
          state.savedSearch.activeSavedFilters?.push(newFilter)
        }
      })
      //
    })
  },
})

export const {
  setBlockerFilters,
  setSearchText,
  savedSearchInfo,
  saveActiveSearchId,
  updateSavedSearchFilters,
  clearQuickSearch,
  updateSearchDependentFilter,
  clearSticker,
  refreshSearchFilters,
  searchFilterDefinitions,
  setFilters,
  setActiveSavedSearchId,
} = userSearchProfileSlice.actions

export default userSearchProfileSlice.reducer
