import gql from 'graphql-tag'

export const GET_TCIN_COMMENTS = gql`
  query getCommentsByTcin($tcin: String) {
    getCommentsByTcin(tcin: $tcin) {
      data {
        _id
        userID
        userName
        comment
        userEmail
        comment
        timestamp
        tcin
        tags
        lastModified
        active
      }
      error
    }
  }
`

export const ADD_TCIN_COMMENT = gql`
  mutation addTcinComment(
    $tcin: String
    $comment: String
    $userId: String
    $userName: String
    $userEmail: String
  ) {
    addTcinComment(
      tcin: $tcin
      comment: $comment
      userId: $userId
      userEmail: $userEmail
      userName: $userName
    ) {
      data {
        _id
        userID
        userName
        comment
        userEmail
        comment
        timestamp
        tcin
        tags
        lastModified
        active
      }
      error
    }
  }
`

export const BATCH_ADD_TCINS_COMMENT = gql`
  mutation batchAddTcinsComment(
    $tcins: [String]
    $comment: String
    $userId: String
    $userName: String
    $userEmail: String
  ) {
    batchAddTcinsComment(
      tcins: $tcins
      comment: $comment
      userId: $userId
      userEmail: $userEmail
      userName: $userName
    ) {
      data {
        _id
        userID
        userName
        comment
        userEmail
        comment
        timestamp
        tcin
        tags
        lastModified
        active
      }
      error
    }
  }
`

export const DELETE_COMMENT = gql`
  mutation deleteComment($commentId: String) {
    deleteComment(commentId: $commentId) {
      data
      error
    }
  }
`

export const UPDATE_COMMENT = gql`
  mutation updateComment(
    $newComment: String
    $previousComment: String
    $commentId: String
  ) {
    updateComment(
      newComment: $newComment
      previousComment: $previousComment
      commentId: $commentId
    ) {
      data
      error
    }
  }
`
