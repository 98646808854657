import { useState } from 'react'
import { differenceWith, isEqual } from 'lodash'
import moment from 'moment'
import { useDispatch, useSelector } from 'react-redux'
import {
  Chip,
  Anchor,
  Badge,
  Modal,
  Grid,
} from '@enterprise-ui/canvas-ui-react'
import EnterpriseIcon, { CancelIcon } from '@enterprise-ui/icons'
import { updateSavedSearchFilters } from '../../../../store/userSearchProfile'

export const AppliedFiltersChips = (props) => {
  const { filtersValue, allFilters } = props
  const [isOpen, setIsOpen] = useState(false)
  const searchFilters = filtersValue
  const dispatch = useDispatch()

  const searchFilterDefinitions = useSelector((state) => {
    const { userSearchProfile } = state
    return userSearchProfile.searchFilterDefinitions
  }, [])
  const displayLimit = searchFilters[searchFilters.id]?.displayLimit || 2
  const lengthLimit = (searchFilters.values?.length - 2).toString() | null
  const filterDefinitions = searchFilterDefinitions.filter(
    (x) => x.id === searchFilters.id,
  )
  const filterName = filterDefinitions
    ? filterDefinitions[0]?.displayName
    : 'Unknown Filter'

  const showAll = searchFilters.values?.length <= displayLimit
  const getChipValue = (value) => {
    let chipValue = value.displayValue || String(value.value)
    const hasWeek = Object.prototype.hasOwnProperty.call(
      value.value,
      'startWeek',
    )
    if (value.displayValue && hasWeek) {
      const relativeDateValue = value.value
      chipValue = `${value.displayValue}: ${relativeDateFormattedValue(
        relativeDateValue.startWeek,
        relativeDateValue.endWeek,
      )}`
    }
    return chipValue
  }
  const relativeDateFormattedValue = (startWeek, endWeek) => {
    const startNumberOfDaysUntilTheEndOfTheDesiredWeeks = startWeek * 7 - 1
    const endNumberOfDaysUntilTheEndOfTheDesiredWeeks = endWeek * 7 - 1

    const startDate = moment()
      .endOf('isoWeek')
      .startOf('day')
      .add(startNumberOfDaysUntilTheEndOfTheDesiredWeeks, 'days')
      .day('Sunday') // Based on filter selection, add that amount of weeks to that date
    const endDate = moment()
      .endOf('isoWeek')
      .endOf('day')
      .add(endNumberOfDaysUntilTheEndOfTheDesiredWeeks, 'days')
      .day('Saturday') // Based on filter selection, add that amount of weeks to that date

    return `${startDate.format('ll')} - ${endDate.format('ll')}`
  }
  const handleDeleteFilterValues = (filter, valuesToRemove) => {
    let updatedFilters = []
    if (valuesToRemove) {
      updatedFilters = allFilters
        .map((searchFilter) => {
          const updatedFilter = { ...searchFilter }

          if (searchFilter.id === filter.id) {
            if (valuesToRemove) {
              updatedFilter.values = differenceWith(
                updatedFilter.values,
                valuesToRemove,
                isEqual,
              )
            } else {
              updatedFilter.values = differenceWith(
                updatedFilter.values,
                filter,
                isEqual,
              )
            }
          }
          return updatedFilter
        })
        .filter((updatedFilter) => updatedFilter.values?.length) // remove if filter has no more values
    } else {
      updatedFilters = allFilters.filter((obj) => obj.id !== filter.id)
    }
    dispatch(updateSavedSearchFilters(updatedFilters))
  }

  return (
    <>
      <p className="hc-fs-sm hc-clr-grey01 hc-mb-min hc-mt-dense display-flex">
        {filterName}
        <Badge
          className="hc-ml-dense"
          location="inline"
          content={searchFilters.values?.length}
          aria-label={`${searchFilters.values?.length} filters`}
        />
        {filterName && (
          <Anchor
            href="#"
            onClick={(ev) => {
              ev.preventDefault()
              handleDeleteFilterValues(searchFilters, '')
            }}
            className="hc-ta-right hc-clr-grey02 right-align"
            aria-label={`Delete filter ${filterName}`}
          >
            <EnterpriseIcon icon={CancelIcon} size="sm" />
          </Anchor>
        )}
      </p>
      {searchFilters?.values?.length > 0 && (
        <>
          {showAll
            ? searchFilters?.values?.map((value, index) => (
                <div
                  key={`${searchFilters.id}-${index}`}
                  style={{ display: 'inline-block' }}
                >
                  <Chip
                    onRequestDelete={() =>
                      handleDeleteFilterValues(searchFilters, [value])
                    }
                    className="hc-bg-grey06"
                  >
                    {getChipValue(value)}
                  </Chip>
                </div>
              ))
            : searchFilters.values
                ?.slice(0, displayLimit)
                .map((value, index) => (
                  <div
                    key={`${searchFilters.id}-${index}`}
                    style={{ display: 'inline-block' }}
                  >
                    <Chip
                      onRequestDelete={() =>
                        handleDeleteFilterValues(searchFilters, [value])
                      }
                      className="hc-bg-grey06"
                    >
                      {getChipValue(value)}
                    </Chip>
                  </div>
                ))}
        </>
      )}

      {!showAll && lengthLimit > 0 && (
        <Anchor
          href="#"
          onClick={() => setIsOpen((p) => !p)}
          style={{ display: 'block' }}
        >
          +{lengthLimit} MORE
        </Anchor>
      )}
      <Modal
        isVisible={isOpen}
        headingText={filterName}
        onRefuse={() => setIsOpen(false)}
        size="dense"
        scrollBehavior="contained"
        footer
      >
        <div className="hc-pa-normal show-scroll" style={{ maxHeight: '30vh' }}>
          <Grid.Container>
            <Grid.Item xs>
              {searchFilters?.values?.length > 0 &&
                searchFilters.values?.map((value, index) => (
                  <div
                    key={`${searchFilters.id}-${index}`}
                    style={{ display: 'inline-block' }}
                  >
                    <Chip
                      onRequestDelete={() =>
                        handleDeleteFilterValues(searchFilters, [value])
                      }
                      className="hc-bg-grey06"
                    >
                      {getChipValue(value)}
                    </Chip>
                  </div>
                ))}
            </Grid.Item>
          </Grid.Container>
        </div>
      </Modal>
    </>
  )
}
