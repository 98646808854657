import { Card, Layout, Heading, Spinner } from '@enterprise-ui/canvas-ui-react'
import icon from '../../globalImagse/favicon.svg?url'

export const LoadingProfile = () => {
  return (
    <Layout data-testid="loadingProfile" fullWidth>
      <Layout.Body includeRail>
        <Card
          className="hc-pa-normal hc-ta-center center"
          style={{ minHeight: '90vh' }}
        >
          <div className="progress-wrapper">
            <Spinner size="expanded" className="lp-spinner" />
            <img src={icon} alt="loading..." />
          </div>
          <Heading className="hc-pl-normal hc-pb-normal">
            Beaming profile data from orbit...
          </Heading>
        </Card>
      </Layout.Body>
    </Layout>
  )
}
