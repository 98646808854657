import {
  Grid,
  Modal,
  Badge,
  Input,
  Button,
  useToaster,
} from '@enterprise-ui/canvas-ui-react'
import { useEffect, useState } from 'react'
import { useMutation, useQuery } from '@apollo/client'
import { useAuth } from '@praxis/component-auth'
import CommentsTable from './CommentsTable'
import {
  GET_TCIN_COMMENTS,
  ADD_TCIN_COMMENT,
  BATCH_ADD_TCINS_COMMENT,
} from '../../../../apiItemGraphql/comment'
import { useFirefly } from '../../../../globalUtils/useAnalytics'
import { FIREFLY_EVENTS } from '../../../../constants/search'

const CommentInputModal = ({ visible, onClose, batchAdd, tcins }) => {
  const [comments, setComments] = useState([])
  const { handleTrackCustomEvent } = useFirefly()
  const auth = useAuth()
  const toaster = useToaster()

  const {
    data: getCommentsData,
    error,
    refetch,
  } = useQuery(GET_TCIN_COMMENTS, {
    variables: { tcin: tcins[0] },
    skip: batchAdd,
    fetchPolicy: 'no-cache',
  })
  const [addTcinCommentGql] = useMutation(ADD_TCIN_COMMENT)
  const [batchAddTcinsCommentGql] = useMutation(BATCH_ADD_TCINS_COMMENT)

  const headingLabel = batchAdd ? null : `${comments.length}`
  const headingSubText = batchAdd
    ? `Will be applied to ${tcins.length} ${tcins.length > 1 ? 'tcins' : 'tcin'}.  ` +
      'Comments are visible for all Target and Merch Vendor users.'
    : 'Comments are visible for all Target and Merch Vendor users.'

  const addComment = async (comment) => {
    const commonAddTcinVariables = {
      comment: comment,
      userId: auth?.session?.userInfo?.lanId,
      userName: `${auth?.session?.userInfo?.firstName} ${auth?.session?.userInfo?.lastName}`,
      userEmail: auth?.session?.userInfo?.email,
    }
    // event capture
    handleTrackCustomEvent(
      FIREFLY_EVENTS.EVENT_TYPE.GRID_ACTION,
      FIREFLY_EVENTS.CUSTOM_INTERACTION_KEYS.COMMENT,
    )
    if (!batchAdd) {
      const res = await addTcinCommentGql({
        variables: {
          tcin: tcins[0],
          ...commonAddTcinVariables,
        },
      })
      if (res?.data) {
        await refetchComments()
      }
    } else {
      const res = await batchAddTcinsCommentGql({
        variables: {
          tcins: tcins,
          ...commonAddTcinVariables,
        },
      })
      if (res?.data) {
        toaster({
          type: 'success',
          message: `Comment added to ${tcins.length} tcins`,
        })
        onClose()
      }
    }
  }
  const refetchComments = async () => {
    await refetch()
  }

  useEffect(() => {
    if (getCommentsData?.getCommentsByTcin?.data) {
      setComments(getCommentsData.getCommentsByTcin.data)
    }
    if (error) {
      toaster({
        type: 'error',
        message: `Error getting comments for the tcin`,
      })
    }
  }, [getCommentsData, error, toaster])

  return (
    <Modal
      isVisible={visible}
      headingText={<Heading label={headingLabel} subText={headingSubText} />}
      onRefuse={onClose}
      data-testid="comment-input-modal"
    >
      <Grid.Container className="hc-pl-md">
        <Grid.Item xs={12}>
          <AddComment onAdd={addComment}></AddComment>
        </Grid.Item>
        <Grid.Item xs={12} className="hc-pr-expanded hc-pb-expanded">
          {!batchAdd && comments.length > 0 && (
            <CommentsTable
              comments={comments}
              refetchComments={() => refetchComments()}
              userId={auth?.session?.userInfo?.lanId}
            />
          )}
        </Grid.Item>
      </Grid.Container>
    </Modal>
  )
}

const Heading = ({ label, subText }) => {
  return (
    <>
      <div>
        <span>Comments</span>
        <Badge
          location="inline"
          content={label}
          className="hc-ml-xs hc-fs-md hc-pa-min"
          data-testid="comments-count"
          aria-label={label ? `Comment count is ${label}` : ''}
        />
      </div>
      <span
        className="hc-clr-grey02 font-weight-400 hc-fs-sm"
        data-testid="Modal-info"
      >
        {subText}
      </span>
    </>
  )
}

const AddComment = ({ onAdd }) => {
  const [input, setInput] = useState('')
  return (
    <Grid.Container className="hc-mt-dense">
      <Grid.Item xs={8}>
        <Input.Label className="font-weight-400 hc-fs-sm">
          Add Comment*
        </Input.Label>
        <Input.Textarea
          onChange={(event) => {
            setInput(event.target.value)
          }}
          value={input}
          data-testid="comment-input-area"
        />
        <Button
          type="primary"
          data-testid="comment-add-button"
          className="hc-mt-xs"
          disabled={input.length < 1}
          onClick={() => {
            onAdd(input)
            setInput('')
          }}
        >
          ADD
        </Button>
      </Grid.Item>
    </Grid.Container>
  )
}

export default CommentInputModal
