import {
  Button,
  Heading,
  Grid,
  Placeholder,
  Tooltip,
} from '@enterprise-ui/canvas-ui-react'

import EnterpriseIcon, {
  FilterIcon,
  MinimizeIcon,
  MaximizeIcon,
  DownloadIcon,
  LinkIcon,
} from '@enterprise-ui/icons'
import { useGridHeader } from './useGridHeader'
import { useGridDownload } from './useGridDownload'

export const GridHeader = (props) => {
  const { openCustomiseColumns, setOpenCustomiseColumns, handleCopyText } =
    useGridHeader()
  const {
    handleDownload,
    handleMTADownload,
    downloadInProgress,
    downloadMtaInProgress,
  } = useGridDownload()
  const {
    openCloseToolPanel,
    getTotalResult,
    loading,
    setMaximize,
    maximize,
    gridApi,
  } = props

  let visibleColumns = []
  let selectedTcins = []
  let disableDownloadButton = true
  if (gridApi.current?.api) {
    const selectedRows = gridApi.current.api.getSelectedRows()
    selectedTcins = selectedRows.map((filter) => filter.tcin)
    const rowGroupList = gridApi.current.api.getAllDisplayedColumns()
    visibleColumns = rowGroupList.map((rowGroup) => {
      const colId = rowGroup?.getColId()
      return colId !== 'selection' ? colId : ''
    })
  }
  if (selectedTcins.length > 0) {
    if (selectedTcins.length <= 10000) {
      selectedTcins = selectedTcins.toString()
      disableDownloadButton = false
    }
  }
  return (
    <Grid.Container justify="space-between" className="grid-header hc-mt-none">
      <Grid.Item xs={4} xl={8}>
        <Heading
          heading={6}
          className="hc-fs-md hc-lh-expanded font-color-default font-weight-700 hc-pa-md"
        >
          {loading ? (
            <Placeholder.Text
              style={{ width: 120 }}
              className="hc-ml-dense hc-mr-dense"
            />
          ) : getTotalResult > 0 ? (
            <span>Result: ({getTotalResult.toLocaleString()})</span>
          ) : (
            <span>Results: No Items</span>
          )}
        </Heading>
      </Grid.Item>
      <Grid.Item className="hc-mr-normal">
        {loading ? (
          <div style={{ display: 'flex' }}>
            <Placeholder.Text
              emphasized
              style={{ width: 20 }}
              className="hc-ml-dense hc-mr-normal"
            />
            <Placeholder.Text
              style={{ width: 20 }}
              className="hc-ml-dense hc-mr-normal"
            />
            <Placeholder.Text
              style={{ width: 20 }}
              className="hc-ml-dense hc-mr-normal"
            />
            <Placeholder.Text
              style={{ width: 20 }}
              className="hc-ml-dense hc-mr-dense"
            />
            <Placeholder.Text
              style={{ width: 150 }}
              className="hc-ml-dense hc-mr-dense"
            />
          </div>
        ) : getTotalResult > 0 ? (
          <div className="hc-mr-dense button-container">
            <Tooltip content="Customise table columns" location="top">
              <Button
                type="ghost"
                iconOnly
                className="bg-default-contrast-weak hc-mr-dense"
                onClick={() => {
                  setOpenCustomiseColumns((p) => !p)
                  openCloseToolPanel('columns', openCustomiseColumns)
                }}
                aria-label="View colunm filters"
              >
                <EnterpriseIcon icon={FilterIcon} />
              </Button>
            </Tooltip>
            <Tooltip
              content="Get links to this page (includes current filters)"
              location="top"
            >
              <Button
                type="ghost"
                iconOnly
                className="bg-default-contrast-weak hc-mr-dense"
                onClick={handleCopyText}
                aria-label="get link"
              >
                <EnterpriseIcon icon={LinkIcon} />
              </Button>
            </Tooltip>
            <Tooltip
              content={maximize ? 'Exit Fullscreen' : 'Fullscreen'}
              location="top"
            >
              <Button
                type="ghost"
                iconOnly
                className="bg-default-contrast-weak hc-mr-dense"
                onClick={() => {
                  setMaximize(!maximize)
                }}
                aria-label="Minimize the table view"
              >
                {maximize ? (
                  <EnterpriseIcon icon={MinimizeIcon} />
                ) : (
                  <EnterpriseIcon icon={MaximizeIcon} />
                )}
              </Button>
            </Tooltip>

            <Tooltip
              content="Download Visible Columns | Max 10,000"
              location="top"
            >
              <Button
                type="ghost"
                iconOnly
                className="bg-default-contrast-weak hc-mr-dense"
                onClick={(e) => {
                  handleDownload(e, visibleColumns)
                }}
                disabled={getTotalResult > 10000}
                isLoading={downloadInProgress}
                aria-label="Download Visible Columns"
              >
                <EnterpriseIcon icon={DownloadIcon} />
              </Button>
            </Tooltip>
            <Tooltip
              content={
                disableDownloadButton
                  ? 'Please select the items'
                  : 'Download results with MTA (Max 10K)'
              }
              location="top"
            >
              <Button
                type="secondary"
                onClick={(e) => {
                  handleMTADownload(e, selectedTcins)
                }}
                disabled={disableDownloadButton}
                isLoading={downloadMtaInProgress}
              >
                Download with MTA
              </Button>
            </Tooltip>
          </div>
        ) : (
          <></>
        )}
      </Grid.Item>
    </Grid.Container>
  )
}
