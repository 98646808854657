import { Tabs, Divider, Card } from '@enterprise-ui/canvas-ui-react'
import { useQuickSearch } from './useFilterSection'
import { AllFilters } from './allFilters'
import { SavedFilters } from './savedFilters'

export const FilterSection = () => {
  const { onTabSelect, activeFilterTab } = useQuickSearch()
  return (
    <Card className="hc-ma-dense">
      <Tabs
        activeTab={activeFilterTab}
        onTabSelect={onTabSelect}
        defaultTab="all-filters"
        disableAdaptive
        justified
        size="expanded"
      >
        <Tabs.Item name="all-filters">All Filters</Tabs.Item>
        <Tabs.Item name="saved-filters">Saved Search</Tabs.Item>
        <Divider />
        <Tabs.Content
          name="all-filters"
          data-testid={`all-filters-content`}
          style={{ width: '314px' }}
          className="hc-pl-xs hc-pt-normal hc-pb-xs hc-pr-none"
        >
          <AllFilters />
        </Tabs.Content>
        <Tabs.Content
          name="saved-filters"
          data-testid={`saved_filters-content`}
          className="hc-pl-xs hc-pt-xs hc-pb-xs hc-pr-none"
        >
          <SavedFilters />
        </Tabs.Content>
      </Tabs>
    </Card>
  )
}
