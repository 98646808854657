import { useAnalytics } from '@praxis/component-analytics'

export const useFirefly = (props) => {
  const { trackCustomEvent } = useAnalytics()

  const handleTrackCustomEvent = (type, key, value) => {
    trackCustomEvent(type, key, value)
  }
  return {
    handleTrackCustomEvent,
  }
}
