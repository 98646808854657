import { Card } from '@enterprise-ui/canvas-ui-react'

export const Welcome = () => {
  return (
    <Card className="hc-pa-expanded dashboard-welcome-block">
      <h4 className="hc-fs-md hc-lh-expanded font-color-default font-weight-700">
        Welcome to LaunchPad!
      </h4>
      <p className="hc-fs-sm hc-lh-expanded hc-mt-normal font-color-default font-weight-400">
        This platform helps you create, maintain and track items seamlessly.
      </p>
    </Card>
  )
}
