import '@enterprise-ui/canvas-ui-css'
import { useEffect } from 'react'
import { Form } from '@enterprise-ui/canvas-ui-react'
import { isEmpty } from 'lodash'
import { getNewTcinsFromString } from './helper'

export const TextBoxFilter = (filter) => {
  const {
    id: filterId,
    name,
    onFilterChange,
    isFav,
    selectedValue,
    setSelectedValues,
  } = filter

  const handleUpdate = (e) => {
    const newValue = e.target.value

    setSelectedValues((prev) => ({ ...prev, [filterId]: newValue }))
    const modifiedTcin = getNewTcinsFromString(newValue)
    const valueList = !isEmpty(modifiedTcin.tcins) ? modifiedTcin.tcins : null
    onFilterChange(filterId, valueList)
  }
  useEffect(() => {
    if (!selectedValue) {
      setSelectedValues((prev) => ({ ...prev, [filterId]: '' }))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedValue])

  return (
    <Form.Field
      id={`${filterId}${isFav}-filter`}
      key={`${filterId}${isFav}-filter`}
      label={name}
      placeholder={name}
      type="text"
      value={selectedValue}
      onChange={(e) => handleUpdate(e)}
    />
  )
}
