import { AgGridReact } from 'ag-grid-react'
import { ServerSideRowModelModule } from '@ag-grid-enterprise/server-side-row-model'
import { ColumnsToolPanelModule } from '@ag-grid-enterprise/column-tool-panel'
import { LicenseManager } from '@ag-grid-enterprise/core'
import { Card, Grid } from '@enterprise-ui/canvas-ui-react'
import { GridHeader } from '../gridHeader'
import { useSearchGrid } from './useSearchGrid'
import { useGridHeader } from '../gridHeader/useGridHeader' // React Grid Logic
import PaginationControls from '../gridFooter/Pagination'
import { Actionbar } from '../actionbar'
import { SearchHeader } from '../header'
import { AG_GRID_LICENSE_KEY } from '../../../constants/ag-grid'

LicenseManager.setLicenseKey(AG_GRID_LICENSE_KEY)

export const SearchGrid = () => {
  const {
    searchItems,
    loading,
    gridColumns,
    gridOptions,
    onBtFirst,
    onBtLast,
    onBtNext,
    onBtPrevious,
    onBtSpecificPage,
    onPaginationChanged,
    gridApi,
    getCurrentPage,
    paginationPageSizeValue,
    getTotalPage,
    openCloseToolPanel,
    maximize,
    setMaximize,
    selectedItems,
    onGridReady,
    handlePaginationPageSizeChange,
    onSelectionChanged,
    getRowId,
    isExpanded,
    setIsExpanded,
  } = useSearchGrid()
  const { sideBar } = useGridHeader()
  const getTotalResult = gridApi?.current?.api?.getDisplayedRowCount()
  const startItemIndex = (getCurrentPage - 1) * paginationPageSizeValue + 1
  let endItemIndex = getCurrentPage * paginationPageSizeValue
  endItemIndex = Math.min(endItemIndex, getTotalResult)
  const displayText = `Showing ${startItemIndex}-${endItemIndex} of ${getTotalResult}`
  const classNames = [
    'ag-theme-quartz hc-pb-none search-grid',
    !maximize && 'search-grid-min',
    selectedItems.length > 0 && 'with-action',
    selectedItems.length === 0 && 'with-no-action',
    isExpanded && 'sticker-expanded',
    !isExpanded && 'sticker-collapsed',
  ]
    .filter(Boolean)
    .join(' ')
  return (
    <>
      <div className="top-right hc-ml-normal">
        <Card className="hc-mt-none hc-mb-normal">
          <SearchHeader setIsExpanded={setIsExpanded} isExpanded={isExpanded} />
        </Card>
        <Card
          className={`${maximize ? 'lp-is-fullscreen' : ''} hc-mt-none hc-mb-normal `}
        >
          <GridHeader
            openCloseToolPanel={openCloseToolPanel}
            getTotalResult={getTotalResult}
            setMaximize={setMaximize}
            maximize={maximize}
            loading={loading}
            gridApi={gridApi}
          />
          <Grid.Container justify="center">
            <Grid.Item className={classNames}>
              <AgGridReact
                ref={gridApi}
                rowData={searchItems}
                gridOptions={gridOptions}
                columnDefs={gridColumns}
                pagination={true}
                onGridReady={onGridReady}
                paginationPageSize={paginationPageSizeValue}
                paginationPageSizeSelector={[50, 75, 100]}
                onPaginationChanged={onPaginationChanged}
                suppressPaginationPanel={true}
                modules={[ServerSideRowModelModule, ColumnsToolPanelModule]}
                sideBar={sideBar}
                getRowId={getRowId}
                onSelectionChanged={onSelectionChanged}
              />
            </Grid.Item>
            {!loading && gridColumns && getTotalResult > 0 && (
              <Grid.Item style={{ width: '98%' }} className="hc-pt-none">
                <Actionbar gridApi={gridApi} selectedItems={selectedItems} />
              </Grid.Item>
            )}
          </Grid.Container>
        </Card>
      </div>
      <div className="bottom-right hc-pb-min">
        <PaginationControls
          getTotalPage={getTotalPage}
          getCurrentPage={getCurrentPage}
          onBtLast={onBtLast}
          onBtNext={onBtNext}
          onBtSpecificPage={onBtSpecificPage}
          onBtPrevious={onBtPrevious}
          onBtFirst={onBtFirst}
          paginationPageSizeValue={paginationPageSizeValue}
          handlePaginationPageSizeChange={handlePaginationPageSizeChange}
          loading={loading}
          displayText={displayText}
        />
      </div>
    </>
  )
}
