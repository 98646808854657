import { useState } from 'react'
import { useQuery, gql } from '@apollo/client'
import { formatOptions } from '../../globalUtils/searchUtills'

export const useFetchLov = ({ variables, skip }) => {
  const [options, setOptions] = useState([])
  const { loading, error, refetch } = useQuery(GET_LOV_QUERY, {
    variables,
    fetchPolicy: 'network-only',
    skip,
    onCompleted: (data) => {
      if (data && data.result && data.result.options) {
        const formattedOptions = formatOptions(data.result.options)
        setOptions(formattedOptions)
      }
    },
  })

  return { options, loading, error, refetch }
}

const GET_LOV_QUERY = gql`
  query lov(
    $attributeId: String!
    $attributeParams: Any = null
    $resourceIds: [String]
    $searchText: String = ""
    $searchIds: [String]
  ) {
    result: lov(
      attributeId: $attributeId
      attributeParams: $attributeParams
      resourceIds: $resourceIds
      searchText: $searchText
      searchIds: $searchIds
    ) {
      options {
        displayValue
        value
      }
    }
  }
`
